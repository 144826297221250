import React, { useContext } from 'react'
import { Title, Subtitle, useModal } from '../../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../../Shared/API'
import CommitmentForm from './CommitmentForm'
import { FundContext } from '../../../Context/FundContext'

export default function NewCommitment ({ returnPath }) {
  const {
    reloadFund, fund, fundAPI, fundPath
  } = useContext(FundContext)
  const { api } = useAPIGet(`${fundAPI}/commitments/new.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const addCommitment = async (commitment) => {
    const { error, data: result } = (await API.post(`${fundAPI}/commitments.json`, { commitment }))

    if (!error) {
      closeModal(`${fundPath}/calls/new/${result.id}`)
      reloadFund()
    }
  }

  return (
    <AwaitResponse api={api}>
      {(commitment) => (
        <Modal>
          <Title>Record Commitment</Title>
          <Subtitle>
            How much are you commiting to invest in
            {' '}
            <b><nobr>{fund.display_name}</nobr></b>
            ?
          </Subtitle>

          <CommitmentForm
            commitment={commitment}
            onSubmit={addCommitment}
          />
        </Modal>
      )}
    </AwaitResponse>
  )
}
