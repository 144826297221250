import React, { useContext } from 'react'
import { parseISO } from 'date-fns'
import styled from 'styled-components'
import Table, {
  CellTitle, CellSubtitle, ContentCell, HamburgerRow
} from '../../Shared/Table'
import { EntityContext } from '../../../Context/EntityContext'

const ReceivedDistribution = HamburgerRow

const ExpectedDistribution = styled(HamburgerRow)`
  ${ContentCell} * {
    color: green;
  }
`

export default ({ distributions }) => {
  const columns = [
    {
      type: 'date',
      value: (row) => (parseISO(row.received_on)),
      display: (row) => ((row.status === 'Expected') ? 'EXPECTED' : null)
    },
    {
      title: 'Notes',
      value: (row) => (row.notes)
    },
    {
      title: 'Amount',
      type: 'monetary',
      value: (row) => (row.dollar_value)
    }
  ]

  const entityColumn = {
    title: 'Company',
    type: 'stacked',
    render: ({ entity }) => (
      <>
        <CellTitle>{entity.display_name}</CellTitle>
        <CellSubtitle>{entity.legal_name}</CellSubtitle>
      </>
    )
  }

  if ((distributions[0] || {}).entity) {
    columns.splice(1, 0, entityColumn)
  }

  const { financesPath } = useContext(EntityContext)

  const rowType = (row) => {
    if (row.status === 'Expected') {
      return ExpectedDistribution
    }

    return ReceivedDistribution
  }

  return (
    <Table
      columns={columns}
      rows={distributions}
      rowType={rowType}
      defaultSort={{ column: 0, direction: 'asc' }}
      emptyMessage="No distributions have been received."
      rowMenu={[
        {
          title: 'Edit…',
          select: (row) => (`${financesPath}/distributions/${row.id}/edit`)
        },
        {
          title: 'Remove…',
          select: (row) => (`${financesPath}/distributions/${row.id}/remove`)
        }
      ]}
    />
  )
}
