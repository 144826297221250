import React, { createContext, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useAPIGet } from '../Shared/API'
import { UserContext } from './UserContext'
import useQuery from '../Shared/useQuery'

export const FundContext = createContext({})

export const FundProvider = ({ children }) => {
  const { portfolioTouched, portfolioAPI, portfolioPath } = useContext(UserContext)
  const { fundID } = useParams()
  const query = useQuery()
  const searchQuery = new URLSearchParams({
    ...Object.fromEntries(query.entries()),
    ts: portfolioTouched
  })

  const {
    api,
    reload: reloadFund,
    setData: setFund
  } = useAPIGet(`${portfolioAPI}/funds/${fundID}.json?${searchQuery.toString()}`, {})
  const {
    initialLoadComplete, data: {
      fund, filters, entities, prospects, summary, relationship_options, status_options
    }
  } = api

  const fundPaths = (e) => ({
    fundPath: e && `${portfolioPath}/fund/${e.id}`,
    fundAPI: e && `${portfolioAPI}/funds/${e.id}`,
    financesPath: e && `${portfolioPath}/fund/${e.id}/finances`,
    prospectPath: e && `${portfolioPath}/prospects/${e.id}`
  })

  // Make the context object:
  const context = {
    initialLoadComplete,
    fund,
    fundPaths,
    ...fundPaths(fund),
    filters,
    entities,
    prospects,
    summary,
    relationship_options,
    status_options,
    reloadFund,
    setFund
  }

  // pass the value in provider and return
  return <FundContext.Provider value={context}>{children}</FundContext.Provider>
}

export const { Consumer } = FundContext
