import React from 'react'
import { Submit, Button } from '../../Shared/Modal'
import { Form, TextArea, Row } from '../../Shared/Form'

export default function NoteForm ({ note, onSubmit, onCancel }) {
  const onFormSubmit = (newnote) => {
    onSubmit({ ...note, ...newnote })
  }

  return (
    <Form onSubmit={onFormSubmit} initialValues={note}>
      <TextArea field="text" autoFocus rows={10} placeholder="Enter notes…" />

      <Row>
        <Button title="Cancel" onClick={onCancel} />
        <Submit title="Save" />
      </Row>
    </Form>
  )
}
