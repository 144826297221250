import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { format as formatDate, parseISO } from 'date-fns'
import {
  Title, Submit, Subtitle, useModal
} from '../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../Shared/API'
import { EntityContext } from '../../Context/EntityContext'
import { DollarValue } from '../../Shared/MonetaryValue'
import { Form } from '../../Shared/Form'

export default function RemoveInvestment ({ returnPath }) {
  const { investmentID } = useParams()
  const { reloadEntity, entityAPI } = useContext(EntityContext)
  const { api } = useAPIGet(`${entityAPI}/investments/${investmentID}.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const removeInvestment = async () => {
    const { error } = (await API.delete(`${entityAPI}/investments/${investmentID}.json`))

    if (!error) {
      closeModal()
      reloadEntity()
    }
  }

  return (
    <AwaitResponse api={api}>
      {({ investment }) => (
        <Modal>
          <Title>Remove Investment?</Title>
          <Subtitle>
            Are you sure you want to remove the
            {' '}
            <b><DollarValue value={investment.dollar_value} /></b>
            {' '}
            investment from
            <b>{` ${formatDate(parseISO(investment.invested_on), 'MMMM d, yyy')}`}</b>
            ? This cannot be undone.
          </Subtitle>

          <Form onSubmit={removeInvestment}>
            <Submit title="Remove Investment" />
          </Form>
        </Modal>
      )}
    </AwaitResponse>
  )
}
