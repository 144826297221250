import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Title, useModal, Subtitle } from '../../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../../Shared/API'
import DistributionForm from './DistributionForm'
import { FundContext } from '../../../Context/FundContext'

export default function EditDistribution ({ returnPath }) {
  const { distributionID } = useParams()
  const { reloadFund, fundAPI } = useContext(FundContext)
  const { api } = useAPIGet(`${fundAPI}/distributions/${distributionID}.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const editDistribution = async (distribution) => {
    const { error } = (await API.put(`${fundAPI}/distributions/${distributionID}.json`, { distribution }))

    if (!error) {
      closeModal()
      reloadFund()
    }
  }

  return (
    <AwaitResponse api={api}>
      {(distribution) => (
        <Modal>
          <Title>Edit Distribution</Title>
          <Subtitle>
            What has been returned?
          </Subtitle>

          <DistributionForm
            distribution={distribution}
            onSubmit={editDistribution}
          />
        </Modal>
      )}
    </AwaitResponse>
  )
}
