import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Title, Subtitle, useModal } from '../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../Shared/API'
import { Select } from '../../Shared/Form'
import EntityForm from './EntityForm'
import { UserContext } from '../../Context/UserContext'
import useQuery from '../../Shared/useQuery'

export default function SelectEntity () {
  const { portfolioPath, portfolioAPI } = useContext(UserContext)
  const { api } = useAPIGet(`${portfolioAPI}/entities/new.json`, [])
  const history = useHistory()
  const { Modal, closeModal } = useModal({ pathname: portfolioPath, search: history.location.search })
  const basePath = `${portfolioPath}/entities`
  const { fundID } = useParams() // Optional
  const query = useQuery()

  const selectEntity = (entityPath) => {
    const searchQuery = fundID
      ? new URLSearchParams({
        ...Object.fromEntries(query.entries()),
        fund_id: fundID
      }).toString()
      : history.location.search

    closeModal({ pathname: `${basePath}/${entityPath}/finances/investments/new`, search: searchQuery })
  }

  const selectOptions = (entities) => ([
    { id: -1, title: 'Create a new entity' },
    { id: -2, title: '', disabled: true }
  ].concat(entities.map((e) => ({ id: `${e.id}-${e.slug}`, title: e.display_name }))))

  return (
    <AwaitResponse api={api}>
      {({
        entity: newEntity, entities, status_options: statusOptions, kind_options: kindOptions
      }) => {
        const onSubmit = async (data) => {
          const entity = (await API.post(`${portfolioAPI}/entities.json`, { entity: data })).data
          selectEntity(`${entity.id}-${entity.slug}`)
        }

        const onSelectChange = (event) => {
          selectEntity(event.target.value)
        }

        const initialFormData = { ...newEntity, entity_id: -1 }

        if (entities.length === 0) {
          return (
            <Modal>
              <Title>Record Your First Investment</Title>
              <Subtitle>Cashlytics makes it easy and intuitive to track your investments. Let&apos;s get started!</Subtitle>
              <Subtitle>First, what company or entity are you investing in?</Subtitle>

              <EntityForm
                onSubmit={onSubmit}
                entity={initialFormData}
                onImport={() => closeModal(`${portfolioPath}/import`)}
                status_options={statusOptions}
                kind_options={kindOptions}
              />
            </Modal>
          )
        }

        return (
          <Modal>
            <Title>Record New Investment</Title>
            <Subtitle>What entity are you investing in?</Subtitle>

            <EntityForm onSubmit={onSubmit} entity={initialFormData} status_options={statusOptions} kind_options={kindOptions}>
              <Select field="entity_id" options={selectOptions(entities)} onChange={onSelectChange} />
            </EntityForm>
          </Modal>
        )
      }}
    </AwaitResponse>
  )
}
