import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import {
  Title, Subtitle, useModal, Submit, Button
} from '../../../Shared/Modal'
import { UserContext } from '../../../Context/UserContext'
import API from '../../../Shared/API'
import {
  Form, Input, Row
} from '../../../Shared/Form'

function InviteForm ({
  portfolio, onSubmit, onCancel, children
}) {
  return (
    <Form onSubmit={onSubmit} initialValues={portfolio}>
      {children}

      <Row>
        <Input field="first_name" label="First Name" placeholder="Sally" />
        <Input field="last_name" label="Last Name" placeholder="Ride" />
      </Row>

      <Input field="email" label="Email Address" placeholder="sally@ride.vc" />

      <Row>
        <Button title="Cancel" onClick={onCancel} />
        <Submit title="Invite" />
      </Row>
    </Form>
  )
}

const InviteLink = styled.div`
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 15px 0;
`

function selectContentsOnClick (e) {
  const range = document.createRange()
  range.selectNodeContents(e.target)
  const sel = window.getSelection()
  sel.removeAllRanges()
  sel.addRange(range)
}

export default function Invite ({ smaller, closePath }) {
  const { portfolio, portfolioPath, portfolioAPI } = useContext(UserContext)
  const { Modal, closeModal } = useModal(closePath || portfolioPath)
  const [sentInvite, setSentInvite] = useState()

  const createInvite = async (invite) => {
    const { data: newInvite } = (await API.post(`${portfolioAPI}/invites.json`, { invite }))
    setSentInvite(newInvite)
  }

  return (
    <Modal smaller={smaller}>
      <Title>
        Invite to
        {' '}
        {portfolio.display_name}
      </Title>

      {sentInvite
        ? (
        <Subtitle>
          <b>{sentInvite.first_name}</b>
          {' '}
          (
          {sentInvite.email}
          ) has been granted access. Send them this link
          to accept their invitiation:
          <InviteLink onClick={selectContentsOnClick}>{`${document.location.origin}/join/${sentInvite.code}`}</InviteLink>

          For security reasons, they will be required to
          create an account matching that email address.

          <Submit onClick={() => closeModal()} title="Done" />
        </Subtitle>
          )
        : (
          <>
            <Subtitle>
              You can invite others to collaborate on the portfolio with you at any time.
              Enter their details to grant them access:
          </Subtitle>

            {/* Must enclose closeModal in an outer function to discard the event param */}
            <InviteForm onSubmit={createInvite} onCancel={() => closeModal()} />
          </>
          )}

    </Modal>
  )
}
