import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Title, useModal } from '../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../Shared/API'
import ValuationForm from './ValuationForm'
import { EntityContext } from '../../Context/EntityContext'

export default function EditValuation ({ returnPath }) {
  const { valuationID } = useParams()
  const { reloadEntity, entityAPI } = useContext(EntityContext)
  const { api } = useAPIGet(`${entityAPI}/valuations/${valuationID}.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const editValuation = async (valuation) => {
    const { error } = (await API.put(`${entityAPI}/valuations/${valuationID}.json`, { valuation }))

    if (!error) {
      closeModal()
      reloadEntity()
    }
  }

  return (
    <AwaitResponse api={api}>
      {({ valuation, source_options }) => (
        <Modal>
          <Title>Edit Valuation</Title>

          <ValuationForm
            valuation={valuation}
            source_options={source_options}
            onSubmit={editValuation}
          />
        </Modal>
      )}
    </AwaitResponse>
  )
}
