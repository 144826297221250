import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { parseISO } from 'date-fns'
import Table, {
  CellTitle, CellDescription, DefaultActionButton, SecondaryActionButton
} from '../Shared/Table'
import { HStack, VStack, Favicon } from '../Shared/UI'
import { UserContext } from '../../Context/UserContext'

export default function ProspectsTable ({ prospects }) {
  const history = useHistory()
  const { portfolioPath } = useContext(UserContext)

  const columns = [
    {
      type: 'date',
      value: (e) => (parseISO(e.created_at))
    },
    {
      title: 'Company',
      type: 'stacked',
      value: (entity) => (entity.display_name),
      render: (entity) => (
        <HStack>
          <Favicon subject={entity} />
          <VStack>
            <CellTitle>{entity.display_name}</CellTitle>
            <CellDescription>{entity.description}</CellDescription>
          </VStack>
        </HStack>
      )
    },
    {
      title: 'Actions',
      type: 'monetary',
      value: (entity) => (entity.analysis.multiple),
      render: (entity) => (
        <>
          <DefaultActionButton data-ignore to={`${portfolioPath}/prospects/${entity.id}/investments/new`}>Invest</DefaultActionButton>
          <SecondaryActionButton data-ignore to={`${portfolioPath}/prospects/${entity.id}/pass`}>Pass</SecondaryActionButton>
        </>
      )
    }
  ]

  return (
    <Table
      columns={columns}
      rows={prospects}
      onRowClick={(event, entity) => {
        if (event.target.getAttribute('data-ignore') === 'true') {
          return
        }

        history.push(`${portfolioPath}/prospects/${entity.id}-${entity.slug}`)
      }}
      defaultSort={{ column: 0, direction: 'asc' }}
      emptyMessage="There are no current prospects."
    />
  )
}
