import React, { useContext } from 'react'
import {
  Title, Submit, Subtitle, useModal
} from '../../Shared/Modal'
import API from '../../Shared/API'
import { EntityContext } from '../../Context/EntityContext'
import { Form, Input } from '../../Shared/Form'
import { UserContext } from '../../Context/UserContext'

export default function RemoveProspect () {
  const { entityAPI, entity, prospectPath } = useContext(EntityContext)
  const { portfolioPath } = useContext(UserContext)
  const { Modal, closeModal } = useModal(prospectPath)

  const deleteEntity = async () => {
    await API.delete(`${entityAPI}.json`)
    closeModal(portfolioPath)
  }

  const validateConfirm = (v) => (v === entity.display_name.toUpperCase() ? undefined : 'Invalid confirmation')

  return (
    <Modal>
      <Title>Remove Prospect?</Title>
      <Subtitle>
        Are you sure you want to remove
        {' '}
        <b>{entity.display_name}</b>
        {' '}
        as a prospective investent?
      </Subtitle>
      <Subtitle>
        <b>Warning: </b>
        This will permanently remove all history of this prospect. Are you sure you dont want to pass instead?
      </Subtitle>

      <Form onSubmit={deleteEntity}>
        <Input
          field="confirm"
          placeholder={`Enter "${entity.display_name.toUpperCase()}" to continue`}
          validateOnBlur
          validate={validateConfirm}
        />
        <Submit title="Remove Prospect" />
      </Form>
    </Modal>
  )
}
