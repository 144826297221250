import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import styled from 'styled-components'
import Stats from '../Shared/Stats'
import {
  Section, Contents, Row, SectionHeader, AddLink
} from '../Shared/UI'
import { EntityContext } from '../../Context/EntityContext'
import EditEntity from './EditEntity'
import DeleteEntity from './DeleteEntity'
import AddNote from '../Notes/AddNote'
import Notes from '../Notes/Notes'
import EditNote from '../Notes/EditNote'
import RemoveNote from '../Notes/RemoveNote'
import Profile from './Profile'

const FailedReason = styled(Section)`
  border: 2px solid red;
  background-color: rgba(255, 235, 235,1);
  padding: 20px;
  text-align: center;
  font-style: italic;
  margin-bottom: 0;
  color: red;
`

const UncertainReason = styled(Section)`
  border: 2px solid orange;
  background-color: #ffe3c3;
  padding: 20px;
  text-align: center;
  font-style: italic;
  margin-bottom: 0;
  color: #dc8f02;
`

const SuccessReason = styled(Section)`
  border: 2px solid green;
  background-color: rgba(235, 255, 235,1);
  padding: 20px;
  text-align: center;
  font-style: italic;
  margin-bottom: 0;
  color: green;
`

const PrimaryColumn = styled.div`
  flex-basis: 60%;
`

const SecondaryColumn = styled.div`
  flex-grow: 1;

  ${Section} {
    margin-left: 0;
  }
`

export default function ShowEntity ({ baseRoute }) {
  const { entity, entityPath } = useContext(EntityContext)

  return (
    <>
      <Stats stats={entity.analysis} />
      <Contents>
        {entity.status === 'Liquidated' && entity.outcome_reason && <FailedReason>{entity.outcome_reason}</FailedReason>}
        {entity.status === 'Acquired' && entity.outcome_reason && <SuccessReason>{entity.outcome_reason}</SuccessReason>}
        {entity.status === 'Uncertain' && entity.outcome_reason && <UncertainReason>{entity.outcome_reason}</UncertainReason>}
        <Row>
          <PrimaryColumn>
            <Section>
              <SectionHeader title="Overview">
                <AddLink to={`${entityPath}/edit`}>Edit</AddLink>
              </SectionHeader>

              <Profile entity={entity} />
            </Section>

            <Section>
              <SectionHeader title="Notes">
                <AddLink to={`${entityPath}/notes/new`}>Add</AddLink>
              </SectionHeader>

              <Notes notes={entity.notes} />
            </Section>
          </PrimaryColumn>

          <SecondaryColumn>
            <Section>
              <SectionHeader title="Contacts">
                <AddLink to={`${entityPath}/holdings/new`}>Add</AddLink>
              </SectionHeader>
            </Section>

            <Section>
              <SectionHeader title="Documentation">
                <AddLink to={`${entityPath}/valuations/new`}>Upload</AddLink>
              </SectionHeader>
            </Section>
          </SecondaryColumn>
        </Row>
      </Contents>

      {/* Entity Modals */}
      <Route path={`${baseRoute}/edit`} exact>
        <EditEntity />
      </Route>
      <Route path={`${baseRoute}/remove`} exact>
        <DeleteEntity />
      </Route>

      {/* Note Modals */}
      <Route path={`${baseRoute}/notes/new`} exact>
        <AddNote />
      </Route>
      <Route path={`${baseRoute}/notes/:noteID/edit`} exact>
        <EditNote />
      </Route>
      <Route path={`${baseRoute}/notes/:noteID/remove`} exact>
        <RemoveNote />
      </Route>
    </>
  )
}
