import React, { createContext, useState } from 'react'
import jwtDecode from 'jwt-decode'
import API from '../Shared/API'
import useLocalStorage from '../Shared/useLocalStorage'

export const UserContext = createContext({})

export const UserProvider = ({
  token: initialToken,
  portfolioID: initialPortfolioID,
  children
}) => {
  const [token, setToken] = useLocalStorage('auth.jwt', initialToken)
  const [portfolioTouched, setPortfolioTouchedInternal] = useState(new Date().getTime())

  let decoded = {}
  if (token) {
    decoded = jwtDecode(token)
    API.defaults.headers.common = { Authorization: `Bearer ${token}` }
  }

  const tokenValid = (t) => {
    const now = Date.now().valueOf() / 1000
    if (typeof t.exp !== 'undefined' && t.exp < now) {
      return false // expired
    }

    if (typeof t.nbf !== 'undefined' && t.nbf > now) {
      return false // not yet valid
    }

    return true
  }

  const { user, passkey_prompt } = decoded
  const { portfolios } = user || {}
  const [portfolioSlug, selectPortfolio] = useState(initialPortfolioID)

  let portfolio
  let portfolioPath
  if (portfolioSlug) {
    portfolio = portfolios.find((e) => e.slug === portfolioSlug) || null
    portfolioPath = portfolio ? `/${portfolio.slug}` : null
  }

  const setPortfolioTouched = () => {
    setPortfolioTouchedInternal(new Date().getTime())
  }

  // Make the context object:
  const context = {
    setToken,
    tokenValid: tokenValid(decoded),
    user,
    passkeyPrompt: passkey_prompt,
    portfolios,
    portfolio,
    portfolioPath,
    portfolioAPI: `/portfolios${portfolioPath}`,
    selectPortfolio,
    portfolioTouched,
    setPortfolioTouched
  }

  // pass the value in provider and return
  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}

export const { Consumer } = UserContext
