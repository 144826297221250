import React from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { DeltaValue, DollarValue, EmptyValue } from '../../Shared/MonetaryValue'

const Stats = styled.div`
  display: flex;
  box-shadow: 0px 0px 14px 0 rgba(0,0,0,0.25);
  border-top: 1px solid rgba(255.0, 255.0, 255.0, 0.95);
`

const Stat = styled.div`
  background-color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
  border-left: 1px solid #ddd;
  cursor: pointer;

  &:first-child {
    border-left: none;
  }

  * {
    transition: all .2s ease-in-out;
  }

  :hover * {
    transform: scale(1.2);
  }
`

const StatValue = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
`

const StatTitle = styled.div`
  text-transform: uppercase;
  color: #666;
`

export default ({ entities, stats }) => (
  <Stats>
    {entities && (
      <Stat>
        <StatValue>
          {entities.length}
        </StatValue>
        <StatTitle>Companies</StatTitle>
      </Stat>
    )}
    <Stat>
      <StatValue>
        <DollarValue value={stats.total_invested} decimals={0} />
      </StatValue>
      <StatTitle>Invested</StatTitle>
    </Stat>
    <Stat>
      <StatValue>
        <DollarValue value={stats.current_value} decimals={0} />
      </StatValue>
      <StatTitle>Total Value</StatTitle>
    </Stat>
    <Stat>
      <StatValue>
        <DeltaValue value={stats.profit} decimals={0} />
      </StatValue>
      <StatTitle>Profit</StatTitle>
    </Stat>
    <Stat>
      <StatValue>
        {stats.multiple === null
          ? (<EmptyValue>--</EmptyValue>)
          : (
            <NumberFormat
              value={stats.multiple}
              displayType="text"
              thousandSeparator
              decimalScale="2"
              fixedDecimalScale
              suffix="x"
            />
            )}
      </StatValue>
      <StatTitle>Multiple</StatTitle>
    </Stat>
    <Stat>
      <StatValue>
        <DollarValue value={stats.total_returned} decimals={0} />
      </StatValue>
      <StatTitle>Returned</StatTitle>
    </Stat>
    <Stat>
      <StatValue>
        <DeltaValue value={stats.total_outstanding} decimals={0} />
      </StatValue>
      <StatTitle>Outstanding</StatTitle>
    </Stat>
  </Stats>
)
