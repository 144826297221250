import React, { useContext, useState } from 'react'
import {
  useHistory, Redirect, Route
} from 'react-router-dom'
import {
  Container, Header, AddNavLink, TitleLink, Description,
  Title, Subject, Subtitle, HStack, HeaderHamburger, Favicon,
  BackgroundLoader, StatusPill
} from '../Shared/UI'
import Masthead from '../Shared/Masthead'
import { UserContext } from '../../Context/UserContext'
import Finances from './Finances'
import { FundContext } from '../../Context/FundContext'
import PortfolioTable from '../Portfolios/PortfolioTable'
import RemoveFund from './RemoveFund'
import EditFund from './EditFund'
import NewProspect from '../Prospects/NewProspect'
import SelectEntity from '../Entities/SelectEntity'

export default function Routes () {
  const { portfolioPath } = useContext(UserContext)
  const {
    fund, fundPath, initialLoadComplete,
    filters, entities, prospects, summary,
    relationship_options, status_options,
    reloadFund
  } = useContext(FundContext)
  const [showHamburgerMenu, setShowHamburgerMenu] = useState()
  const history = useHistory()

  if (!initialLoadComplete) {
    return <BackgroundLoader />
  } if (!fund) {
    return <Redirect to={portfolioPath} />
  }

  const isManaged = (fund.relationship === 'Manager')
  const hamburgerMenu = isManaged
    ? [
        {
          title: 'Add Investment…',
          select: () => (`${fundPath}/new/investment`)
        },
        {
          title: 'Edit Fund…',
          select: () => (`${fundPath}/edit`)
        },
        {
          title: 'Delete Fund…',
          select: () => (`${fundPath}/remove`)
        }
      ]
    : [
        {
          title: 'Add Commitment…',
          select: () => (`${fundPath}/commitments/new`)
        },
        {
          title: 'Add Capital Call…',
          select: () => (`${fundPath}/calls/new`)
        },
        {
          title: 'Add Returns…',
          select: () => (`${fundPath}/distributions/new`)
        },
        {
          title: 'Add Distributed Holdings…',
          select: () => (`${fundPath}/entities/new`)
        },
        {
          title: 'Edit Fund…',
          select: () => (`${fundPath}/edit`)
        },
        {
          title: 'Delete Fund…',
          select: () => (`${fundPath}/remove`)
        }
      ]

  return (
    <>
      <Container>
        <Masthead />
        <Header>
          <Subject>
            <HStack>
              <Favicon subject={fund} size={56} background />
              <div>
                <Title>
                  <TitleLink href={fund.url} target="_blank" rel="noopener noreferrer">
                    {fund.display_name}
                  </TitleLink>
                </Title>
                <Subtitle>{fund.legal_name}</Subtitle>
                <Description style={{ maxWidth: '600px' }}>{fund.description}</Description>
              </div>
            </HStack>
            <HStack>
              {fund.status !== 'Active'
                ? (
                <StatusPill status={fund.status}>{fund.status}</StatusPill>
                  )
                : (
                    isManaged
                      ? (
                  <AddNavLink to={{ pathname: `${fundPath}/new/prospect`, search: history.location.search }}>Add Lead</AddNavLink>
                        )
                      : (
                  <AddNavLink to={`${fundPath}/commitments/new`}>Add Commitment</AddNavLink>
                        )
                  )}

              <HeaderHamburger
                menu={hamburgerMenu}
                displayMenu={showHamburgerMenu}
                onOpen={() => { setShowHamburgerMenu(true) }}
                onClose={() => { setShowHamburgerMenu(false) }}
                onSelect={(item) => { history.push(item.select()) }}
                hOffset={-150}
              />
            </HStack>
          </Subject>
        </Header>

        {isManaged
          ? (
          <PortfolioTable
            basePath={fundPath}
            summary={summary}
            entities={entities}
            prospects={prospects}
            filters={filters}
          />
            )
          : <Finances />}
      </Container>

      {/* Modals */}
      <Route path="/:portfolioSlug/fund/:fundID/new/prospect" exact>
        <NewProspect closePath={fundPath} />
      </Route>

      <Route path="/:portfolioSlug/fund/:fundID/new/investment" exact>
        <SelectEntity closePath={fundPath} />
      </Route>

      <Route path="/:portfolioSlug/fund/:fundID/edit" exact>
        <EditFund
          fund={fund}
          closePath={fundPath}
          relationship_options={relationship_options}
          status_options={status_options}
          reload={reloadFund}
        />
      </Route>

      <Route path="/:portfolioSlug/fund/:fundID/remove" exact>
        <RemoveFund fund={fund} successPath={portfolioPath} cancelPath={fundPath} />
      </Route>
    </>
  )
}
