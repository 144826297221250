import React, { useContext } from 'react'
import styled from 'styled-components'
import { parseISO } from 'date-fns'
import NumberFormat from 'react-number-format'
import Table, {
  CellTitle, CellSubtitle, HamburgerRow, ContentCell, CellDetails
} from '../Shared/Table'
import { DeltaValue, Multiple, prepareCurrency } from '../../Shared/MonetaryValue'
import { EntityContext } from '../../Context/EntityContext'

const ActiveHolding = HamburgerRow

const CancelledHolding = styled(HamburgerRow)`
  ${ContentCell} * {
    text-decoration: line-through;
    opacity: 0.5;
  }
`

export default ({ holdings, showTip }) => {
  const columns = [
    {
      type: 'date',
      value: (row) => (parseISO(row.bestowed_on))
    },
    {
      title: 'Type',
      type: 'stacked',
      render: (holding) => (
        <>
          <CellTitle>
            {holding.holding_type}
            {holding.holding_type === 'Shares' && holding.certificate_status === 'Private - Certificated' && (
              ` (${holding.certificate_name})`
            )}
            {holding.holding_type === 'Shares' && holding.certificate_status === 'Private - Unissued' && (
              <span style={{ color: 'red', fontStyle: 'italic' }}> (no certificate)</span>
            )}
          </CellTitle>
          {(holding.holding_type === 'Shares' || holding.holding_type === 'Option Grant' || holding.holding_type === 'Warrant') && (
            <CellSubtitle>
              <NumberFormat
                value={holding.share_count}
                displayType="text"
                thousandSeparator
              />
              {` shares of ${holding.share_class}`}
              {holding.holding_type === 'Shares' && holding.certificate_location && holding.certificate_location !== '' && (
                ` (${holding.certificate_location})`
              )}
            </CellSubtitle>
          )}
          {(holding.holding_type === 'Tokens') && (
            <CellSubtitle>
              <NumberFormat
                value={prepareCurrency(holding.share_count)}
                displayType="text"
                thousandSeparator
              />
              {` ${holding.share_class} (${holding.entity_legal_name})`}
            </CellSubtitle>
          )}
          {holding.holding_type === 'SPV' && (
            <CellSubtitle>
              {holding.spv_name}
            </CellSubtitle>
          )}
          <CellDetails>{holding.notes}</CellDetails>
        </>
      )
    },
    {
      title: 'Initial Value',
      type: 'monetary',
      value: (row) => (row.initial_value)
    },
    {
      title: 'Current Value',
      type: 'monetary',
      width: 150,
      value: (row) => (row.current_value)
    },
    {
      title: 'Profit/Loss',
      type: 'monetary',
      value: (row) => (row.profit),
      render: (row) => (<DeltaValue value={row.profit} decimals={0} />)
    },
    {
      title: 'Multiple',
      type: 'monetary',
      width: 150,
      value: (row) => (row.multiple),
      render: (row) => (
        <Multiple
          value={row.multiple}
          displayType="text"
          thousandSeparator
          decimalScale="2"
          fixedDecimalScale
          suffix="x"
        />
      )
    }
  ]

  const { financesPath } = useContext(EntityContext)

  const rowType = (row) => {
    if (row.status === 'Cancelled' || row.is_expired) {
      return CancelledHolding
    }

    return ActiveHolding
  }

  return (
    <Table
      columns={columns}
      rows={holdings}
      rowType={rowType}
      defaultSort={{ column: 0, direction: 'asc' }}
      emptyMessage={`No holdings have been recorded. ${showTip ? 'Add one to offset your investment loss.' : ''}`}
      rowMenu={[
        {
          title: 'Edit…',
          select: (row) => (`${financesPath}/holdings/${row.id}/edit`)
        },
        {
          title: 'Remove…',
          select: (row) => (`${financesPath}/holdings/${row.id}/remove`)
        }
      ]}
    />
  )
}
