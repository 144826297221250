export default [
  {
    name: 'Company Name',
    description: 'The display name (not legal name) of the entity which distributed funds, e.g.',
    examples: ['Acme']
  },
  {
    name: 'Received On',
    description: 'When you received the return, e.g.',
    examples: ['1/1/2020']
  },
  {
    name: 'Amount',
    description: 'The dollar value (USD) of the return, e.g.',
    examples: ['$25,000.00']
  }
]
