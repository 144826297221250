import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'
import API from '../Shared/API'
import { UserContext } from '../Context/UserContext'

const Container = styled.div`
    width: 900px;
    margin: 0 auto;
    padding: 40px 0;
    background: radial-gradient(ellipse at top, #142d4c, transparent 70%)
`

const Title = styled.h1`
  color: rgba(255,255,255,0.8);
  font-size: 70px;
  margin: 0;
`

export const Subtitle = styled.h2`
  color: #7094b3;
  font-size: 48px;
  line-height: 60px;
  font-weight: 200;
`

export const Tagline = styled.h3`
  color: #8c9298;
  font-size: 24px;
  font-weight: 200;
`

export const Button = styled(Link)`
  display: block;
  padding: 10px 35px;
  border: 1px solid rgba(255,255,255,0.6);
  color: white;
  font-weight: 100;
  font-size: 30px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 15px;
  transition: all .2s ease-in-out;

  :hover {
    border-color: white;
    background-color: rgba(255,255,255,0.15);
    animation-play-state: paused;
  }

  @keyframes glowing {
    0% {
      background-color: rgba(255,255,255,0.0);
      border-color: rgba(255,255,255,0.6);
    }

    50% {
      background-color: rgba(255,255,255,0.1);
      border-color: rgba(255,255,255,1.0);
    }
  }

  ${({ frozen }) => (frozen
    ? ''
    : `
    transition-timing-function: ease-in-out;
    animation: glowing 3000ms infinite;
  `)};
`

export const VStack = styled.div`
  display: flex;
  flex-direction: column;
`

export const HStack = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export function SignInWithGoogle () {
  const { setToken } = useContext(UserContext)
  const history = useHistory()

  const onSignIn = async ({ credential }) => {
    try {
      const { data: { token } } = (await API.post('/sessions.json', { credential }))
      setToken(token)
      history.replace('/')
    } catch (e) {
      history.push('/get-started/join')
    }
  }

  const onSignInFailure = () => { }

  return (
    <GoogleLogin
      theme="filled_blue"
      onSuccess={onSignIn}
      onError={onSignInFailure}
      useOneTap
    />
  )
}

export function Frame ({ children }) {
  return (
    <Container>
      <HStack>
        <div>
          <Title>Cashlytics.</Title>
        </div>
      </HStack>

      {children}
    </Container>
  )
}
