import React, { useContext } from 'react'
import { parseISO } from 'date-fns'
import Table, { CellTitle, CellSubtitle, CellDetails } from '../Shared/Table'
import { DollarValue } from '../../Shared/MonetaryValue'
import { EntityContext } from '../../Context/EntityContext'

export default ({ milestones, isCrypto }) => {
  const columns = isCrypto
    ? [
        {
          type: 'date',
          value: (row) => (parseISO(row.date))
        },
        {
          title: 'Type',
          type: 'stacked',
          render: (milestone) => (
        <>
          {milestone.type === 'split' && (
            <>
              <CellSubtitle><i>{milestone.description}</i></CellSubtitle>
            </>
          )}
          {milestone.type === 'valuation' && (
            <>
              <CellTitle>{milestone.name}</CellTitle>
              <CellSubtitle>{milestone.source}</CellSubtitle>
              <CellDetails>{milestone.description}</CellDetails>
            </>
          )}
        </>
          )
        },
        {
          title: 'Price',
          type: 'monetary',
          render: (milestone) => (
        <DollarValue
          value={milestone.share_price}
          estimate={milestone.share_price_is_estimate}
          decimals={5}
        />
          )
        },
        {
          title: 'Market Cap',
          type: 'monetary',
          width: 150,
          render: (milestone) => (
        <DollarValue
          value={milestone.valuation}
          estimate={milestone.valuation_is_estimate}
          humanize
        />
          )
        }
      ]
    : [
        {
          type: 'date',
          value: (row) => (parseISO(row.date))
        },
        {
          title: 'Type',
          type: 'stacked',
          render: (milestone) => (
        <>
          {milestone.type === 'split' && (
            <>
              <CellSubtitle><i>{milestone.description}</i></CellSubtitle>
            </>
          )}
          {milestone.type === 'valuation' && (
            <>
              <CellTitle>{milestone.name}</CellTitle>
              <CellSubtitle>{milestone.source}</CellSubtitle>
              <CellDetails>{milestone.description}</CellDetails>
            </>
          )}
        </>
          )
        },
        {
          title: 'Share Price',
          type: 'monetary',
          render: (milestone) => (
        <DollarValue
          value={milestone.share_price}
          estimate={milestone.share_price_is_estimate}
          decimals={5}
        />
          )
        },
        {
          title: 'Round Size',
          type: 'monetary',
          render: (milestone) => (
        <DollarValue
          value={milestone.round_size}
          estimate={milestone.valuation_is_estimate}
          humanize
        />
          )
        },
        {
          title: 'Post Valuation',
          type: 'monetary',
          width: 150,
          render: (milestone) => (
        <DollarValue
          value={milestone.valuation}
          estimate={milestone.valuation_is_estimate}
          humanize
        />
          )
        }
      ]

  const { financesPath } = useContext(EntityContext)

  return (
    <Table
      columns={columns}
      rows={milestones}
      rowKey={(row) => (row.key)}
      defaultSort={{ column: 0, direction: 'asc' }}
      emptyMessage="No valuations have been reported."
      rowMenu={[
        {
          title: 'Edit…',
          select: (row) => (`${financesPath}/${row.type}s/${row.id}/edit`)
        },
        {
          title: 'Remove…',
          select: (row) => (`${financesPath}/${row.type}s/${row.id}/remove`)
        }
      ]}
    />
  )
}
