import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import SyncLoader from 'react-spinners/SyncLoader'
import { useHistory } from 'react-router-dom'
import { Submit as FormSubmit, Button as FormButton } from './Form'

const Modal = styled.div`
    margin: ${({ smaller }) => (smaller ? '200px' : '150px')} auto 60px auto;
    padding 25px;
    width: ${({ smaller }) => (smaller ? '450px' : '500px')};
    background-color: #eee;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    form {
        display: flex;
        flex-direction: column;

        label {
            display: block;
            margin-bottom: 4px;
        }

        input, select, textarea {
            width: 100%;
            box-sizing: border-box;
            font-size: 1.2em;
            padding: 10px 15px;
            border: 1px solid #ccc;
            border-radius: 3px;

            ::placeholder {
                color: #bbb;
            }
        }

        .react-datepicker-wrapper {
          display: block;
        }
    }

    ${({ shake }) => (shake
? `
      animation: shake 0.5s steps(1) 1;
    `
: '')}

    @keyframes shake {
      0% { transform: translate(1px, 1px) rotate(0deg); }
      10% { transform: translate(-1px, -2px) rotate(-1deg); }
      20% { transform: translate(-3px, 0px) rotate(1deg); }
      30% { transform: translate(3px, 2px) rotate(0deg); }
      40% { transform: translate(1px, -1px) rotate(1deg); }
      50% { transform: translate(-1px, 2px) rotate(-1deg); }
      60% { transform: translate(-3px, 1px) rotate(0deg); }
      70% { transform: translate(3px, 1px) rotate(-1deg); }
      80% { transform: translate(-1px, -1px) rotate(1deg); }
      90% { transform: translate(1px, 2px) rotate(0deg); }
      100% { transform: translate(1px, -2px) rotate(-1deg); }
    }
`

export const ModalLoader = () => (
  <Overlay>
    <FixedBody />
    <Modal smaller>
      <SyncLoader color="#5c738b" size={10} css={{ margin: '0 auto', width: 50 }} />
    </Modal>
  </Overlay>
)

const FixedBody = createGlobalStyle`
  body {
    height: 100vh;
    overflow-y: hidden;
    overscroll-behavior: none;
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: scroll;
  overflow-x: hidden;
`

export const Submit = styled(FormSubmit)`
    font-size: 1.2em;
    font-weight: 400;
    padding: 10px 15px;
    border-radius: 3px;
    margin-top: 15px;
    background-color: #395e7c;
    color: white;
    border: none;
    cursor: pointer;
    transition: all .2s ease-in-out;
    width: 100%;
    box-sizing: border-box;

    :hover {
      filter: brightness(150%);
    }

    &:disabled {
        background-color: #444;

        :hover {
          filter: none;
        }
    }
`

export const Button = styled(FormButton)`
    font-size: 1.2em;
    font-weight: 400;
    padding: 10px 15px;
    border-radius: 3px;
    margin-top: 15px;
    margin-right: 15px;
    background-color: #666;
    color: white;
    border: none;
    cursor: pointer;
    transition: all .2s ease-in-out;
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;

    :hover {
      filter: brightness(150%);
    }

    &:disabled {
        background-color: #888;

        :hover {
          filter: none;
        }
    }
`

export const Title = styled.h1`
    font-size: 1.9em;
    font-weight: 300;
    margin: 0 0 0.25em 0;
`

export const Subtitle = styled.h2`
    font-weight: normal;
    margin-top: 0;
    font-size: 1.1em;
    opacity: 0.6;
`

export const Section = styled.div`
  flex-basis: 100px;
  flex-grow: 1;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 0px 8px 0 rgba(0,0,0,0.15);
  overflow: auto;
  margin: 20px 0;
`

export const SectionTitle = styled.h2`
    font-weight: normal;
    font-size: 1.1em;
    border-bottom: 2px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 10px;
    margin-top: 20px;
`

export const useModal = (onCloseRedirect) => {
  const history = useHistory()
  const closeModal = (redirectTo) => {
    history.replace(redirectTo || onCloseRedirect)
  }

  const handleOnClick = (e) => {
    if (e.currentTarget !== e.target) {
      return
    }

    closeModal()
  }

  const ModalFrame = ({
    children, smaller, shake, disableClose
  }) => (
    <Overlay onMouseDown={disableClose ? null : handleOnClick}>
      <FixedBody />
      <Modal smaller={smaller} shake={shake}>
        {children}
      </Modal>
    </Overlay>
  )

  return {
    Modal: ModalFrame,
    closeModal
  }
}
