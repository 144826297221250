import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Title, useModal } from '../../Shared/Modal'
import API, { useAPIGet, AwaitResponse } from '../../Shared/API'
import { EntityContext } from '../../Context/EntityContext'
import HoldingForm from './HoldingForm'

export default function EditHolding ({ returnPath }) {
  const { holdingID } = useParams()
  const { reloadEntity, entityAPI } = useContext(EntityContext)
  const { api } = useAPIGet(`${entityAPI}/holdings/${holdingID}.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const editHolding = async (holding) => {
    await API.put(`${entityAPI}/holdings/${holdingID}.json`, { holding })

    closeModal()
    reloadEntity()
  }

  return (
    <AwaitResponse api={api}>
      {({
        holding, holding_type_options, certificate_options,
        status_options, fund_options
      }) => (
        <Modal>
          <Title>Edit Holding</Title>

          <HoldingForm
            onSubmit={editHolding}
            holding={holding}
            status_options={status_options}
            fund_options={fund_options}
            holding_type_options={holding_type_options}
            certificate_options={certificate_options}
          />
        </Modal>
      )}
    </AwaitResponse>
  )
}
