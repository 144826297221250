import React, { useContext } from 'react'
import {
  Title, Subtitle, useModal
} from '../../Shared/Modal'
import { UserContext } from '../../Context/UserContext'
import API, { useAPIGet, AwaitResponse } from '../../Shared/API'
import FundForm from './FundForm'

export default function NewFund ({
  smaller, funds, closePath, reload
}) {
  const { portfolioPath, portfolioAPI } = useContext(UserContext)
  const { Modal, closeModal } = useModal(closePath || portfolioPath)
  const { api } = useAPIGet(`${portfolioAPI}/funds/new.json?relationship=Manager`, [])

  const createFund = async (fund) => {
    await API.post(`${portfolioAPI}/funds.json`, { fund })
    reload()
    closeModal()
  }

  if (funds.length === 0) {
    return (
      <AwaitResponse api={api}>
        {(fund) => (
          <Modal smaller={smaller}>
            <Title>Set Up Your First Fund</Title>

            <Subtitle>
              Cashlytics makes it easy to manage multiple funds, all as part of the same
              broader portfolio. Let&apos;s set up your first fund.
            </Subtitle>

            <FundForm fund={fund} onSubmit={createFund} onCancel={false} />
          </Modal>
        )}
      </AwaitResponse>
    )
  }

  return (
    <AwaitResponse api={api}>
      {(fund) => (
        <Modal smaller={smaller}>
          <Title>Add Fund</Title>

          <Subtitle>
            Tell us about your new fund.
          </Subtitle>

          {/* Must enclose closeModal in an outer function to discard the event param */}
          <FundForm fund={fund} onSubmit={createFund} onCancel={() => closeModal()} />
        </Modal>
      )}
    </AwaitResponse>
  )
}
