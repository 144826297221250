import React, { useContext } from 'react'
import { Title, Subtitle, useModal } from '../../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../../Shared/API'
import CallForm from './CallForm'
import { FundContext } from '../../../Context/FundContext'

export default function NewCall ({ returnPath }) {
  const {
    reloadFund, fund, fundAPI, fundPath
  } = useContext(FundContext)
  const { api } = useAPIGet(`${fundAPI}/capital_calls/new.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const addCall = async (call) => {
    const { error, data: result } = (await API.post(`${fundAPI}/capital_calls.json`, { capital_call: call }))

    if (!error) {
      closeModal(`${fundPath}/calls/new/${result.id}`)
      reloadFund()
    }
  }

  return (
    <AwaitResponse api={api}>
      {(call) => (
        <Modal>
          <Title>Record Capital Call</Title>
          <Subtitle>
            How much did
            {' '}
            <b><nobr>{fund.display_name}</nobr></b>
            {' '}
            call?
          </Subtitle>

          <CallForm
            call={call}
            onSubmit={addCall}
          />
        </Modal>
      )}
    </AwaitResponse>
  )
}
