import React from 'react'
import { PrimaryTable, CellTitle, CellSubtitle } from '../Shared/Table'
import { DollarValue, ComparativeValue } from '../../Shared/MonetaryValue'
import { VStack } from '../Shared/UI'

export default ({ funds, basePath }) => {
  const columns = [
    {
      title: 'Name',
      type: 'stacked',
      value: (fund) => (fund.display_name),
      render: (row) => (
        <>
          <CellTitle>{row.display_name}</CellTitle>
          <CellSubtitle>{row.legal_name}</CellSubtitle>
        </>
      )
    },
    {
      title: 'Current Value / Total Invested',
      width: '100px',
      type: 'monetary',
      render: (fund) => (
        <VStack>
          <CellTitle><ComparativeValue value={fund.analysis.current_value} comparison={fund.analysis.total_invested} /></CellTitle>
          <CellSubtitle><DollarValue value={fund.analysis.total_invested} /></CellSubtitle>
        </VStack>
      ),
      value: (fund) => (fund.analysis.current_value),
      secondarySort: (fund) => (fund.display_name)
    }
  ]

  return (
    <PrimaryTable
      footer
      columns={columns}
      rows={funds}
      defaultSort={{ column: 0, direction: 'asc' }}
      emptyMessage="This portfolio is not currently split into funds."
      rowMenu={[
        {
          title: 'Edit…',
          select: (row) => (`${basePath}/${row.id}/edit`)
        },
        {
          title: 'Remove…',
          select: (row) => (`${basePath}/${row.id}/remove`)
        }
      ]}
    />
  )
}
