import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import DotLoader from 'react-spinners/DotLoader'
import { Link, NavLink as RouterNavLink } from 'react-router-dom'

export const BackgroundLoader = () => (
  <DotLoader color="#5c738b" css={{ margin: '30vh auto;' }} />
)

export const Header = styled.div`
  background-color: #36495d;
  display: flex;
  flex-direction: column;
  border-radius: 5px 5px 0 0;
  border: 1px solid #5c738b;
  border-bottom: none;
  overflow: auto;
  flex-shrink: 0;
`

export const Subject = styled.div`
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
`

export const Title = styled.h1`
    font-size: 20px;
    font-weight: 600;
    margin: 0;
`

export const Subtitle = styled.h2`
    font-size: 14px;
    font-weight: 500;
    opacity: 0.7;
    margin: 0;
`

export const Description = styled.h2`
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    opacity: 0.6;
    margin: 0;
`

export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
`

export const NavLink = styled(RouterNavLink)`
  text-decoration: none;
  padding: 7px 15px;
  color: rgba(255,255,255,0.7);
  font-weight: 500;
  border-radius: 5px 5px 0 0;
  margin-right: 10px;

  &:first-child {
    margin-left: 20px;
  }

  &:hover, &.active {
    color: white;
    background-color: rgba(255,255,255, 0.3);
  }
`

export const AddLink = styled(Link)`
  :before {
    content: "\uFF0B";
    padding-right: 2px;
  }
`

export const AddNavLink = styled(RouterNavLink)`
  text-decoration: none;
  padding: 7px 15px;
  color: rgba(255,255,255,0.7);
  font-weight: 500;
  border-radius: 5px 5px 0 0;
  margin-right: 10px;

  :before {
    content: "\uFF0B";
    padding-right: 2px;
  }

  :hover {
    color: white;
  }
`

export const MinusNavLink = styled(AddNavLink)`
  :before {
    content: "\u2212";
    padding-right: 5px;
  }
`

export const Left = styled.div`
  display: flex;
`

export const Right = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Container = styled.div`
  width: 1100px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const Contents = styled.div`
  background-color: rgba(255.0, 255.0, 255.0, 0.84);
  border-radius: 0 0 5px 5px;
  border: 1px solid rgba(255.0, 255.0, 255.0, 0.84);
  box-shadow: inset 0 0px 8px 0 rgba(0,0,0,0.2);
  margin-bottom: 40px;
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 0;
`

export const Section = styled.div`
  flex-basis: 100px;
  flex-grow: 1;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 0px 8px 0 rgba(0,0,0,0.15);
  overflow: auto;
  margin: 20px;
`

export const SectionTitle = styled.h2`
  font-size: 16px;
  text-transform: uppercase;
  color: #444;
  margin: 0;
`

const SectionHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;

  a {
    text-decoration: none;
    color: #395e7c;
    font-weight: 500;
    transition: all .1s ease-in-out;

    :hover {
      filter: brightness(150%);
    }
  }
`

export const SectionHeader = ({ title, children }) => (
  <SectionHeaderContainer>
    {title && (<SectionTitle>{title}</SectionTitle>)}
    {children}
  </SectionHeaderContainer>
)

export const Row = styled.div`
  display: flex;

  > ${Section} {
    margin-bottom: 0 !important;
    margin-left: 0 !important;

    &:first-child {
      margin-left: 20px !important;
    }
  }
`

const HamburgerMenuContainer = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  min-width: 100px;
  border: 2px solid white;
  box-shadow: 0 0px 8px 0 rgba(0,0,0,0.15);
  z-index: 1000;
`

const HamburgerMenuItem = styled.div`
  color: #395e7c;
  font-weight: 500;
  padding: 5px 20px 5px 10px;
  cursor: pointer;
  white-space: nowrap;

  :hover {
    background-color: #e9f3fb;
  }

  ${({ selected }) => (selected
? `
    animation: blink 0.15s steps(1) 1;
  `
: '')}

  @keyframes blink {
    0% { background-color: transparent; }
    50% { background-color: #e9f3fb; }
    100% { background-color: transparent; }
  }
`

const HamburgerMenuSeparator = styled.div`
  height: 1px;
  background-color: #eee;
  margin: 2px 0;
`

export const Menu = ({
  menu, onClose, onSelect, style
}) => {
  const myRef = useRef()
  const watchForClose = (e) => {
    if (!myRef.current.contains(e.target)) {
      e.preventDefault()
      e.stopPropagation()
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', watchForClose, false)

    return () => {
      document.removeEventListener('mousedown', watchForClose, false)
    }
  })

  const [selectedItemTitle, setSelectedItemTitle] = useState()

  return (
    <HamburgerMenuContainer ref={myRef} style={style}>
      { menu.map((item) => {
        const startSelectItem = (e) => {
          setSelectedItemTitle(item.title)
          e.stopPropagation()
        }

        const doSelectItem = () => {
          onClose()
          onSelect(item)
        }

        return (
          <HamburgerMenuItem
            key={item.title}
            onClick={startSelectItem}
            selected={item.title === selectedItemTitle}
            onAnimationEnd={doSelectItem}
          >
            {item.title}
          </HamburgerMenuItem>
        )
      }).reduce((acc, elem) => (acc === null
        ? [elem]
        : [acc, (<HamburgerMenuSeparator key={`${elem.key}-sep`} />), elem]), null)}
    </HamburgerMenuContainer>
  )
}

export const HStack = styled.div`
  display: flex;
  align-items: center;
`

export const VStack = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderHamburgerIcon = styled.div`
  padding: 0 5px;
  cursor: pointer;

  &:after, &:before, > div {
    background-color: #999;
    border-radius: 2.5px;
    content: '';
    display: block;
    height: 5px;
    width: 5px;
    margin: 5px 0;
  }

  :hover {
    &:after, &:before, > div {
      background-color: white;
    }
  }
`

const HeaderHamburgerContainer = styled.div``
export const HeaderHamburger = ({
  menu, displayMenu, onOpen, onClose, onSelect, hOffset
}) => (
  <HeaderHamburgerContainer onClick={onOpen}>
    {displayMenu && (
    <Menu
      menu={menu}
      onClose={onClose}
      onSelect={onSelect}
      style={{ marginLeft: hOffset ? `${hOffset}px` : 0 }}
    />
    )}
    <HeaderHamburgerIcon style={{ visibility: (displayMenu ? 'hidden' : 'visible') }}><div /></HeaderHamburgerIcon>
  </HeaderHamburgerContainer>
)

const FaviconImage = styled.img`
  width: ${({ size }) => `${size || 32}px;`}
  height: ${({ size }) => `${size || 32}px;`}
  margin-right: 12px;
  border-radius: 5px;

  ${({ background }) => background && `
    background-color: white;
    border: 3px solid white;
  `}
`

const FaviconBackground = styled.div`
  width: ${({ size }) => `${size || 32}px;`}
  height: ${({ size }) => `${size || 32}px;`}
  margin-right: 12px;
  border-radius: 5px;
  background-color: #aaa;
  text-align: center;
  line-height: ${({ size }) => `${(size || 32) + 4}px;`}
  font-weight: 600;
  font-size: ${({ size }) => `${((size || 32) / 32) * 20}px;`};
  color: white;

  ${({ background }) => background && `
    border: 3px solid white;
  `}
`

export const Favicon = ({
  size, background, subject, style
}) => (subject.icon_data
  ? (
    <FaviconImage
      size={size}
      background={background}
      style={style}
      src={`data:${subject.icon_content_type || 'image/gif'};base64,${subject.icon_data}`}
    />
    )
  : (
    <FaviconBackground
      size={size}
      background={background}
      style={style}
    >
      {(subject.display_name || '?').toUpperCase()[0]}
    </FaviconBackground>
    ))

export const StatusColors = {
  Acquired: 'green',
  Uncertain: 'orange',
  Liquidated: 'red',
  Operating: '#8ab4de',
  Passed: 'red',
  'Public (Liquid)': 'limegreen',
  'Public (Locked Up)': 'darkgreen',
  Inactive: 'red'
}

export const KindColors = {
  Public: 'limegreen',
  Cryptocurrency: 'purple'
}

export const StatusPill = styled.span`
    box-shadow: inset 0px 1px 2px rgba(0,0,0,0.2);
    font-weight: 900;
    text-transform: uppercase;
    border-radius: 1ex;
    border: 1px solid ${(props) => (StatusColors[props.status])};
    background-color: ${(props) => (StatusColors[props.status])};
    color: white;
    padding: 8px 12px 6px 12px;
    text-decoration: none!important;
    margin-right: 15px;
    opacity: 0.75;
  `

export const TitleLink = styled.a`
    color: inherit;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  `
