import React, { useContext } from 'react'
import { GoogleLogin } from '@react-oauth/google'
import { useParams } from 'react-router-dom'
import {
  Title, useModal, Subtitle, Submit
} from '../Shared/Modal'
import API, { useAPIGet, AwaitResponse } from '../Shared/API'
import { UserContext } from '../Context/UserContext'

function MissingInvite () {
  const { Modal, closeModal } = useModal('/')

  return (
    <Modal>
      <Title>
        Invitation Expired
      </Title>
      <Subtitle>
        Sorry, this invitation is no longer available.
        It has either expired or has already been used.
      </Subtitle>

      <Submit title="OK" onClick={() => closeModal()} />
    </Modal>
  )
}

export default function Join () {
  const { setToken } = useContext(UserContext)
  const { Modal, closeModal } = useModal()
  const { code } = useParams()
  const { api } = useAPIGet(`/sessions/join/${code}.json`, [])

  const onSignIn = async ({ credential }) => {
    try {
      const { data: { token } } = (await API.post('/sessions.json', { credential }))
      setToken(token)
      closeModal(`/accept/${code}`)
    } catch (e) {
      console.log(e)
    }
  }

  const onSignInFailure = () => {}

  return (
    <AwaitResponse api={api} error={MissingInvite}>
      {(invite) => (
        <Modal>
          <Title>
            Welcome,
            {' '}
            {invite.first_name}
            !
          </Title>
          <Subtitle>
            <b>{invite.inviter_name}</b>
            {' '}
            has invited you to join
            {' '}
            <b>{invite.portfolio_name}</b>
            { ' '}
            on Cashlytics.
          </Subtitle>
          <Subtitle>
            Cashlytics makes investment portfolio tracking fast and delightful,
            so you can say goodbye to tedious spreadsheets.
          </Subtitle>
          <Subtitle>
            Sign in to your
            {' '}
            <b>{invite.email}</b>
            {' '}
            account to accept your invitation:
          </Subtitle>

          <GoogleLogin
            theme="filled_blue"
            onSuccess={onSignIn}
            onError={onSignInFailure}
            useOneTap
          />
        </Modal>
      )}
    </AwaitResponse>
  )
}
