import React, { useContext } from 'react'
import { Title, Subtitle, useModal } from '../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../Shared/API'
import InvestmentForm from './InvestmentForm'
import { EntityContext } from '../../Context/EntityContext'
import useQuery from '../../Shared/useQuery'

export default function NewInvestment ({ returnPath }) {
  const {
    reloadEntity, entity, entityAPI, financesPath
  } = useContext(EntityContext)
  const query = useQuery()
  const { api } = useAPIGet(`${entityAPI}/investments/new.json?${query.toString()}`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const addInvestment = async (investment) => {
    const { error, data: result } = (await API.post(`${entityAPI}/investments.json`, { investment }))

    if (!error) {
      closeModal(`${financesPath}/holdings/new/${result.id}`)
      reloadEntity()
    }
  }

  return (
    <AwaitResponse api={api}>
      {({
        investment,
        round_series_options,
        fund_options
      }) => (
        <Modal>
          <Title>Record Investment</Title>
          <Subtitle>
            {entity.kind === 'Cryptocurrency' ? 'How much ' : 'What are you investing in '}
            <b><nobr>{entity.stock_symbol || entity.display_name}</nobr></b>
            {entity.kind === 'Cryptocurrency' ? ' are you buying?' : '?'}
          </Subtitle>

          <InvestmentForm
            investment={investment}
            round_series_options={round_series_options}
            fund_options={fund_options}
            onSubmit={addInvestment}
          />
        </Modal>
      )}
    </AwaitResponse>
  )
}
