import React, { useContext, useEffect } from 'react'
import {
  Switch, Redirect, Route, useParams
} from 'react-router-dom'
import Portfolios from './Portfolios'
import Entity from './Entities'
import Prospect from './Prospects'
import { UserContext } from '../Context/UserContext'
import { EntityProvider } from '../Context/EntityContext'
import { BackgroundLoader } from './Shared/UI'
import Funds from './Funds'
import { FundProvider } from '../Context/FundContext'

export default function Routes () {
  const { selectPortfolio, portfolio, portfolioPath } = useContext(UserContext)
  const { portfolioSlug } = useParams()

  useEffect(() => {
    selectPortfolio(portfolioSlug)

    return () => {
      selectPortfolio(null)
    }
  })

  if (portfolio === undefined) {
    return (<BackgroundLoader />)
  }

  return portfolio
    ? (
    <Switch>
      {/* Entity Routes */}
      <Route path="/:portfolioSlug/entities/:entityIDSlug">
        <EntityProvider>
          <Entity baseRoute="/:portfolioSlug/entities/:entityIDSlug" />
        </EntityProvider>
      </Route>
      <Route path="/:portfolioSlug/prospects/:entityIDSlug">
        <EntityProvider>
          <Prospect />
        </EntityProvider>
      </Route>
      <Route path="/:portfolioSlug/fund/:fundID/entities/:entityIDSlug">
        <FundProvider>
          <EntityProvider>
            <Entity baseRoute="/:portfolioSlug/fund/:fundID/entities/:entityIDSlug" />
          </EntityProvider>
        </FundProvider>
      </Route>

      {/* Fund Routes */}
      <Route path="/:portfolioSlug/fund/:fundID">
        <FundProvider>
          <Funds />
        </FundProvider>
      </Route>

      {/* Portfolio Routes */}
      <Route path="/:portfolioSlug">
        <Portfolios />
      </Route>

      <Redirect to={portfolioPath} />
    </Switch>
      )
    : (<Redirect to="/" />)
}
