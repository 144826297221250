import React, { useState } from 'react'
import styled from 'styled-components'
import DotLoader from 'react-spinners/DotLoader'
import { format as formatDate } from 'date-fns'
import { useHistory, Link } from 'react-router-dom'
import { DollarValue } from '../../Shared/MonetaryValue'
import { Menu } from './UI'

const HTMLTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  tbody tr {
    padding: 0;
    margin: 0;

    ${({ footer }) => (
    footer && `
    &:last-child td {
      border-bottom: 1px solid #ddd;

      &:first-child {
        border-color: transparent;
      }

      &:last-child {
        border-color: transparent;
      }
    }
        `
  )}

    &:first-child td {
      border-top: 2px solid #ddd;

      &:first-child {
        border-color: transparent;
      }

      &:last-child {
        border-color: transparent;
      }
    }
  }

  td, th {
    padding: 10px;
    margin: 0;
    border-top: 1px solid #ddd;
    box-sizing: border-box;

    &:first-child {
      border-color: transparent;
    }

    &:nth-child(2) {
      padding-left: 5px;
    }

    &:nth-last-child(2) {
      padding-right: 5px;
    }

    &:last-child {
      border-color: transparent;
    }
  }

  thead tr th {
    border-top: none;
    padding-bottom: 5px;
  }
`

export const Header = styled.th`
  text-align: left;
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: smaller;
  color: ${({ sortDirection }) => (sortDirection === 'none' ? '#777' : 'black')};
  white-space: nowrap;

  ${({ sortDirection }) => {
    if (sortDirection === 'asc') {
      return `
        &::before {
          content: "\\2193  ";
        }
      `
    }

    if (sortDirection === 'desc') {
      return `
        &::before {
          content: "\\2191  ";
        }
      `
    }

    return `
      &::before {
        content: "   ";
      }
    `
  }};

  ${({ sortable }) => {
    if (sortable) {
      return `
        cursor: pointer;

        :hover {
          color: black;
        }
      `
    }

    return ''
  }};
`

export const MonetaryHeader = styled(Header)`
  text-align: right;
`

export const DateHeader = styled(Header)`
  text-align: right;
  width: 75px;
`

export const ActionRow = styled.tr`
  cursor: pointer;

  :hover {
    background-color: #e9f3fb;

    td {
      border-color: #ddd!important;
    }
  }

  :hover + tr {
    td {
      border-color: #ddd!important;
    }
  }
`

export const HamburgerIcon = styled.div`
  padding: 0 5px;
  cursor: pointer;
`

export const HamburgerRow = styled.tr`
  ${({ displayMenu }) => {
    if (displayMenu) {
      return `
      background-color: #e9f3fb;
    `
    }

    return ''
  }};

  :hover {
    td {
      border-color: #ddd!important;
    }

    ${HamburgerIcon} {
      &:after, &:before, > div {
        background-color: #999;
        border-radius: 2px;
        content: '';
        display: block;
        height: 4px;
        width: 4px;
        margin: 4px 0;
      }

      :hover {
        &:after, &:before, > div {
          background-color: #395e7c;
        }
      }
    }
  }

  :hover + tr {
    td {
      border-color: #ddd!important;
    }
  }
`

const Hamburger = ({
  menu, displayMenu, onOpen, onClose, onSelect
}) => (
  <HamburgerCell onClick={onOpen}>
    {displayMenu
      ? <Menu menu={menu} onClose={onClose} onSelect={onSelect} />
      : <HamburgerIcon><div /></HamburgerIcon>}
  </HamburgerCell>
)

function Table ({
  style,
  columns,
  className,
  rows,
  rowKey,
  rowType,
  onRowClick,
  defaultSort,
  emptyMessage,
  rowMenu,
  footer
}) {
  const history = useHistory()
  const [sort, setSort] = useState(defaultSort || { column: 0, direction: 'desc' })
  const [displayHamburgerMenuForRowId, setDisplayHamburgerMenuForRowId] = useState()
  const tableIsSortable = rows.length > 0
  const emptyContent = rows.length === 0
    ? (<EmptyRow span={columns.length}>{emptyMessage || 'No data.'}</EmptyRow>)
    : undefined

  let sortedRows = rows
  if (columns[sort.column].value) {
    sortedRows = rows.sort((a, b) => {
      const vA = columns[sort.column].value(a)
      const vB = columns[sort.column].value(b)

      if (vA < vB) {
        return -1
      } if (vB < vA) {
        return 1
      }

      if (columns[sort.column].secondarySort) {
        const sA = columns[sort.column].secondarySort(a)
        const sB = columns[sort.column].secondarySort(b)

        if (sA < sB) {
          return -1
        } if (sB < sA) {
          return 1
        }

        if (columns[sort.column].tertiarySort) {
          const tA = columns[sort.column].tertiarySort(a)
          const tB = columns[sort.column].tertiarySort(b)

          if (tA < tB) {
            return -1
          } if (tB < tA) {
            return 1
          }
        }
      }

      return 0
    })

    if (sort.direction === 'desc') {
      sortedRows = sortedRows.reverse()
    }
  }

  const onHeaderClick = (event) => {
    const sortIndex = Number.parseInt(event.currentTarget.getAttribute('data-index'), 10)
    const currentSortDirection = event.currentTarget.getAttribute('data-sort-direction')
    const sortable = (tableIsSortable && columns[sortIndex].value !== undefined)

    if (!sortable) {
      return
    }

    setSort({
      column: sortIndex,
      direction: (currentSortDirection === 'desc' ? 'asc' : 'desc')
    })
  }

  return (
    <HTMLTable className={className} style={style} footer={footer}>
      <thead>
        <tr>
          <PaddingCell />
          {
            columns.map((column, i) => {
              let HeaderType = Header
              let { title, key } = column
              const sortDirection = (tableIsSortable && sort.column === i) ? sort.direction : 'none'
              const sortable = (tableIsSortable && column.value !== undefined)

              if (column.type === 'date') {
                HeaderType = DateHeader
                title = title || 'Date'
              } else if (column.type === 'monetary') {
                HeaderType = MonetaryHeader
              }

              return (
                <HeaderType
                  key={title || key}
                  data-index={i}
                  data-sort-direction={sortDirection}
                  sortDirection={sortDirection}
                  sortable={sortable}
                  onClick={onHeaderClick}
                >
                  {title}
                </HeaderType>
              )
            })
          }
          <PaddingCell />
        </tr>
      </thead>
      <tbody>
        {
          emptyContent || sortedRows.map((row) => {
            const ClickRowType = onRowClick ? ActionRow : styled.tr``
            const BaseRowType = rowMenu ? HamburgerRow : ClickRowType
            const RowType = rowType ? rowType(row) : BaseRowType
            const key = rowKey ? rowKey(row) : row.id
            const displayMenu = (key === displayHamburgerMenuForRowId)

            return (
              <RowType
                key={key}
                data-key={key}
                onClick={(e) => onRowClick && onRowClick(e, row)}
                displayMenu={displayMenu}
              >
                <PaddingCell />
                {
                  columns.map((column, i) => {
                    const {
                      value, display, render, width
                    } = column
                    const sorted = tableIsSortable && sort.column === i

                    if (column.type === 'status') {
                      return (<StatusCell key={`${row.key}-${i}`} color={column.color(row)} />)
                    }

                    if (column.type === 'date') {
                      return (<DateCell key={`${row.key}-${i}`} date={value(row)} display={display && display(row)} style={{ fontWeight: sorted && 500 }} />)
                    }

                    if (column.type === 'monetary') {
                      const contents = render ? render(row) : (<DollarValue value={value(row)} />)
                      return (<MonetaryCell key={`${row.key}-${i}`} width={width || 130} style={{ fontWeight: sorted && 500 }}>{contents}</MonetaryCell>)
                    }

                    if (column.type === 'stacked') {
                      return (<StackedCell key={`${row.key}-${i}`} width={width}>{render(row)}</StackedCell>)
                    }

                    const contents = render ? render(row) : (<CellTitle>{value(row)}</CellTitle>)
                    return (<ContentCell key={`${row.key}-${i}`} width={width}>{contents}</ContentCell>)
                  })
                }

                {rowMenu
                  ? (
                    <Hamburger
                      menu={rowMenu}
                      displayMenu={displayMenu}
                      onOpen={() => { setDisplayHamburgerMenuForRowId(key) }}
                      onClose={() => { setDisplayHamburgerMenuForRowId(undefined) }}
                      onSelect={(item) => { history.replace(item.select(row)) }}
                    />
                    )
                  : (<PaddingCell />)}
              </RowType>
            )
          })
        }
      </tbody>
    </HTMLTable>
  )
}

export default Table
export const PrimaryTable = styled(Table)`
  margin-top: 20px;
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContentCell = styled.td``

export const StackedCell = ({ children, width }) => (
  <ContentCell width={width}>
    <Stack>{children}</Stack>
  </ContentCell>
)

const StatusPill = styled.div`
  box-shadow: inset 0px 1px 2px rgba(0,0,0,0.2);
  font-size: 10px;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 100vw;
  background-color: ${(props) => (props.color)};
  color: white;
  height: 40px;
  width: 5px;
`

export const StatusCell = ({ children, color }) => (
  <ContentCell width={7}>
    <StatusPill color={color} />
  </ContentCell>
)

export const CellTitle = styled.a`
  text-decoration: none;
  font-weight: 500;
`

export const CellSubtitle = styled.small`
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CellDetails = styled.span`
  color: #777;
  font-size: 11px;
`

export const CellDescription = styled(CellSubtitle)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 600px;
`

export const DefaultActionButton = styled(Link)`
  font-weight: 400;
  padding: 3px 12px;
  border-radius: 3px;
  margin-top: 15px;
  margin-left: 10px;
  cursor: pointer;
  transition: all .1s ease-in-out;
  text-decoration: none;
  border: 2px solid #395e7c;

  background-color: #395e7c;
  color: white;

  :hover {
    filter: brightness(150%);
  }
`

export const SecondaryActionButton = styled(DefaultActionButton)`
  color: #395e7c;
  background-color: white;
`

export const PaddingCell = styled.td`
  width: 15px;
`

export const MonetaryCell = styled(ContentCell)`
  text-align: right;
`

export const HamburgerCell = styled.td`
  padding: 0!important;
`

export const TableLoader = () => (
  <DotLoader color="gray" size={30} css={{ margin: '20px auto;' }} />
)

const Date = styled(ContentCell)`
  text-transform: uppercase;
  text-align: right;
  font-size: 11px;
  font-weight: 600;
  color: #777;
`

export const DateCell = ({ date, display }) => (
  <Date title={formatDate(date, 'MMMM d, yyy')}>
    <nobr>{display || formatDate(date, 'MMM yyy')}</nobr>
  </Date>
)

const MessageCell = styled(ContentCell)`
  text-align: center;
  font-style: italic;
  color: #666;
`

export const EmptyRow = ({ span, children }) => (
  <tr>
    <PaddingCell />
    <MessageCell colSpan={span}>
      {children}
    </MessageCell>
    <PaddingCell />
  </tr>
)
