import React from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Switch, Redirect, Route } from 'react-router-dom'
import Welcome from './Welcome'
import Join from './Join'

export default function StaticRoutes () {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <Switch>
        <Route path="/" exact>
          <Welcome />
        </Route>

        <Route path="/join/:code">
          <>
            <Welcome />
            <Join />
          </>
        </Route>

        <Redirect to="/" />
      </Switch>
    </GoogleOAuthProvider>
  )
}
