import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import {
  Title, useModal
} from '../../Shared/Modal'
import { UserContext } from '../../Context/UserContext'
import API from '../../Shared/API'
import FundForm from './FundForm'

export default function EditFund ({
  fund: providedFund, smaller, funds, closePath, reload, relationship_options, status_options
}) {
  const { fundID } = useParams()
  const { portfolioPath, portfolioAPI } = useContext(UserContext)
  const { Modal, closeModal } = useModal(closePath || portfolioPath)
  const fund = providedFund || funds.find((f) => f.id === Number.parseInt(fundID, 10)) || null

  const editFund = async (fund) => {
    await API.put(`${portfolioAPI}/funds/${fundID}.json`, { fund })
    if (reload) {
      reload()
    }
    closeModal()
  }

  return (
    <Modal smaller={smaller}>
      <Title>
        Edit
        {' '}
        {fund.display_name}
      </Title>

      {/* Must enclose closeModal in an outer function to discard the event param */}
      <FundForm
        fund={fund}
        onSubmit={editFund}
        onCancel={() => closeModal()}
        relationship_options={relationship_options}
        status_options={status_options}
      />
    </Modal>
  )
}
