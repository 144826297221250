import React, { useContext } from 'react'
import styled from 'styled-components'
import { Route } from 'react-router-dom'
import {
  Title, useModal, Subtitle, Section, Submit
} from '../../Shared/Modal'
import { useAPIGet, AwaitResponse } from '../../Shared/API'
import { UserContext } from '../../Context/UserContext'
import { AddLink } from '../Shared/UI'
import FundsTable from './FundsTable'
import NewFund from './NewFund'
import EditFund from './EditFund'
import RemoveFund from './RemoveFund'

const AddFund = styled(AddLink)`
  text-decoration: none;
  color: #395e7c;
  font-weight: 500;
  display: block;
  margin: 10px 0 15px 20px;
`

export default function ManageFunds () {
  const {
    portfolioPath,
    portfolioAPI
  } = useContext(UserContext)
  const { Modal, closeModal } = useModal(portfolioPath)
  const { api, reload } = useAPIGet(`${portfolioAPI}/funds.json`, [])
  const currentPath = `${portfolioPath}/funds`

  return (
    <AwaitResponse api={api}>
      {(funds) => (
        <>
          <Modal>
            <Title>Manage Funds</Title>
            <Subtitle>
              Cashlytics makes it easy to manage multiple funds, all as part of the same
              broader portfolio. If you make investments out of different funds, set them up here.
            </Subtitle>

            <Section>
              <FundsTable funds={funds} basePath={currentPath} />
              <AddFund to={`${portfolioPath}/funds/new`}>Add</AddFund>
            </Section>

            <Submit onClick={() => closeModal()} title="Done" />
          </Modal>

          <Route path="/:portfolioSlug/funds/new" exact>
            <NewFund smaller funds={funds} closePath={currentPath} reload={reload} />
          </Route>
          <Route path="/:portfolioSlug/funds/:fundID/edit" exact>
            <EditFund smaller funds={funds} closePath={currentPath} reload={reload} />
          </Route>
          <Route path="/:portfolioSlug/funds/:fundID/remove" exact>
            <RemoveFund
              smaller
              funds={funds}
              reload={reload}
              successPath={currentPath}
              cancelPath={currentPath}
            />
          </Route>
        </>
      )}
    </AwaitResponse>
  )
}
