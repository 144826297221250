import React, { useContext } from 'react'
import { parseISO } from 'date-fns'
import Table from '../../Shared/Table'
import { FundContext } from '../../../Context/FundContext'

export default ({ commitments }) => {
  const columns = [
    {
      type: 'date',
      value: (row) => (parseISO(row.committed_on))
    },
    {
      title: 'Thesis',
      value: (row) => (row.thesis)
    },
    {
      title: 'Amount',
      type: 'monetary',
      value: (row) => (row.dollar_value)
    }
  ]

  const { fundPath } = useContext(FundContext)

  return (
    <Table
      columns={columns}
      rows={commitments}
      defaultSort={{ column: 0, direction: 'asc' }}
      emptyMessage="No commitments have been made."
      rowMenu={[
        {
          title: 'Edit…',
          select: (row) => (`${fundPath}/commitments/${row.id}/edit`)
        },
        {
          title: 'Remove…',
          select: (row) => (`${fundPath}/commitments/${row.id}/remove`)
        }
      ]}
    />
  )
}
