import React, { useContext } from 'react'
import { Title, useModal, Subtitle } from '../../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../../Shared/API'
import DistributionForm from './DistributionForm'
import { FundContext } from '../../../Context/FundContext'

export default function AddCashDistribution ({ returnPath }) {
  const { reloadFund, fundAPI } = useContext(FundContext)
  const { api } = useAPIGet(`${fundAPI}/distributions/new.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const onSubmit = async (distribution) => {
    const { error } = (await API.post(`${fundAPI}/distributions.json`, { distribution }))

    if (!error) {
      closeModal()
      reloadFund()
    }
  }

  return (
    <AwaitResponse api={api}>
      {(distribution) => (
        <Modal>
          <Title>Record Cash Distribution</Title>
          <Subtitle>
            What has been returned?
          </Subtitle>

          <DistributionForm
            distribution={distribution}
            onSubmit={onSubmit}
          />
        </Modal>
      )}
    </AwaitResponse>
  )
}
