import React from 'react'
import { Submit } from '../../../Shared/Modal'
import {
  CurrencyInput, Form,
  Row, DatePicker, TextArea
} from '../../../Shared/Form'
import { prepareCurrency, parseCurrency } from '../../../Shared/MonetaryValue'
import useQuery from '../../../Shared/useQuery'

export default function CommitmentForm ({ commitment, onSubmit }) {
  const isEditing = (commitment.id !== null)

  // Prepare currency values
  const { dollar_value, ...rest } = commitment
  const initialValues = {
    dollar_value: prepareCurrency(dollar_value),
    ...rest
  }

  const query = useQuery()
  if (!isEditing && query.get('funds')) {
    initialValues.fund_id = query.get('funds')
  }

  const onFormSubmit = ({ dollar_value, ...rest }) => {
    onSubmit({
      ...commitment,
      ...rest,
      dollar_value: parseCurrency(dollar_value)
    })
  }

  return (
    <Form onSubmit={onFormSubmit} initialValues={initialValues}>
      <Row>
        <CurrencyInput field="dollar_value" label={isEditing ? 'Dollar Amount' : 'How much?'} placeholder="$25,000.00" />
        <DatePicker field="committed_on" label="When?" />
      </Row>

      <TextArea field="thesis" label="Investment Thesis" />

      <Submit title={isEditing ? 'Save Changes' : 'Record Commitment'} />
    </Form>
  )
}
