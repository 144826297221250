import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { parseISO } from 'date-fns'
import {
  PrimaryTable, ActionRow, CellTitle, CellSubtitle
} from '../Shared/Table'
import { DeltaValue, Multiple } from '../../Shared/MonetaryValue'
import {
  HStack, VStack, Favicon, StatusColors, KindColors
} from '../Shared/UI'

// We must apply text-decoration to sub-elements so it inherits their color
const LiquidatedRow = styled(ActionRow)`
  td > * {
    text-decoration: line-through;
    opacity: 0.5;
  }
`

const AcquiredRow = styled(ActionRow)`
  td > * {
    opacity: 0.5;
  }
`

const statusSortOrders = {
  Acquired: 4,
  Operating: 3,
  Uncertain: 2,
  Liquidated: 1
}

export default function EntitiesTable ({ basePath, entities, emptyMessage }) {
  const columns = [
    {
      type: 'status',
      key: 'status',
      width: 7,
      value: (entity) => (statusSortOrders[entity.status]),
      color: (entity) => (KindColors[entity.kind] || StatusColors[entity.status]),
      secondarySort: (entity) => (entity.analysis.profit),
      tertiarySort: (entity) => (entity.display_name)
    },
    {
      type: 'date',
      value: (e) => (parseISO(e.first_invested_on || e.created_at))
    },
    {
      title: 'Company',
      type: 'stacked',
      value: (entity) => (entity.display_name.toLocaleLowerCase()),
      render: (entity) => (
        <HStack>
          {/* Style hacks prevent the icon from inheriting text-decoration: underline; */}
          <Favicon style={{ position: 'absolute' }} subject={entity} />
          <VStack style={{ marginLeft: '44px' }}>
            <CellTitle>{entity.display_name}</CellTitle>
            <CellSubtitle style={{ width: '250px' }}>{entity.description}</CellSubtitle>
          </VStack>
        </HStack>
      )
    },
    {
      title: 'Invested',
      type: 'monetary',
      value: (entity) => (entity.analysis.total_invested)
    },
    {
      title: 'Total Value',
      type: 'monetary',
      value: (entity) => (entity.analysis.current_value),
      secondarySort: (entity) => (entity.analysis.profit),
      tertiarySort: (entity) => (entity.display_name)
    },
    {
      title: 'Profit/Loss',
      type: 'monetary',
      value: (entity) => (entity.analysis.profit),
      render: (entity) => (<DeltaValue value={entity.analysis.profit} decimals={0} />)
    },
    {
      title: 'Multiple',
      type: 'monetary',
      value: (entity) => (entity.analysis.multiple),
      render: (entity) => (
        <Multiple
          value={entity.analysis.multiple}
          displayType="text"
          thousandSeparator
          decimalScale="2"
          fixedDecimalScale
          suffix="x"
        />
      )
    },
    {
      title: 'Returned',
      type: 'monetary',
      value: (entity) => (entity.analysis.total_returned),
      secondarySort: (entity) => (entity.analysis.profit),
      tertiarySort: (entity) => (entity.display_name)
    }
    // {
    //   title: 'Outstanding',
    //   type: 'monetary',
    //   value: (entity) => (entity.analysis.total_outstanding),
    //   render: (entity) => (<DeltaValue value={entity.analysis.total_outstanding} decimals={0} />),
    // },
  ]

  const history = useHistory()

  return (
    <PrimaryTable
      columns={columns}
      rows={entities}
      rowType={(entity) => {
        switch (entity.status) {
          case 'Liquidated':
            return LiquidatedRow
          case 'Acquired':
            return AcquiredRow
          default:
            return ActionRow
        }
      }}
      onRowClick={(event, entity) => {
        history.push(`${basePath}/entities/${entity.id}-${entity.slug}`)
      }}
      defaultSort={{ column: 5, direction: 'desc' }}
      emptyMessage={emptyMessage}
    />
  )
}
