import React, { useContext } from 'react'
import { Submit, Button } from '../../Shared/Modal'
import {
  Form, Input, Row
} from '../../Shared/Form'
import { UserContext } from '../../Context/UserContext'

export default function PortfolioForm ({
  portfolio, onSubmit, onCancel, children
}) {
  const isEditing = (portfolio && portfolio.id !== null && portfolio.id !== undefined)
  const { user } = useContext(UserContext)

  return (
    <Form onSubmit={onSubmit} initialValues={portfolio}>
      {children}

      <Input field="display_name" label="Portfolio Name" placeholder={`${user.first_name}'s Investments`} />

      <Row>
        {!isEditing && onCancel && <Button title="Cancel" onClick={onCancel} />}
        <Submit title={isEditing ? 'Save Changes' : 'Create Portfolio'} />
      </Row>
    </Form>
  )
}
