import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import {
  Title, Submit, Subtitle, useModal, Button
} from '../../Shared/Modal'
import API from '../../Shared/API'
import { Form, Row } from '../../Shared/Form'
import { UserContext } from '../../Context/UserContext'

export default function RemoveFund ({
  fund: providedFund, funds, reload, smaller, successPath, cancelPath
}) {
  const { fundID } = useParams()
  const { Modal, closeModal } = useModal(cancelPath)
  const { portfolioAPI } = useContext(UserContext)
  const fund = providedFund || funds.find((f) => f.id === Number.parseInt(fundID, 10)) || null

  const remove = async () => {
    await API.delete(`${portfolioAPI}/funds/${fundID}.json`)

    if (reload) {
      reload()
    }

    closeModal(successPath)
  }

  return fund && (
    <Modal smaller={smaller}>
      <Title>Remove Fund?</Title>
      <Subtitle>
        Are you sure you want to permanently delete
        {' '}
        <b>{fund.display_name}</b>
        ? This is not undoable.
      </Subtitle>

      <Form onSubmit={remove}>
        <Row>
          <Button title="Cancel" onClick={() => closeModal()} />
          <Submit title="Delete Fund" />
        </Row>
      </Form>
    </Modal>
  )
}
