import React from 'react'
import styled from 'styled-components'
import ReactDatePicker from 'react-datepicker'
import {
  Form as InForm,
  Text as InInput,
  TextArea as InTextArea,
  Select as InSelect,
  asField, useField,
  useFormState, useFieldState,
  useFieldApi
} from 'informed'
import { parseISO, format as formatDate } from 'date-fns'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import 'react-datepicker/dist/react-datepicker.css'

export const Field = styled.div`
  margin-bottom: 20px;
`

export const Row = styled.div`
  display: flex;

  ${Field} {
    margin-left: 10px;
    flex-basis: 100px;
    flex-grow: 1;

    &:first-child {
        margin-left: 0;
    }
  }
`

export const DatePicker = asField(({
  fieldState, fieldApi, label, field, ...props
}) => {
  const { value } = fieldState
  const { setValue, setTouched } = fieldApi
  const {
    onChange, onBlur, initialValue, forwardedRef, ...rest
  } = props
  const selected = (value && parseISO(value)) ||
    (initialValue && parseISO(initialValue)) ||
    new Date()

  return (
    <Field>
      {label && <label htmlFor={field}>{label}</label>}
      <ReactDatePicker
        name={field}
        selected={selected}
        onChange={(date) => {
          setValue(formatDate(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"))
          if (onChange) {
            onChange(date)
          }
        }}
        onBlur={(e) => {
          setTouched(true)
          if (onBlur) {
            onBlur(e)
          }
        }}
        ref={forwardedRef}
        {...rest}
      />
      {fieldState.error && <span>{fieldState.error}</span>}
    </Field>
  )
})

export const Submit = ({ className, title, onClick }) => {
  const formState = useFormState()
  return (
    <button
      className={className}
      disabled={formState.invalid}
      onClick={onClick}
      type="submit"
    >
      {title}
    </button>
  )
}

export const Button = ({ className, title, onClick }) => (
  <button className={className} onClick={onClick} type="button">{title}</button>
)

export const Form = InForm

export const Input = ({
  field, type, label, placeholder, id, className,
  onChange, validate, validateOnBlur, accept,
  onValueChange
}) => (
  <Field className={className}>
    {label && <label htmlFor={field}>{label}</label>}
    <InInput
      id={id}
      accept={accept}
      field={field}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      keepState
      allowEmptyString
      validate={validate}
      validateOnBlur={validateOnBlur}
      onValueChange={onValueChange}
    />
    <ErrorFor field={field} />
  </Field>
)

export const TextArea = ({
  field, type, label, placeholder, id, className,
  onChange, validate, validateOnBlur, accept,
  onValueChange, rows, autoFocus
}) => (
  <Field className={className}>
    {label && <label htmlFor={field}>{label}</label>}
    <InTextArea
      id={id}
      accept={accept}
      autoFocus={autoFocus}
      field={field}
      type={type}
      rows={rows}
      placeholder={placeholder}
      onChange={onChange}
      keepState
      allowEmptyString
      validate={validate}
      validateOnBlur={validateOnBlur}
      onValueChange={onValueChange}
    />
    <ErrorFor field={field} />
  </Field>
)

const ErrorFor = ({ field }) => {
  const fieldState = useFieldState(field)
  return fieldState.error
    ? (
    <small style={{ color: 'red' }}>{fieldState.error}</small>
      )
    : null
}

export const Select = ({
  field, label, options, onChange
}) => (
  <Field>
    <InSelect field={field} label={label} onChange={onChange}>
      {options.map((o) => (
        <option value={o.id} key={o.id} disabled={o.disabled === true}>
          {o.title}
        </option>
      ))}
    </InSelect>
  </Field>
)

export const StringSelect = ({ options, ...props }) => {
  const statusOptions = options.map((s) => ({ id: s, title: s }))

  return <Select options={statusOptions} {...props} />
}

export const FormMaskedInput = ({
  mask, parse, format, ...props
}) => {
  const {
    fieldState, fieldApi, field, render, ref, userProps
  } = useField({ ...props, parse, format })
  const { value } = fieldState
  const { setValue, setTouched } = fieldApi
  const {
    onChange, onBlur, label, disabled, ...rest
  } = userProps

  return render(
    <Field>
      {label && <label htmlFor={field} style={{ opacity: disabled ? '0.5' : '1.0' }}>{label}</label>}
      <MaskedInput
        mask={mask}
        disabled={disabled}
        {...rest}
        ref={ref}
        value={!value && value !== 0 ? '' : value}
        onChange={(e) => {
          setValue(e.target.value)
          if (onChange) {
            onChange(e)
          }
        }}
        onBlur={(e) => {
          setTouched(true)
          if (onBlur) {
            onBlur(e)
          }
        }}
        style={fieldState.error ? { border: 'solid 1px red' } : null}
      />
      {fieldState.error
        ? (
        <small style={{ color: 'red' }}>{fieldState.error}</small>
          )
        : null}
    </Field>
  )
}

export const validateRequiredCurrencyValue = (v) => (
  !v || Number.isNaN(parseFloat(v)) || parseFloat(v) === 0.0
    ? 'Invalid dollar value'
    : undefined
)

export const validateOptionalCurrencyValue = (v) => (
  v && (Number.isNaN(parseFloat(v)) || parseFloat(v) === 0.0)
    ? 'Invalid dollar value'
    : undefined
)

export const CurrencyInput = ({ decimalLimit, optional, ...props }) => (
  <FormMaskedInput
    {...props}
    parse={(value) => (value !== null ? `${value}`.replace(/[^\d.]/g, '') : value)}
    format={(value) => (value)}
    validate={optional ? validateOptionalCurrencyValue : validateRequiredCurrencyValue}
    validateOnBlur
    mask={createNumberMask({
      prefix: '$',
      suffix: '',
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ',',
      allowDecimal: true,
      requireDecimal: true,
      decimalSymbol: '.',
      decimalLimit: decimalLimit || 2, // how many digits allowed after the decimal
      allowNegative: false,
      allowLeadingZeroes: false
    })}
  />
)

export const PercentInput = (props) => (
  <FormMaskedInput
    {...props}
    parse={(value) => (value !== null ? `${value}`.replace(/[^\d.]/g, '') : value)}
    format={(value) => (value)}
    mask={createNumberMask({
      prefix: '',
      suffix: '%',
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ',',
      allowDecimal: true,
      requireDecimal: false,
      decimalSymbol: '.',
      decimalLimit: 2, // how many digits allowed after the decimal
      allowNegative: false,
      allowLeadingZeroes: false
    })}
  />
)

export const validateRequiredDecimalValue = (v) => (
  !v || Number.isNaN(parseFloat(v)) || parseFloat(v) === 0.0
    ? 'Invalid decimal value'
    : undefined
)

export const validateOptionalDecimalValue = (v) => (
  v && (Number.isNaN(parseFloat(v)) || parseFloat(v) === 0.0)
    ? 'Invalid decimal value'
    : undefined
)

export const DecimalInput = ({ decimalLimit, optional, ...props }) => (
  <FormMaskedInput
    {...props}
    parse={(value) => (value !== null ? `${value}`.replace(/[^\d.]/g, '') : value)}
    format={(value) => (value)}
    validate={optional ? validateOptionalDecimalValue : validateRequiredDecimalValue}
    validateOnBlur
    mask={createNumberMask({
      prefix: '',
      suffix: '',
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ',',
      allowDecimal: true,
      requireDecimal: true,
      decimalSymbol: '.',
      decimalLimit: decimalLimit || 6, // how many digits allowed after the decimal
      allowNegative: false,
      allowLeadingZeroes: false
    })}
  />
)

export const validateRequiredIntegerValue = (v) => (
  !v || parseInt(v, 10) === 0
    ? 'Invalid number'
    : undefined
)

export const validateOptionalIntegerValue = (v) => (
  v && parseInt(v, 10) === 0
    ? 'Invalid number'
    : undefined
)

export const IntegerInput = ({ optional, ...props }) => (
  <FormMaskedInput
    {...props}
    parse={(value) => (value !== null ? `${value}`.replace(/[^\d.]/g, '') : value)}
    format={(value) => (value)}
    validate={optional ? validateOptionalIntegerValue : validateRequiredIntegerValue}
    validateOnBlur
    mask={createNumberMask({
      prefix: '',
      suffix: '',
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ',',
      allowDecimal: false,
      allowNegative: false,
      allowLeadingZeroes: false
    })}
  />
)

export const DisplayNameInput = ({
  field, label, placeholder, destination, suffix
}) => {
  const fieldApi = useFieldApi(destination)
  const validateName = (v) => (!v || v.length < 2 ? 'Invalid name' : undefined)

  return (
    <Input
      field={field}
      label={label}
      placeholder={placeholder}
      onChange={(e) => fieldApi.setValue(`${e.target.value}, ${suffix || 'LLC'}`)}
      validate={validateName}
      validateOnBlur
    />
  )
}
