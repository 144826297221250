import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import {
  Menu, Title, Subtitle, Favicon
} from './UI'
import { UserContext } from '../../Context/UserContext'
import { FundContext } from '../../Context/FundContext'
import { addPasskey } from '../../Shared/Passkeys'

const MastheadContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Breadcrumbs = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  margin: 6px 0px;
  color: rgba(255,255,255,0.6);
  padding-right: 5px;
  cursor: pointer;

  :hover {
    background-color: rgba(255,255,255,0.15);
    color: white;
  }

  ${({ selected }) => selected && `
    background-color: rgba(255,255,255,0.15);
    color: white;
  `}
`

const Breadcrumb = styled.h1`
    font-size: 16px;
    font-weight: 500;
    margin: 0;
`

const Back = styled.div`
  font-family: "Arial";
  font-size: 20px;
  font-weight: 200;
  padding: 2px 10px 2px 5px;
`

const MenuContainer = styled.div``
const PullDownMenu = ({
  menu, displayMenu, onOpen, onClose, onSelect, children
}) => (
  <MenuContainer onClick={onOpen}>
    {children}
    {displayMenu && <Menu menu={menu} onClose={onClose} onSelect={onSelect} />}
  </MenuContainer>
)

const AddMenuItem = styled.span`
  opacity: 0.8;

  :before {
    content: "\uFF0B";
    padding-right: 2px;
  }
`

function ShallowPortfolio () {
  const { portfolios, portfolio: { display_name } } = useContext(UserContext)

  const [showHamburgerMenu, setShowHamburgerMenu] = useState()
  const history = useHistory()

  const hamburgerMenu = portfolios.sort((a, b) => (a.display_name.localeCompare(b.display_name))).map((p) => ({
    title: (
      <>
        <Title>{p.display_name}</Title>
        <Subtitle>{p.legal_name}</Subtitle>
      </>
    ),
    select: () => (`/${p.slug}`)
  })).concat({
    title: (
      <AddMenuItem>Set up another portfolio</AddMenuItem>
    ),
    select: () => ('/portfolios/new')
  })

  return (
    <PullDownMenu
      menu={hamburgerMenu}
      displayMenu={showHamburgerMenu}
      onOpen={() => { setShowHamburgerMenu(true) }}
      onClose={() => { setShowHamburgerMenu(false) }}
      onSelect={(item) => { history.push(item.select()) }}
    >
      <Breadcrumbs selected={showHamburgerMenu}>
        <Favicon
          subject={{ display_name }}
          size={25}
          style={{
            margin: '3px 7px 3px 3px',
            borderRadius: '100vh'
          }}
        />
        <Breadcrumb>{display_name}</Breadcrumb>
      </Breadcrumbs>
    </PullDownMenu>
  )
}

function DeepPortfolio () {
  const { portfolio, portfolioPath } = useContext(UserContext)
  const history = useHistory()
  const goBack = () => {
    history.push(portfolioPath)
  }

  return (
    <Breadcrumbs onClick={goBack}>
      <Back>&#8602;</Back>
      <Breadcrumb>{portfolio.display_name}</Breadcrumb>
    </Breadcrumbs>
  )
}

function DeepFund () {
  const { fund, fundPath } = useContext(FundContext)
  const history = useHistory()
  const goBack = () => {
    history.push(fundPath)
  }

  return (
    <Breadcrumbs onClick={goBack}>
      <Back>&#8602;</Back>
      <Breadcrumb>{fund.display_name}</Breadcrumb>
    </Breadcrumbs>
  )
}

function User () {
  const { user, setToken } = useContext(UserContext)

  const [showHamburgerMenu, setShowHamburgerMenu] = useState()
  const history = useHistory()

  const selectAddPasskey = async () => {
    const token = await addPasskey()
    setToken(token)
    return '/'
  }

  const hamburgerMenu = [
    {
      title: (
        <AddMenuItem>Add Passkey</AddMenuItem>
      ),
      select: selectAddPasskey
    },
    {
      title: 'Log Out',
      select: () => ('/logout')
    }
  ]

  return (
    <PullDownMenu
      menu={hamburgerMenu}
      displayMenu={showHamburgerMenu}
      onOpen={() => { setShowHamburgerMenu(true) }}
      onClose={() => { setShowHamburgerMenu(false) }}
      onSelect={(item) => { history.push(item.select()) }}
    >
      <Breadcrumbs selected={showHamburgerMenu}>
        <Favicon
          subject={{ display_name: user.first_name }}
          size={25}
          style={{
            margin: '3px 7px 3px 3px',
            borderRadius: '100vh'
          }}
        />
        <Breadcrumb>{`${user.first_name} ${user.last_name}`}</Breadcrumb>
      </Breadcrumbs>
    </PullDownMenu>
  )
}

function Navigation ({ toplevel, deep }) {
  const { fundPath } = useContext(FundContext)

  if (toplevel) {
    return <ShallowPortfolio />
  }

  if (deep && fundPath) {
    return <DeepFund />
  }

  return <DeepPortfolio />
}

function Masthead ({ toplevel, deep }) {
  return (
    <MastheadContainer>
      <Navigation toplevel={toplevel} deep={deep} />
      <User />
    </MastheadContainer>
  )
}

export default Masthead
