import React, { useContext } from 'react'
import styled from 'styled-components'
import { Route } from 'react-router-dom'
import {
  Title, useModal, Subtitle, Section, Submit
} from '../../Shared/Modal'
import { useAPIGet, AwaitResponse } from '../../Shared/API'
import { UserContext } from '../../Context/UserContext'
import MembershipsTable from './Memberships/MembershipsTable'
import { AddLink } from '../Shared/UI'
import Invite from './Memberships/Invite'
import RemoveMembership from './Memberships/RemoveMembership'

const AddPerson = styled(AddLink)`
  text-decoration: none;
  color: #395e7c;
  font-weight: 500;
  display: block;
  margin: 10px 0 15px 110px;
`

export default function ManageAccess () {
  const {
    portfolioPath,
    portfolioAPI,
    portfolio
  } = useContext(UserContext)
  const { Modal, closeModal } = useModal(portfolioPath)
  const { api, setData } = useAPIGet(`${portfolioAPI}/memberships.json`, [])
  const currentPath = `${portfolioPath}/access`

  return (
    <AwaitResponse api={api}>
      {(memberships) => (
        <>
          <Modal>
            <Title>Manage Access</Title>
            <Subtitle>
              The following people have access to
              {' '}
              {portfolio.display_name}
              :
            </Subtitle>

            <Section>
              <MembershipsTable memberships={memberships} basePath={currentPath} footer />
              <AddPerson to={`${portfolioPath}/access/invite`}>Invite</AddPerson>
            </Section>

            <Submit onClick={() => closeModal()} title="Done" />
          </Modal>

          <Route path="/:portfolioSlug/access/invite" exact>
            <Invite smaller closePath={currentPath} />
          </Route>
          <Route path="/:portfolioSlug/access/:membershipID/remove" exact>
            <RemoveMembership
              smaller
              memberships={memberships}
              setMemberships={setData}
              basePath={currentPath}
            />
          </Route>
        </>
      )}
    </AwaitResponse>
  )
}
