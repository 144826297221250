import React, { useContext } from 'react'
import {
  Title, Submit, Subtitle, useModal
} from '../../Shared/Modal'
import API from '../../Shared/API'
import { EntityContext } from '../../Context/EntityContext'
import { Form, Input } from '../../Shared/Form'
import { UserContext } from '../../Context/UserContext'

export default function DeleteEntity () {
  const { entityPath, entity, entityAPI } = useContext(EntityContext)
  const { portfolioPath } = useContext(UserContext)
  const { Modal, closeModal } = useModal(entityPath)

  const deleteEntity = async () => {
    await API.delete(`${entityAPI}.json`)
    closeModal(portfolioPath)
  }

  const validateConfirm = (v) => (v === entity.display_name.toUpperCase() ? undefined : 'Invalid confirmation')

  return (
    <Modal>
      <Title>Delete Entity?</Title>
      <Subtitle>
        Are you sure you want to delete
        {' '}
        <b>{entity.display_name}</b>
        {' '}
        and all associated data?
      </Subtitle>
      <Subtitle>
        <b>Warning: </b>
        This cannot be undone.
      </Subtitle>

      <Form onSubmit={deleteEntity}>
        <Input
          field="confirm"
          placeholder={`Enter "${entity.display_name.toUpperCase()}" to continue`}
          validateOnBlur
          validate={validateConfirm}
        />
        <Submit title="Delete Entity" />
      </Form>
    </Modal>
  )
}
