import React, { useState, useContext } from 'react'
import {
  Switch, Redirect, Route, useHistory
} from 'react-router-dom'
import ShowCompanies from './ShowCompanies'
import {
  Right, Container, Header,
  Title, Subject, Subtitle,
  AddNavLink, HeaderHamburger
} from '../Shared/UI'
import Masthead from '../Shared/Masthead'
import EditPortfolio from './EditPortfolio'
import ManageAccess from './ManageAccess'
import Import from './Import'
import DeletePortfolio from './DeletePortfolio'
import SelectEntity from '../Entities/SelectEntity'
import NewProspect from '../Prospects/NewProspect'
import { UserContext } from '../../Context/UserContext'
import ManageFunds from '../Funds/ManageFunds'
import SelectCommitment from '../Funds/SelectCommitment'

export default function Routes () {
  const { portfolio, portfolioPath } = useContext(UserContext)
  const [showHamburgerMenu, setShowHamburgerMenu] = useState()
  const history = useHistory()

  const hamburgerMenu = [
    {
      title: 'Add Investment…',
      select: () => (`${portfolioPath}/new/investment`)
    },
    {
      title: 'Add LP Commitment…',
      select: () => (`${portfolioPath}/new/commitment`)
    },
    {
      title: 'Import…',
      select: () => (`${portfolioPath}/import`)
    },
    {
      title: 'Edit Portfolio…',
      select: () => (`${portfolioPath}/edit`)
    },
    {
      title: 'Manage Funds…',
      select: () => (`${portfolioPath}/funds`)
    },
    {
      title: 'Manage Access…',
      select: () => (`${portfolioPath}/access`)
    },
    {
      title: 'Delete Portfolio…',
      select: () => (`${portfolioPath}/remove`)
    }
  ]

  return (
    <>
      <Container>
        <Masthead toplevel />
        <Header>
          <Subject>
            <div>
              <Title>Portfolio</Title>
              <Subtitle>{portfolio.display_name}</Subtitle>
            </div>
            <Right>
              <AddNavLink to={{ pathname: `${portfolioPath}/new/prospect`, search: history.location.search }}>Add Lead</AddNavLink>
              <HeaderHamburger
                menu={hamburgerMenu}
                displayMenu={showHamburgerMenu}
                onOpen={() => { setShowHamburgerMenu(true) }}
                onClose={() => { setShowHamburgerMenu(false) }}
                onSelect={(item) => { history.push({ pathname: item.select(), search: history.location.search }) }}
                hOffset={-150}
              />
            </Right>
          </Subject>
        </Header>
        <Switch>
          <Route path="/:portfolioSlug/:status?">
            <ShowCompanies />
          </Route>

          <Redirect to={`${portfolioPath}`} />
        </Switch>
      </Container>

      {/* Portfolio Modals */}
      <Route path="/:portfolioSlug/new/prospect" exact>
        <NewProspect />
      </Route>
      <Route path="/:portfolioSlug/new/investment" exact>
        <SelectEntity />
      </Route>
      <Route path="/:portfolioSlug/new/commitment" exact>
        <SelectCommitment />
      </Route>
      <Route path="/:portfolioSlug/edit" exact>
        <EditPortfolio />
      </Route>
      <Route path="/:portfolioSlug/remove" exact>
        <DeletePortfolio />
      </Route>
      <Route path="/:portfolioSlug/funds">
        <ManageFunds />
      </Route>
      <Route path="/:portfolioSlug/access">
        <ManageAccess />
      </Route>
      <Route path="/:portfolioSlug/import/:importType?">
        <Import />
      </Route>
    </>
  )
}
