import React, { useContext } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import {
  Title, Subtitle, useModal, Submit
} from '../../../Shared/Modal'
import SelectFiles from './SelectFiles'
import InvestmentFields from './InvestmentFields'
import HoldingFields from './HoldingFields'
import DistributionFields from './DistributionFields'
import SplitFields from './SplitFields'
import { UserContext } from '../../../Context/UserContext'

const ImportOptionContainer = styled.div`
  background-color: white;
  border-radius: 2px;
  border: 1px solid #ccc;
  padding: 10px 10px 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;

  :hover {
    background-color: #e9f3fb;
  }

  &:last-child {
    margin-bottom: 10px;
  }
`

const OptionSubject = styled.div`
  display: flex;
  flex-direction: column;
`

const OptionTitle = styled.b`
  color: #395e7c;
`
const OptionDescription = styled.small`
  opacity: 0.6;
`

const OptionArrow = styled.div`
  color: #ccc;
  font-size: 22px;
  transform:scale(0.7,2);
  padding-top 2px;
`

const ImportOption = ({ children, type, title }) => {
  const history = useHistory()

  const selectOption = () => {
    history.push(`${history.location.pathname}/${type}`)
  }

  const capitalized = type.replace(/^\w/, (c) => c.toUpperCase())

  return (
    <ImportOptionContainer onClick={selectOption}>
      <OptionSubject>
        <OptionTitle>{title || capitalized}</OptionTitle>
        {children}
      </OptionSubject>
      <OptionArrow>
    &#65125;
      </OptionArrow>
    </ImportOptionContainer>
  )
}

export default function Import () {
  const { importType } = useParams()
  const { portfolioPath } = useContext(UserContext)
  const { Modal, closeModal } = useModal(portfolioPath)

  switch (importType) {
    case 'investments':
      return <SelectFiles title="Investments" fields={InvestmentFields} />
    case 'holdings':
      return <SelectFiles title="Holdings" fields={HoldingFields} />
    case 'entities':
      return <SelectFiles title="Entity Details" fields={[]} hideReplace />
    case 'valuations':
      return <SelectFiles title="Valuations" fields={[]} />
    case 'splits':
      return <SelectFiles title="Stock Splits" fields={SplitFields} />
    case 'distributions':
      return <SelectFiles title="Returns" fields={DistributionFields} />
    default:
      break
  }

  return (
    <Modal>
      <Title>Import from CSV</Title>
      <Subtitle>
        Cashlytics makes it easy to import all of your
        existing data from well-formed CSV files.
        We&apos;ll walk you through it.
      </Subtitle>
      <Subtitle>
        What would you like to import?
      </Subtitle>

      <div>
        <ImportOption type="investments">
          <OptionDescription>Company names, dates, and amounts of your investments…</OptionDescription>
        </ImportOption>

        <ImportOption type="holdings">
          <OptionDescription>Current ownership resulting from your investments…</OptionDescription>
        </ImportOption>

        <ImportOption type="valuations">
          <OptionDescription>Current share prices and valuation changes…</OptionDescription>
        </ImportOption>

        <ImportOption type="splits" title="Stock Splits">
          <OptionDescription>Stock splits that impact current share price…</OptionDescription>
        </ImportOption>

        <ImportOption type="distributions" title="Returns">
          <OptionDescription>Distributions you have received as a result of liquidity events…</OptionDescription>
        </ImportOption>

        <ImportOption type="entities" title="Entity Details">
          <OptionDescription>Legal name and operating status…</OptionDescription>
        </ImportOption>
      </div>

      <Submit title="Done" onClick={() => closeModal()} />
    </Modal>
  )
}
