import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import {
  Title, Subtitle, useModal, Button
} from '../../../Shared/Modal'
import { UserContext } from '../../../Context/UserContext'
import API from '../../../Shared/API'
import { Row } from '../../../Shared/Form'

const FieldList = styled.div`
  display: flex;
  overflow-x: scroll;
  border: 1px solid #ccc;
  border-radius: 2px;

  font-size: smaller;
`

const Field = styled.details`
  padding: 10px;
  background-color: white;
  border-right: 1px solid #ccc;
  min-width: 125px;
  max-width: 250px;
  flex-shrink: 0;
  flex-grow: 1;
  font-weight: 600;

  &:last-child {
    border-right: none;
  }

  display: flex;
  flex-direction: column;

  p {
    font-weight: 200;
    opacity: 0.6;
  }

  code {
    padding: 3px 5px;
    background-color: white;
    border-radius: 2px;
    border: 0.5px solid #ddd;
    margin-right: 7px;
    margin-bottom: 5px;
    white-space: nowrap;
    display: inline-block;
  }
`

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const SubmitLabel = styled.label`
  font-size: 1.2em;
  font-weight: 400;
  padding: 10px 15px;
  border-radius: 3px;
  margin-top: 15px;
  margin-bottom: 0 !important;
  background-color: #395e7c;
  color: white;
  border: none;
  cursor: pointer;
  transition: all .2s ease-in-out;
  width: 100%;
  box-sizing: border-box;
  text-align: center;

  :hover {
      font-weight: 500;
  }

  &:disabled {
      background-color: #444;

      :hover {
          font-weight: 400;
      }
  }
`

const Checkmark = styled.label`
  /* Customize the label (the container) */
  display: block;
  position: relative;
  padding-left: 30px;
  margin: 12px auto;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 2px solid #395e7c;
    border-radius: 2px;
  }

  /* On mouse-over, add a grey background color */
  :hover input ~ span {
    background-color: #fff;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ span {
    background-color: #395e7c;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  span:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ span:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  span:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

export default function SelectFiles ({ title, fields, hideReplace }) {
  const { importType } = useParams()
  const {
    portfolioPath, portfolioAPI, setPortfolioTouched
  } = useContext(UserContext)
  const { Modal, closeModal } = useModal(portfolioPath)
  const [replaceAll, setReplaceAll] = useState(false)

  const onSelectFile = async (e) => {
    const currentForm = e.target.form
    const files = Array.from(e.target.files)
    const formData = new FormData()

    files.forEach((file) => {
      formData.append('csv[]', file)
    })

    formData.append('replace', replaceAll)

    try {
      await API.put(`${portfolioAPI}/import/${importType}.json`, formData)
      setPortfolioTouched()
      closeModal(`${portfolioPath}/import`)
    } catch (error) {
      // Try again?
      currentForm.reset()
    }
  }

  const onToggleCheckBox = (e) => {
    setReplaceAll(e.target.checked)
  }

  return (
    <Modal>
      <Title>
        Import
        {' '}
        {title}
      </Title>
      <Subtitle>
        You can import
        {' '}
        {importType}
        {' '}
        from CSV files with the following column headers:
      </Subtitle>
      <FieldList>
        {
          fields.map((f) => (
            <Field key={f.name}>
              <summary>{f.name}</summary>
              <p>{f.description}</p>
              <div>{f.examples.map((e) => <code key={e}>{e}</code>)}</div>
            </Field>
          ))
        }
      </FieldList>

      <form>
        <FileInput
          field="file"
          type="file"
          id="file-csv"
          title="Select File"
          accept=".csv"
          onChange={onSelectFile}
          multiple
        />

        {!hideReplace && (
          <Checkmark htmlFor="checkbox">
            Replace all existing
            {' '}
            {importType}
            ? (Careful!)
            <input
              id="checkbox"
              type="checkbox"
              checked={replaceAll}
              onChange={onToggleCheckBox}
            />
            <span />
          </Checkmark>
        )}

        <Row>
          <Button title="Back" onClick={() => closeModal(`${portfolioPath}/import`)} />
          <SubmitLabel htmlFor="file-csv">Select CSV File</SubmitLabel>
        </Row>
      </form>

    </Modal>
  )
}
