import React from 'react'
import {
  Frame, HStack, Subtitle, Tagline, SignInWithGoogle
} from './Shared'
import { PasskeyLoginButton } from '../Shared/Passkeys'
import styled from 'styled-components'

const Buttons = styled(HStack)`
  justify-content: flex-start;
`

export default function Welcome () {
  return (
    <Frame>
      <Subtitle>
        The world&apos;s most powerful,
        <br />
        yet lightest weight,
        <br />
        <b>angel investment tracker.</b>
      </Subtitle>
      <Tagline>
        Manage deal-flow,
        track performance,
        and stay informed.
        <br />
        <i>No spreadsheets required.</i>
      </Tagline>

      <Tagline>
        <i>Also, it&apos;s free. Just sign in with Google to get started&hellip;</i>
      </Tagline>

      <Buttons>
        <SignInWithGoogle />
        <PasskeyLoginButton />
      </Buttons>
    </Frame>
  )
}
