import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import {
  Title, Subtitle, useModal
} from '../../Shared/Modal'
import { DollarValue } from '../../Shared/MonetaryValue'
import API, { useAPIGet, AwaitResponse } from '../../Shared/API'
import { EntityContext } from '../../Context/EntityContext'
import HoldingForm from './HoldingForm'

export default function NewHolding ({ returnPath }) {
  const { investmentID } = useParams()
  const { reloadEntity, entityAPI, financesPath } = useContext(EntityContext)
  const { api } = useAPIGet(`${entityAPI}/holdings/new.json?investment_id=${investmentID || ''}`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const addHolding = async (holding) => {
    const { error, data: result } = await API.post(`${entityAPI}/holdings.json`, { holding })

    if (!error) {
      // If we're in a new investment flow, keep it going
      if (investmentID) {
        closeModal(`${financesPath}/valuations/new/${result.id}`)
      } else {
        closeModal()
      }

      reloadEntity()
    }
  }

  return (
    <AwaitResponse api={api}>
      {({
        holding, holding_type_options, certificate_options, fund_options, investment
      }) => (
          <Modal>
            <Title>Record Holdings</Title>
            <Subtitle>
              What have you received in exchange for your
            {' '}
              {investment && <b><DollarValue value={investment.dollar_value} /></b>}
              {' '}
              investment?
          </Subtitle>

            <HoldingForm
              onSubmit={addHolding}
              onCancel={() => closeModal()}
              holding={holding}
              holding_type_options={holding_type_options}
              certificate_options={certificate_options}
              fund_options={fund_options}
              expectedValue={investment ? investment.dollar_value : null}
              showNothingYet={investment}
            />
          </Modal>
      )}
    </AwaitResponse>
  )
}
