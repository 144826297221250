import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Title, Subtitle, useModal } from '../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../Shared/API'
import { Select } from '../../Shared/Form'
import FundForm from './FundForm'
import { UserContext } from '../../Context/UserContext'

export default function SelectCommitment () {
  const { portfolioPath, portfolioAPI } = useContext(UserContext)
  const { api } = useAPIGet(`${portfolioAPI}/funds/new.json?relationship=LP`, [])
  const history = useHistory()
  const { Modal, closeModal } = useModal({ pathname: portfolioPath, search: history.location.search })

  const selectFund = (fundID) => {
    closeModal({ pathname: `${portfolioPath}/fund/${fundID}/commitments/new`, search: history.location.search })
  }

  const selectOptions = (commitments) => ([
    { id: -1, title: 'Invest in a new fund' },
    { id: -2, title: '', disabled: true }
  ].concat(commitments.map((e) => ({ id: e.id, title: e.display_name }))))

  return (
    <AwaitResponse api={api}>
      {({ commitment: newCommitment, commitments }) => {
        const onSubmit = async (data) => {
          const commitment = (await API.post(`${portfolioAPI}/funds.json`, { fund: data })).data
          selectFund(commitment.id)
        }

        const onSelectChange = (event) => {
          selectFund(event.target.value)
        }

        const initialFormData = { ...newCommitment, fund_id: -1 }

        if (commitments.length === 0) {
          return (
            <Modal>
              <Title>Record Your First LP Commitment</Title>
              <Subtitle>Cashlytics makes it easy and intuitive to track your LP commitments. Let&apos;s get started!</Subtitle>
              <Subtitle>First, what fund are you investing in?</Subtitle>

              <FundForm
                onSubmit={onSubmit}
                fund={initialFormData}
                onImport={() => closeModal(`${portfolioPath}/import`)}
              />
            </Modal>
          )
        }

        return (
          <Modal>
            <Title>Record LP Commitment</Title>
            <Subtitle>What fund are you investing in?</Subtitle>

            <FundForm onSubmit={onSubmit} fund={initialFormData}>
              <Select field="entity_id" options={selectOptions(commitments)} onChange={onSelectChange} />
            </FundForm>
          </Modal>
        )
      }}
    </AwaitResponse>
  )
}
