import React, { useContext } from 'react'
import { parseISO } from 'date-fns'
import Table from '../../Shared/Table'
import { FundContext } from '../../../Context/FundContext'

export default ({ calls }) => {
  const columns = [
    {
      type: 'date',
      value: (row) => (parseISO(row.stated_on))
    },
    {
      title: 'Notes',
      value: (row) => (row.notes)
    },
    {
      title: 'Capital Balance',
      type: 'monetary',
      value: (row) => (row.capital_balance)
    }
  ]

  const { fundPath } = useContext(FundContext)

  return (
    <Table
      columns={columns}
      rows={calls}
      defaultSort={{ column: 0, direction: 'asc' }}
      emptyMessage="No statements have been entered."
      rowMenu={[
        {
          title: 'Edit…',
          select: (row) => (`${fundPath}/statements/${row.id}/edit`)
        },
        {
          title: 'Remove…',
          select: (row) => (`${fundPath}/statements/${row.id}/remove`)
        }
      ]}
    />
  )
}
