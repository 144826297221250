import React, { useContext } from 'react'
import { Title, useModal } from '../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../Shared/API'
import { EntityContext } from '../../Context/EntityContext'
import NoteForm from './NoteForm'

export default function AddNote () {
  const {
    reloadEntity, entityPath, entityAPI, entity
  } = useContext(EntityContext)
  const { api } = useAPIGet(`${entityAPI}/notes/new.json`, {})
  const { Modal, closeModal } = useModal(entityPath)

  const onSubmit = async (note) => {
    const { error } = (await API.post(`${entityAPI}/notes.json`, { note }))

    if (!error) {
      closeModal()
      reloadEntity()
    }
  }

  return (
    <AwaitResponse api={api}>
      {(note) => (
        <Modal disableClose>
          <Title>
            Enter Notes on
            {' '}
            {entity.display_name}
          </Title>

          <NoteForm note={note} onSubmit={onSubmit} onCancel={() => closeModal()} />
        </Modal>
      )}
    </AwaitResponse>
  )
}
