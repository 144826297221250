import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Title, Subtitle, useModal } from '../../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../../Shared/API'
import { Select } from '../../../Shared/Form'
import EntityForm from '../../Entities/EntityForm'
import { UserContext } from '../../../Context/UserContext'
import { FundContext } from '../../../Context/FundContext'

export default function AddStockDistribution () {
  const { portfolioAPI } = useContext(UserContext)
  const { fundPath } = useContext(FundContext)
  const { api } = useAPIGet(`${portfolioAPI}/entities/new.json`, [])
  const history = useHistory()
  const { Modal, closeModal } = useModal({ pathname: fundPath, search: history.location.search })
  const basePath = `${fundPath}/entities`

  const selectEntity = (entityPath) => {
    closeModal(`${basePath}/${entityPath}/finances/holdings/new`)
  }

  const selectOptions = (entities) => ([
    { id: -1, title: 'Create a new entity' },
    { id: -2, title: '', disabled: true }
  ].concat(entities.map((e) => ({ id: `${e.id}-${e.slug}`, title: e.display_name }))))

  return (
    <AwaitResponse api={api}>
      {({
        entity: newEntity, entities, status_options: statusOptions, kind_options: kindOptions
      }) => {
        const onSubmit = async (data) => {
          const entity = (await API.post(`${portfolioAPI}/entities.json`, { entity: data })).data
          selectEntity(`${entity.id}-${entity.slug}`)
        }

        const onSelectChange = (event) => {
          selectEntity(event.target.value)
        }

        const initialFormData = { ...newEntity, entity_id: -1 }

        if (entities.length === 0) {
          return (
            <Modal>
              <Title>Record Stock Distribution</Title>
              <Subtitle>Cashlytics makes it easy and intuitive to track stock distributions.</Subtitle>
              <Subtitle>First, what company&apos;s or entity&apos;s stock have you received?</Subtitle>

              <EntityForm
                onSubmit={onSubmit}
                entity={initialFormData}
                status_options={statusOptions}
                kind_options={kindOptions}
              />
            </Modal>
          )
        }

        return (
          <Modal>
            <Title>Record Stock Distribution</Title>
            <Subtitle>What entity&apos;s stock have you received?</Subtitle>

            <EntityForm
              onSubmit={onSubmit}
              entity={initialFormData}
              status_options={statusOptions}
              kind_options={kindOptions}
            >
              <Select field="entity_id" options={selectOptions(entities)} onChange={onSelectChange} />
            </EntityForm>
          </Modal>
        )
      }}
    </AwaitResponse>
  )
}
