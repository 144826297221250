import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { format as formatDate, parseISO } from 'date-fns'
import {
  Title, Submit, Subtitle, useModal
} from '../../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../../Shared/API'
import { EntityContext } from '../../../Context/EntityContext'
import { Form } from '../../../Shared/Form'
import { DollarValue } from '../../../Shared/MonetaryValue'

export default function RemoveDistribution ({ returnPath }) {
  const { distributionID } = useParams()
  const { reloadEntity, entityAPI } = useContext(EntityContext)
  const { api } = useAPIGet(`${entityAPI}/distributions/${distributionID}.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const removeDistribution = async () => {
    const { error } = (await API.delete(`${entityAPI}/distributions/${distributionID}.json`))

    if (!error) {
      closeModal()
      reloadEntity()
    }
  }

  return (
    <AwaitResponse api={api}>
      {(distribution) => (
        <Modal>
          <Title>Remove Distribution?</Title>
          <Subtitle>
            Are you sure you want to remove the
            {' '}
            <b><DollarValue value={distribution.dollar_value} /></b>
            {' '}
            distribution from
            <b>{` ${formatDate(parseISO(distribution.received_on), 'MMMM d, yyy')}`}</b>
            ? This cannot be undone.
          </Subtitle>

          <Form onSubmit={removeDistribution}>
            <Submit title="Remove Distribution" />
          </Form>
        </Modal>
      )}
    </AwaitResponse>
  )
}
