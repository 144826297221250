import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { format as formatDate, parseISO } from 'date-fns'
import {
  Title, Submit, Subtitle, useModal
} from '../../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../../Shared/API'
import { FundContext } from '../../../Context/FundContext'
import { DollarValue } from '../../../Shared/MonetaryValue'
import { Form } from '../../../Shared/Form'

export default function RemoveStatement ({ returnPath }) {
  const { statementID } = useParams()
  const { reloadFund, fundAPI } = useContext(FundContext)
  const { api } = useAPIGet(`${fundAPI}/statements/${statementID}.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const removeStatement = async () => {
    const { error } = (await API.delete(`${fundAPI}/statements/${statementID}.json`))

    if (!error) {
      closeModal()
      reloadFund()
    }
  }

  return (
    <AwaitResponse api={api}>
      {(statement) => (
        <Modal>
          <Title>Remove Statement?</Title>
          <Subtitle>
            Are you sure you want to remove the
            {' '}
            <b><DollarValue value={statement.capital_balance} /></b>
            {' '}
            statement from
            <b>{` ${formatDate(parseISO(statement.stated_on), 'MMMM d, yyy')}`}</b>
            ? This cannot be undone.
          </Subtitle>

          <Form onSubmit={removeStatement}>
            <Submit title="Remove Statement" />
          </Form>
        </Modal>
      )}
    </AwaitResponse>
  )
}
