import React, { useContext } from 'react'
import { Title, useModal } from '../../Shared/Modal'
import API from '../../Shared/API'
import PortfolioForm from './PortfolioForm'
import { UserContext } from '../../Context/UserContext'

export default function EditPortfolio () {
  const {
    portfolioPath,
    portfolioAPI,
    portfolio,
    setToken
  } = useContext(UserContext)
  const { Modal, closeModal } = useModal(portfolioPath)

  const editPortfolio = async (newPortfolio) => {
    const { data: { token, portfolio: updatedPortfolio } } = (await API.put(portfolioAPI, { portfolio: newPortfolio }))
    setToken(token)

    // Manually redirect to the new slug
    closeModal(`/${updatedPortfolio.slug}`)
  }

  return (
    <Modal>
      <Title>Edit Portfolio</Title>

      <PortfolioForm
        portfolio={portfolio}
        onSubmit={editPortfolio}
      />
    </Modal>
  )
}
