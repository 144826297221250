import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import {
  Title, useModal, Subtitle, Submit, Button
} from '../../Shared/Modal'
import API, { useAPIGet, AwaitResponse } from '../../Shared/API'
import { UserContext } from '../../Context/UserContext'
import { Row } from '../../Shared/Form'

function MissingInvite () {
  const { Modal, closeModal } = useModal('/')

  return (
    <Modal>
      <Title>
        Invitation Expired
      </Title>
      <Subtitle>
        Sorry, this invitation is no longer available.
      </Subtitle>
      <Subtitle>
        It may have expired, already been used, or does not match your
        account&apos;s email address.
      </Subtitle>

      <Submit title="OK" onClick={() => closeModal()} />
    </Modal>
  )
}

export default function AcceptInvite () {
  const { setToken } = useContext(UserContext)
  const { Modal, closeModal } = useModal()
  const { code } = useParams()
  const { api } = useAPIGet(`/invites/${code}.json`, [])

  const onAccept = async () => {
    try {
      const { data: { token } } = (await API.put(`/invites/${code}/accept.json`))
      setToken(token)
      closeModal('/')
    } catch (e) {
      // Try again?
    }
  }

  return (
    <AwaitResponse api={api} error={MissingInvite}>
      {(invite) => (
        <Modal>
          <Title>
            Join
            {' '}
            {invite.portfolio_name}
            ?
          </Title>
          <Subtitle>
            <b>{invite.inviter_name}</b>
            {' '}
            has invited you to join
            {' '}
            <b>{invite.portfolio_name}</b>
            {' '}
            on Cashlytics.
          </Subtitle>

          <Row>
            <Button title="Decline Invitation" onClick={() => closeModal('/')} />
            <Submit title="Join" onClick={onAccept} />
          </Row>
        </Modal>
      )}
    </AwaitResponse>
  )
}
