import React from 'react'
import { Submit } from '../../../Shared/Modal'
import {
  CurrencyInput, Form,
  Row, DatePicker, TextArea
} from '../../../Shared/Form'
import { prepareCurrency, parseCurrency } from '../../../Shared/MonetaryValue'
import useQuery from '../../../Shared/useQuery'

export default function StatementForm ({ statement, onSubmit }) {
  const isEditing = (statement.id !== null)

  // Prepare currency values
  const { capital_balance, ...rest } = statement
  const initialValues = {
    capital_balance: prepareCurrency(capital_balance),
    ...rest
  }

  const query = useQuery()
  if (!isEditing && query.get('funds')) {
    initialValues.fund_id = query.get('funds')
  }

  const onFormSubmit = ({ capital_balance, ...rest }) => {
    onSubmit({
      ...statement,
      ...rest,
      capital_balance: parseCurrency(capital_balance)
    })
  }

  return (
    <Form onSubmit={onFormSubmit} initialValues={initialValues}>
      <Row>
        <CurrencyInput field="capital_balance" label="Capital Balance" placeholder="$100,000.00" />
        <DatePicker field="stated_on" label="When?" />
      </Row>

      <TextArea field="notes" label="Notes" />

      <Submit title={isEditing ? 'Save Changes' : 'Enter Statement'} />
    </Form>
  )
}
