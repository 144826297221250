export default [
  {
    name: 'Company Name',
    description: 'The display name (not legal name) of the entity which experienced a stock split, e.g.',
    examples: ['Acme']
  },
  {
    name: 'Split On',
    description: 'When the split went into effect, e.g.',
    examples: ['1/1/2020']
  },
  {
    name: 'Numerator',
    description: 'The number of shares newly issued to each share holder. For example, in a 2-for-1 forward split, this would be',
    examples: ['2']
  },
  {
    name: 'Denominator',
    description: 'The number of shares replaced in the split. For example, in a 2-for-1 forward split, this would be',
    examples: ['1']
  }
]
