import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Header, Menu } from './UI'
import useQuery from '../../Shared/useQuery'

export const Filters = styled(Header)`
  border-radius: 0;
  border-top: none;
  flex-direction: row;
  justify-content: space-between;
`

const MenuContainer = styled.div`
  margin: 0 0 5px 0px;
  font-size: 13px;

  &:first-child {
    margin-left: 13px;
  }
`

const FilterMenu = ({
  menu, displayMenu, onOpen, onClose, onSelect, children
}) => (
  <MenuContainer onClick={onOpen}>
    {children}
    {displayMenu && <Menu menu={menu} onClose={onClose} onSelect={onSelect} />}
  </MenuContainer>
)

const SelectedFilter = styled.div`
  text-decoration: none;
  padding: 3px 7px;
  color: rgba(255,255,255,0.7);
  font-weight: 500;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;

  :hover {
    color: white;
    background-color: rgba(255,255,255, 0.3);
  }

  :after {
    content: "\\25BC";
    font-size: 9px;
    color: rgba(255,255,255,0.5);
    padding-left: 5px;
  }

  ${({ selected }) => selected && `
    background-color: rgba(255,255,255,0.3);
    color: white;
  `}

  ${({ overridden }) => overridden && `
    color: white;
    font-weight: 700;

    :after {
      color: rgba(255,255,255,0.8);
    }
  `}
`

const MenuItem = styled.div`
  display: flex;

  :before {
    display: inline-block;
    content: "";
    width: 17px;
    overflow: hidden;
  }

  ${({ selected }) => selected && `
    font-weight: 700;

    :before {
      content: "\\2714";
    }
  `}
`

export function Filter ({
  filter, filters, filterOpen, setFilterOpen, defaultTitle, render, onSelect
}) {
  const options = filters[filter].map((o) => {
    if (o.id) {
      return {
        key: o.display_name,
        value: String(o.id),
        ref: o
      }
    }

    return { key: o, value: o }
  })

  const query = useQuery()
  const history = useHistory()
  const filterValue = query.get(filter)
  const isSelected = (o) => (filterValue === o.value)
  const itemLookup = {}
  const menu = [{ key: defaultTitle, value: null }].concat(options).map((o) => {
    itemLookup[o.value] = o.key

    return {
      title: (
        <MenuItem selected={isSelected(o)}>
          {render && o.ref ? render(o.ref) : o.key}
        </MenuItem>
      ),
      value: o.value
    }
  })

  const selected = filterOpen === filter

  const onSelectDefaultHandler = (item) => {
    if (item.value) {
      query.set(filter, item.value)
    } else {
      query.delete(filter)
    }

    history.push({ search: query.toString() })
  }

  return (
    <FilterMenu
      key={filter}
      menu={menu}
      displayMenu={selected}
      onOpen={() => { setFilterOpen(filter) }}
      onClose={() => { setFilterOpen(null) }}
      onSelect={onSelect || onSelectDefaultHandler}
    >
      <SelectedFilter selected={selected} overridden={filterValue}>
        {itemLookup[filterValue] || defaultTitle}
      </SelectedFilter>
    </FilterMenu>
  )
}
