import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import CommitmentsTable from './Commitments/CommitmentsTable'
import Stats from '../Shared/Stats'
import {
  Section, Contents, Row, SectionHeader, AddLink
} from '../Shared/UI'
import NewCommitment from './Commitments/NewCommitment'
import EditCommitment from './Commitments/EditCommitment'
import RemoveCommitment from './Commitments/RemoveCommitment'
import { FundContext } from '../../Context/FundContext'
import AddCashDistribution from './Distributions/AddCashDistribution'
import EditDistribution from './Distributions/EditDistribution'
import RemoveDistribution from './Distributions/RemoveDistribution'
import CallsTable from './CapitalCalls/CallsTable'
import RemoveCall from './CapitalCalls/RemoveCall'
import EditCall from './CapitalCalls/EditCall'
import NewCall from './CapitalCalls/NewCall'
import DistributionsTable from './Distributions/DistributionsTable'
import EntitiesTable from '../Entities/EntitiesTable'
import AddStockDistribution from './Distributions/AddStockDistribution'
import StatementsTable from './Statements/StatementsTable'
import RemoveStatement from './Statements/RemoveStatement'
import EditStatement from './Statements/EditStatement'
import NewStatement from './Statements/NewStatement'

export default function Finances () {
  const { fund, fundPath, entities } = useContext(FundContext)

  return (
    <>
      <Stats stats={fund.analysis} />
      <Contents>
        <Row>
          <Section>
            <SectionHeader title="Capital Commitments">
              <AddLink to={`${fundPath}/commitments/new`}>Add</AddLink>
            </SectionHeader>
            <CommitmentsTable commitments={fund.commitments} />
          </Section>

          <Section>
            <SectionHeader title="Capital Calls">
              <AddLink to={`${fundPath}/calls/new`}>Add</AddLink>
            </SectionHeader>
            <CallsTable calls={fund.capital_calls} />
          </Section>
        </Row>

        <Row>
          <Section>
            <SectionHeader title="Statements">
              <AddLink to={`${fundPath}/statements/new`}>Add</AddLink>
            </SectionHeader>
            <StatementsTable calls={fund.statements} />
          </Section>

          <Section>
            <SectionHeader title="Cash Distributions">
              <AddLink to={`${fundPath}/distributions/new/cash`}>Add</AddLink>
            </SectionHeader>
            <DistributionsTable distributions={fund.distributions} />
          </Section>
        </Row>

        <Section>
          <SectionHeader title="Stock Distributions">
            <AddLink to={`${fundPath}/distributions/new/stock`}>Add</AddLink>
          </SectionHeader>
          <EntitiesTable
            basePath={fundPath}
            entities={entities}
            emptyMessage="No distributions have been received."
          />
        </Section>
      </Contents>

      {/* Commitment Modals */}
      <Route path="/:portfolioSlug/fund/:fundID/commitments/new" exact>
        <NewCommitment returnPath={fundPath} />
      </Route>
      <Route path="/:portfolioSlug/fund/:fundID/commitments/:commitmentID/edit" exact>
        <EditCommitment returnPath={fundPath} />
      </Route>
      <Route path="/:portfolioSlug/fund/:fundID/commitments/:commitmentID/remove" exact>
        <RemoveCommitment returnPath={fundPath} />
      </Route>

      {/* Capital Call Modals */}
      <Route path="/:portfolioSlug/fund/:fundID/calls/new" exact>
        <NewCall returnPath={fundPath} />
      </Route>
      <Route path="/:portfolioSlug/fund/:fundID/calls/:callID/edit" exact>
        <EditCall returnPath={fundPath} />
      </Route>
      <Route path="/:portfolioSlug/fund/:fundID/calls/:callID/remove" exact>
        <RemoveCall returnPath={fundPath} />
      </Route>

      {/* Distribution Modals */}
      <Route path="/:portfolioSlug/fund/:fundID/distributions/new/stock" exact>
        <AddStockDistribution returnPath={fundPath} />
      </Route>
      <Route path="/:portfolioSlug/fund/:fundID/distributions/new/cash" exact>
        <AddCashDistribution returnPath={fundPath} />
      </Route>
      <Route path="/:portfolioSlug/fund/:fundID/distributions/:distributionID/edit" exact>
        <EditDistribution returnPath={fundPath} />
      </Route>
      <Route path="/:portfolioSlug/fund/:fundID/distributions/:distributionID/remove" exact>
        <RemoveDistribution returnPath={fundPath} />
      </Route>

      {/* Statement Modals */}
      <Route path="/:portfolioSlug/fund/:fundID/statements/new" exact>
        <NewStatement returnPath={fundPath} />
      </Route>
      <Route path="/:portfolioSlug/fund/:fundID/statements/:statementID/edit" exact>
        <EditStatement returnPath={fundPath} />
      </Route>
      <Route path="/:portfolioSlug/fund/:fundID/statements/:statementID/remove" exact>
        <RemoveStatement returnPath={fundPath} />
      </Route>
    </>
  )
}
