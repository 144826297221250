import React, { useContext, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import {
  Left, Container, Header, StatusPill, TitleLink, MinusNavLink, Description,
  Title, NavBar, Subject, Subtitle, HStack, NavLink, HeaderHamburger, Favicon, Right, AddNavLink, BackgroundLoader
} from '../Shared/UI'
import { EntityContext } from '../../Context/EntityContext'
import Masthead from '../Shared/Masthead'
import ShowProspect from './ShowProspect'
import { UserContext } from '../../Context/UserContext'

export default function Routes () {
  const { portfolioPath } = useContext(UserContext)
  const {
    entity, prospectPath, initialLoadComplete
  } = useContext(EntityContext)
  const [showHamburgerMenu, setShowHamburgerMenu] = useState()
  const history = useHistory()

  const prospectMenu = [
    {
      title: 'Invest…',
      select: () => (`${prospectPath}/investments/new`)
    },
    {
      title: 'Pass…',
      select: () => (`${prospectPath}/pass`)
    },
    {
      title: 'Edit…',
      select: () => (`${prospectPath}/edit`)
    },
    {
      title: 'Remove…',
      select: () => (`${prospectPath}/remove`)
    }
  ]

  if (!initialLoadComplete) {
    return <BackgroundLoader />
  } if (!entity) {
    return <Redirect to={portfolioPath} />
  }

  return (
    <Container>
      <Masthead deep />
      <Header>
        <Subject>
          <HStack>
            <Favicon subject={entity} size={56} background />
            <div>
              <Title>
                <TitleLink href={entity.url} target="_blank" rel="noopener noreferrer">
                  {entity.display_name}
                </TitleLink>
              </Title>
              <Subtitle>{entity.legal_name}</Subtitle>
              <Description style={{ maxWidth: '600px' }}>{entity.description}</Description>
            </div>
          </HStack>
          <HStack>
            <StatusPill status={entity.status}>{entity.status}</StatusPill>
            <HeaderHamburger
              menu={prospectMenu}
              hOffset={-90}
              displayMenu={showHamburgerMenu}
              onOpen={() => { setShowHamburgerMenu(true) }}
              onClose={() => { setShowHamburgerMenu(false) }}
              onSelect={(item) => { history.push(item.select()) }}
            />
          </HStack>
        </Subject>
        <NavBar>
          <Left>
            <NavLink to={prospectPath}>Overview</NavLink>
          </Left>
          <Right>
            <AddNavLink to={`${prospectPath}/investments/new`}>Invest</AddNavLink>
            {entity.status !== 'Passed' && <MinusNavLink to={`${prospectPath}/pass`}>Pass</MinusNavLink>}
          </Right>
        </NavBar>
      </Header>

      <ShowProspect />
    </Container>
  )
}
