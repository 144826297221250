import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import styled from 'styled-components'
import InvestmentsTable from '../Investments/InvestmentsTable'
import HoldingsTable from '../Holdings/HoldingsTable'
import MilestonesTable from '../Milestones/MilestonesTable'
import Stats from '../Shared/Stats'
import {
  Section, Contents, Row, SectionHeader
} from '../Shared/UI'
import DistributionsTable from '../Funds/Distributions/DistributionsTable'
import { EntityContext } from '../../Context/EntityContext'
import EditProspect from './EditProspect'
import RemoveProspect from './RemoveProspect'
import PassProspect from './PassProspect'
import NewInvestment from '../Investments/NewInvestment'

const PassedReason = styled(Section)`
  border: 1px solid rgba(255, 150, 150,1);
  background-color: rgba(255, 235, 235,1);
  padding: 20px;
  text-align: center;
  font-style: italic;
  margin-bottom: 0;
  color: red;
`

const DisabledSection = styled(Section)`
  opacity: 0.6;
`

export default function ShowProspect () {
  const { entity, prospectPath } = useContext(EntityContext)

  return (
    <>
      <Stats stats={entity.analysis} />
      <Contents>
        {entity.status === 'Passed' && entity.outcome_reason && <PassedReason>{entity.outcome_reason}</PassedReason>}
        <Row>
          <DisabledSection>
            <SectionHeader title="Investments" />
            <InvestmentsTable investments={entity.investments} />
          </DisabledSection>

          <DisabledSection>
            <SectionHeader title="Returns" />
            <DistributionsTable distributions={entity.distributions} />
          </DisabledSection>
        </Row>

        <DisabledSection>
          <SectionHeader title="Holdings" />
          <HoldingsTable holdings={entity.holdings} />
        </DisabledSection>

        <DisabledSection>
          <SectionHeader title="Valuations" />
          <MilestonesTable milestones={entity.milestones} />
        </DisabledSection>
      </Contents>

      {/* Prospect Modals */}
      <Route path="/:portfolioSlug/prospects/:entityIDSlug/investments/new" exact>
        <NewInvestment returnPath={prospectPath} />
      </Route>
      <Route path="/:portfolioSlug/prospects/:entityIDSlug/pass" exact>
        <PassProspect />
      </Route>
      <Route path="/:portfolioSlug/prospects/:entityIDSlug/edit" exact>
        <EditProspect />
      </Route>
      <Route path="/:portfolioSlug/prospects/:entityIDSlug/remove" exact>
        <RemoveProspect />
      </Route>
    </>
  )
}
