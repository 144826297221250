import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import InvestmentsTable from '../Investments/InvestmentsTable'
import HoldingsTable from '../Holdings/HoldingsTable'
import MilestonesTable from '../Milestones/MilestonesTable'
import Stats from '../Shared/Stats'
import {
  Section, Contents, Row, SectionHeader, AddLink
} from '../Shared/UI'
import DistributionsTable from './Distributions/DistributionsTable'
import NewInvestment from '../Investments/NewInvestment'
import NewHolding from '../Holdings/NewHolding'
import EditInvestment from '../Investments/EditInvestment'
import RemoveInvestment from '../Investments/RemoveInvestment'
import { EntityContext } from '../../Context/EntityContext'
import EditHolding from '../Holdings/EditHolding'
import RemoveHolding from '../Holdings/RemoveHolding'
import EditSplit from '../Milestones/EditSplit'
import AddSplit from '../Milestones/AddSplit'
import RemoveSplit from '../Milestones/RemoveSplit'
import EditValuation from '../Milestones/EditValuation'
import AddValuation from '../Milestones/AddValuation'
import RemoveValuation from '../Milestones/RemoveValuation'
import AddDistribution from './Distributions/AddDistribution'
import EditDistribution from './Distributions/EditDistribution'
import RemoveDistribution from './Distributions/RemoveDistribution'

export default function Finances ({ baseRoute }) {
  const { entity, entityPath } = useContext(EntityContext)
  const financesPath = `${entityPath}/finances`
  const isCrypto = entity.kind === 'Cryptocurrency'

  return (
    <>
      <Stats stats={entity.analysis} />
      <Contents>
        <Row>
          <Section>
            <SectionHeader title="Investments">
              <AddLink to={`${financesPath}/investments/new`}>Add</AddLink>
            </SectionHeader>
            <InvestmentsTable investments={entity.investments} />
          </Section>

          <Section>
            <SectionHeader title="Returns">
              <AddLink to={`${financesPath}/distributions/new`}>Add</AddLink>
            </SectionHeader>
            <DistributionsTable distributions={entity.distributions} />
          </Section>
        </Row>

        <Section>
          <SectionHeader title="Holdings">
            <AddLink to={`${financesPath}/holdings/new`}>Add</AddLink>
          </SectionHeader>
          <HoldingsTable holdings={entity.holdings} showTip />
        </Section>

        <Section>
          <SectionHeader title="Valuation Milestones">
            <div>
              <AddLink to={`${financesPath}/valuations/new`}>{isCrypto ? 'Add' : 'Add Valuation'}</AddLink>
              {!isCrypto && (<AddLink to={`${financesPath}/splits/new`} style={{ marginLeft: '20px' }}>Add Stock Split</AddLink>)}
            </div>
          </SectionHeader>
          <MilestonesTable milestones={entity.milestones} isCrypto={isCrypto} />
        </Section>
      </Contents>

      {/* Investment Modals */}
      <Route path={`${baseRoute}/investments/new`} exact>
        <NewInvestment returnPath={financesPath} />
      </Route>
      <Route path={`${baseRoute}/investments/:investmentID/edit`} exact>
        <EditInvestment returnPath={financesPath} />
      </Route>
      <Route path={`${baseRoute}/investments/:investmentID/remove`} exact>
        <RemoveInvestment returnPath={financesPath} />
      </Route>

      {/* Distribution Modals */}
      <Route path={`${baseRoute}/distributions/new`} exact>
        <AddDistribution returnPath={financesPath} />
      </Route>
      <Route path={`${baseRoute}/distributions/:distributionID/edit`} exact>
        <EditDistribution returnPath={financesPath} />
      </Route>
      <Route path={`${baseRoute}/distributions/:distributionID/remove`} exact>
        <RemoveDistribution returnPath={financesPath} />
      </Route>

      {/* Holding Modals */}
      <Route path={`${baseRoute}/holdings/new/:investmentID?`} exact>
        <NewHolding returnPath={financesPath} />
      </Route>
      <Route path={`${baseRoute}/holdings/:holdingID/edit`} exact>
        <EditHolding returnPath={financesPath} />
      </Route>
      <Route path={`${baseRoute}/holdings/:holdingID/remove`} exact>
        <RemoveHolding returnPath={financesPath} />
      </Route>

      {/* Valuation Modals */}
      <Route path={`${baseRoute}/valuations/new/:holdingID?`} exact>
        <AddValuation returnPath={financesPath} />
      </Route>
      <Route path={`${baseRoute}/valuations/:valuationID/edit`} exact>
        <EditValuation returnPath={financesPath} />
      </Route>
      <Route path={`${baseRoute}/valuations/:valuationID/remove`} exact>
        <RemoveValuation returnPath={financesPath} />
      </Route>

      {/* Split Modals */}
      <Route path={`${baseRoute}/splits/new`} exact>
        <AddSplit returnPath={financesPath} />
      </Route>
      <Route path={`${baseRoute}/splits/:splitID/edit`} exact>
        <EditSplit returnPath={financesPath} />
      </Route>
      <Route path={`${baseRoute}/splits/:splitID/remove`} exact>
        <RemoveSplit returnPath={financesPath} />
      </Route>
    </>
  )
}
