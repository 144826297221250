import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Title, useModal } from '../../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../../Shared/API'
import StatementForm from './StatementForm'
import { FundContext } from '../../../Context/FundContext'

export default function EditStatement ({ returnPath }) {
  const { statementID } = useParams()
  const { reloadFund, fundAPI } = useContext(FundContext)
  const { api } = useAPIGet(`${fundAPI}/statements/${statementID}.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const editStatement = async (statement) => {
    const { error } = (await API.put(`${fundAPI}/statements/${statementID}.json`, { statement }))

    if (!error) {
      closeModal()
      reloadFund()
    }
  }

  return (
    <AwaitResponse api={api}>
      {(statement) => (
        <Modal>
          <Title>Edit Statement</Title>

          <StatementForm
            statement={statement}
            onSubmit={editStatement}
          />
        </Modal>
      )}
    </AwaitResponse>
  )
}
