import React, { useContext, useState } from 'react'
import {
  useHistory, Redirect, Switch, Route
} from 'react-router-dom'
import ShowEntity from './ShowEntity'
import {
  Left, Right, Container, Header, AddNavLink, TitleLink, StatusPill, Description,
  Title, NavBar, Subject, Subtitle, HStack, NavLink, HeaderHamburger, Favicon, BackgroundLoader
} from '../Shared/UI'
import { EntityContext } from '../../Context/EntityContext'
import Masthead from '../Shared/Masthead'
import { UserContext } from '../../Context/UserContext'
import Finances from './Finances'

export default function Routes ({ baseRoute }) {
  const { portfolioPath } = useContext(UserContext)
  const {
    entity, entityPath, initialLoadComplete, financesPath
  } = useContext(EntityContext)
  const [showHamburgerMenu, setShowHamburgerMenu] = useState()
  const history = useHistory()

  const hamburgerMenu = [
    {
      title: 'Edit…',
      select: () => (`${entityPath}/edit`)
    },
    {
      title: 'Add Investment…',
      select: () => (`${financesPath}/investments/new`)
    },
    {
      title: 'Add Holding…',
      select: () => (`${financesPath}/holdings/new`)
    },
    {
      title: 'Add Valuation…',
      select: () => (`${financesPath}/valuations/new`)
    },
    {
      title: 'Add Returns…',
      select: () => (`${financesPath}/distributions/new`)
    },
    {
      title: 'Delete…',
      select: () => (`${entityPath}/remove`)
    }
  ]

  if (!initialLoadComplete) {
    return <BackgroundLoader />
  } if (!entity) {
    return <Redirect to={portfolioPath} />
  }

  return (
    <Container>
      <Masthead deep />
      <Header>
        <Subject>
          <HStack>
            <Favicon subject={entity} size={56} background />
            <div>
              <Title>
                <TitleLink href={entity.url} target="_blank" rel="noopener noreferrer">
                  {entity.display_name}
                  {entity.stock_symbol && ` (${entity.stock_symbol})`}
                </TitleLink>
              </Title>
              <Subtitle>{entity.legal_name}</Subtitle>
              <Description style={{ maxWidth: '600px' }}>{entity.description}</Description>
            </div>
          </HStack>
          <HStack>
            <StatusPill status={entity.status}>{entity.status}</StatusPill>
            <HeaderHamburger
              menu={hamburgerMenu}
              displayMenu={showHamburgerMenu}
              onOpen={() => { setShowHamburgerMenu(true) }}
              onClose={() => { setShowHamburgerMenu(false) }}
              onSelect={(item) => { history.push(item.select()) }}
              hOffset={-150}
            />
          </HStack>
        </Subject>
        <NavBar>
          <Left>
            <NavLink to={entityPath} exact>Overview</NavLink>
            <NavLink to={financesPath}>Finances</NavLink>
          </Left>
          <Right>
            <AddNavLink to={`${financesPath}/investments/new`}>Add Investment</AddNavLink>
          </Right>
        </NavBar>
      </Header>

      <Switch>
        <Route path={`${baseRoute}/finances`}>
          <Finances baseRoute={`${baseRoute}/finances`} />
        </Route>

        <ShowEntity baseRoute={baseRoute} />
      </Switch>
    </Container>
  )
}
