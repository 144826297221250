import React, { useContext } from 'react'
import { parseISO } from 'date-fns'
import Table, { CellTitle, CellSubtitle, CellDetails } from '../Shared/Table'
import { EntityContext } from '../../Context/EntityContext'

export default ({ investments }) => {
  const columns = [
    {
      type: 'date',
      value: (row) => (parseISO(row.invested_on))
    },
    {
      title: 'Round',
      type: 'stacked',
      render: ({ round_name: roundName, thesis, fund }) => (
        <>
          <CellTitle>{roundName}</CellTitle>
          {fund && <CellSubtitle>{fund.display_name}</CellSubtitle>}
          <CellDetails>{thesis}</CellDetails>
        </>
      )
    },
    {
      title: 'Amount',
      type: 'monetary',
      value: (row) => (row.dollar_value)
    }
  ]

  const entityColumn = {
    title: 'Company',
    type: 'stacked',
    render: ({ entity }) => (
      <>
        <CellTitle>{entity.display_name}</CellTitle>
        <CellSubtitle>{entity.legal_name}</CellSubtitle>
      </>
    )
  }

  if (investments[0] && investments[0].entity) {
    columns.splice(1, 0, entityColumn)
  }

  const { financesPath } = useContext(EntityContext)

  return (
    <Table
      columns={columns}
      rows={investments}
      defaultSort={{ column: 0, direction: 'asc' }}
      emptyMessage="No investments have been made."
      rowMenu={[
        {
          title: 'Add Related Holding…',
          select: (row) => (`${financesPath}/holdings/new/${row.id}`)
        },
        {
          title: 'Edit…',
          select: (row) => (`${financesPath}/investments/${row.id}/edit`)
        },
        {
          title: 'Remove…',
          select: (row) => (`${financesPath}/investments/${row.id}/remove`)
        }
      ]}
    />
  )
}
