import React, { useContext } from 'react'
import { Title, Subtitle, useModal } from '../../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../../Shared/API'
import StatementForm from './StatementForm'
import { FundContext } from '../../../Context/FundContext'

export default function NewStatement ({ returnPath }) {
  const {
    reloadFund, fund, fundAPI, fundPath
  } = useContext(FundContext)
  const { api } = useAPIGet(`${fundAPI}/statements/new.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const addStatement = async (statement) => {
    const { error, data: result } = (await API.post(`${fundAPI}/statements.json`, { statement }))

    if (!error) {
      closeModal(`${fundPath}/statements/new/${result.id}`)
      reloadFund()
    }
  }

  return (
    <AwaitResponse api={api}>
      {(statement) => (
        <Modal>
          <Title>Enter Statement</Title>
          <Subtitle>
            What did
            {' '}
            <b><nobr>{fund.display_name}</nobr></b>
            {' '}
            report?
          </Subtitle>

          <StatementForm
            statement={statement}
            onSubmit={addStatement}
          />
        </Modal>
      )}
    </AwaitResponse>
  )
}
