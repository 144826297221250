import React, { useContext } from 'react'
import { useFieldApi, useFieldState } from 'informed'
import { Submit } from '../../Shared/Modal'
import {
  CurrencyInput, Form, Input,
  Row, DatePicker, StringSelect, TextArea, Select
} from '../../Shared/Form'
import { prepareCurrency, parseCurrency } from '../../Shared/MonetaryValue'
import useQuery from '../../Shared/useQuery'
import { EntityContext } from '../../Context/EntityContext'

const RoundSeriesSelect = ({ options, ...props }) => {
  const fieldApi = useFieldApi('round_name')

  return (
    <StringSelect
      options={options}
      {...props}
      onChange={(e) => fieldApi.setValue(e.target.value === 'Public' ? '' : e.target.value)}
    />
  )
}

const RoundName = () => {
  const { value: round_series } = useFieldState('round_series')
  return round_series === 'Public'
    ? null
    : (
    <Input field="round_name" label="Round Name" keepState />
      )
}

export default function InvestmentForm ({
  investment, onSubmit, round_series_options, fund_options
}) {
  const { entity } = useContext(EntityContext)
  const isEditing = (investment.id !== null)

  // Prepare currency values
  const { dollar_value, ...rest } = investment
  const initialValues = {
    dollar_value: prepareCurrency(dollar_value),
    ...rest
  }

  const query = useQuery()
  if (!isEditing && query.get('funds')) {
    initialValues.fund_id = query.get('funds')
  }

  const onFormSubmit = ({ dollar_value, ...rest }) => {
    onSubmit({
      ...investment,
      ...rest,
      dollar_value: parseCurrency(dollar_value)
    })
  }

  const selectOptions = fund_options.map((f) => ({ id: f.id, title: f.display_name }))

  return (
    <Form onSubmit={onFormSubmit} initialValues={initialValues}>
      <Row>
        <CurrencyInput field="dollar_value" label={isEditing ? 'Dollar Amount' : 'How much?'} placeholder="$25,000.00" />
        <DatePicker field="invested_on" label="When?" />
      </Row>
      <Row>
        {selectOptions.length > 0 && <Select field="fund_id" options={selectOptions} label="From which fund?" />}
        {entity.kind === 'Cryptocurrency'
          ? (<Input field="entity_legal_name" label="Into which exchange?" placeholder="Coinbase" />)
          : (<Input field="entity_legal_name" label="Into what legal entity?" />)}

      </Row>
      {entity.kind !== 'Cryptocurrency' && (
        <Row>
          <RoundSeriesSelect field="round_series" options={round_series_options} label="Company Stage" />
          <RoundName />
        </Row>
      )}

      <TextArea field="thesis" label="Investment Thesis" />

      <Submit title={isEditing ? 'Save Changes' : 'Record Investment'} />
    </Form>
  )
}
