import React, { useContext } from 'react'
import {
  Title, Subtitle, useModal
} from '../../Shared/Modal'
import PortfolioForm from './PortfolioForm'
import { UserContext } from '../../Context/UserContext'
import API from '../../Shared/API'

export default function NewPortfolio () {
  const { Modal, closeModal } = useModal('/')
  const { portfolios, setToken } = useContext(UserContext)

  const addPortfolio = async (newPortfolio) => {
    const { data: { token, portfolio } } = (await API.post('/portfolios.json', { portfolio: newPortfolio }))
    setToken(token)
    closeModal(`/${portfolio.slug}/funds/new`)
  }

  // If this is their very first portfolio, be more explanatory (and don't permit canceling)
  if (portfolios.length === 0) {
    return (
      <Modal>
        <Title>Create Your Portfolio</Title>
        <Subtitle>
          Your Cashlytics Portfolio let&apos;s you easily manage your investments
          (across one or many funds) and collaborate with your investing partners.
        </Subtitle>

        <PortfolioForm onSubmit={addPortfolio} />
      </Modal>
    )
  }

  return (
    <Modal>
      <Title>Set up a New Portfolio</Title>
      <Subtitle>
        Each portfolio is a collection of investments (across one or many funds),
        that you want to collaborate on with a set of investing partners.
      </Subtitle>

      {/* Must enclose closeModal in an outer function to discard the event param */}
      <PortfolioForm onSubmit={addPortfolio} onCancel={() => closeModal()} />
    </Modal>
  )
}
