import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Title, useModal } from '../../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../../Shared/API'
import CommitmentForm from './CommitmentForm'
import { FundContext } from '../../../Context/FundContext'

export default function EditCommitment ({ returnPath }) {
  const { commitmentID } = useParams()
  const { reloadFund, fundAPI } = useContext(FundContext)
  const { api } = useAPIGet(`${fundAPI}/commitments/${commitmentID}.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const editCommitment = async (commitment) => {
    const { error } = (await API.put(`${fundAPI}/commitments/${commitmentID}.json`, { commitment }))

    if (!error) {
      closeModal()
      reloadFund()
    }
  }

  return (
    <AwaitResponse api={api}>
      {(commitment) => (
        <Modal>
          <Title>Edit Commitment</Title>

          <CommitmentForm
            commitment={commitment}
            onSubmit={editCommitment}
          />
        </Modal>
      )}
    </AwaitResponse>
  )
}
