import React from 'react'
import { parseISO } from 'date-fns'
import { PrimaryTable, CellTitle, CellSubtitle } from '../../Shared/Table'

export default ({ memberships, basePath }) => {
  const columns = [
    {
      title: 'Joined',
      type: 'date',
      value: (row) => (parseISO(row.created_at))
    },
    {
      title: 'Person',
      type: 'stacked',
      render: (row) => (
        <>
          <CellTitle>{row.user_name}</CellTitle>
          <CellSubtitle>{row.user_email}</CellSubtitle>
        </>
      )
    },
    {
      title: 'Role',
      width: '100px',
      render: (row) => (row.role)
    }
  ]

  return (
    <PrimaryTable
      footer
      columns={columns}
      rows={memberships}
      defaultSort={{ column: 0, direction: 'asc' }}
      emptyMessage="Nobody has access to this portfolio."
      rowMenu={[
        {
          title: 'Remove',
          select: (row) => (`${basePath}/${row.id}/remove`)
        }
      ]}
    />
  )
}
