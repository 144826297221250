import React, { useContext } from 'react'
import { Title, useModal } from '../../Shared/Modal'
import API from '../../Shared/API'
import EntityForm from '../Entities/EntityForm'
import { EntityContext } from '../../Context/EntityContext'

export default function EditProspect () {
  const {
    prospectPath, entity, status_options, kind_options, setEntity, entityAPI
  } = useContext(EntityContext)
  const { Modal, closeModal } = useModal(prospectPath)

  const editEntity = async (newEntity) => {
    const { error, data } = (await API.put(entityAPI, { entity: newEntity }))

    if (!error) {
      closeModal()
      setEntity(data)
    }
  }

  return (
    <Modal>
      <Title>Edit Prospect</Title>

      <EntityForm
        entity={entity}
        status_options={status_options}
        kind_options={kind_options}
        onSubmit={editEntity}
        isProspect
      />
    </Modal>
  )
}
