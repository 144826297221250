import React, { useContext } from 'react'
import { Title, useModal, Subtitle } from '../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../Shared/API'
import SplitForm from './SplitForm'
import { EntityContext } from '../../Context/EntityContext'

export default function AddSplit ({ returnPath }) {
  const { reloadEntity, entityAPI } = useContext(EntityContext)
  const { api } = useAPIGet(`${entityAPI}/splits/new.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const editSplit = async (split) => {
    const { error } = (await API.post(`${entityAPI}/splits.json`, { split }))

    if (!error) {
      closeModal()
      reloadEntity()
    }
  }

  return (
    <AwaitResponse api={api}>
      {(split) => (
        <Modal>
          <Title>Add Stock Split</Title>
          <Subtitle>
            In a forward stock split, the numerator will be greater than
            the denominator. For example, in a 2-for-1 split, you would be issued
            2 shares for each 1 you currently own.
          </Subtitle>

          <SplitForm split={split} onSubmit={editSplit} />
        </Modal>
      )}
    </AwaitResponse>
  )
}
