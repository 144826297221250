import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import {
  Title, Submit, Subtitle, useModal, Button
} from '../../../Shared/Modal'
import API from '../../../Shared/API'
import { Form, Row } from '../../../Shared/Form'
import { UserContext } from '../../../Context/UserContext'

export default function RemoveMembership ({
  memberships, setMemberships, smaller, basePath
}) {
  const { membershipID } = useParams()
  const { Modal, closeModal } = useModal(basePath)
  const { user, setToken, portfolioAPI } = useContext(UserContext)
  const membership = memberships.find((m) => m.id === Number.parseInt(membershipID, 10)) || null

  const removeInvestment = async () => {
    const {
      data: { memberships: updatedMemberships, token }
    } = (await API.delete(`${portfolioAPI}/memberships/${membershipID}.json`))

    setToken(token)
    setMemberships(updatedMemberships)
    closeModal()
  }

  if (!membership) {
    return null
  }

  if (memberships.length <= 1) {
    return (
      <Modal smaller={smaller}>
        <Title>Remove Access?</Title>
        <Subtitle>
          You cannot remove the last member from a portfolio. Delete the portfolio instead.
        </Subtitle>

        <Submit title="OK" onClick={() => closeModal()} />
      </Modal>
    )
  }

  // Verify whether we want to remove the current user.
  if (membership.user_id === user.id) {
    return (
      <Modal smaller={smaller}>
        <Title>Remove Access?</Title>
        <Subtitle>
          Are you sure you want to remove yourself from
          <br />
          <b>{membership.portfolio_name}</b>
          ? You will lose access immediately!
        </Subtitle>

        <Form onSubmit={removeInvestment}>
          <Row>
            <Button title="Cancel" onClick={() => closeModal()} />
            <Submit title="Remove Access" />
          </Row>
        </Form>
      </Modal>
    )
  }

  return (
    <Modal smaller={smaller}>
      <Title>Remove Access?</Title>
      <Subtitle>
        Are you sure you want to remove
        {' '}
        <b>{membership.user_name}</b>
        {' '}
        from
        {' '}
        <b>{membership.portfolio_name}</b>
        ? They will lose access immediately.
      </Subtitle>

      <Form onSubmit={removeInvestment}>
        <Row>
          <Button title="Cancel" onClick={() => closeModal()} />
          <Submit title="Remove Access" />
        </Row>
      </Form>
    </Modal>
  )
}
