import React, { useContext, useEffect } from 'react'
import { useFieldState, useFieldApi } from 'informed'
import { Submit, Button } from '../../Shared/Modal'
import {
  CurrencyInput, Form, IntegerInput, StringSelect,
  PercentInput, Row, DatePicker, Input, DecimalInput,
  validateRequiredCurrencyValue, validateRequiredIntegerValue, Select, TextArea,
  validateRequiredDecimalValue
} from '../../Shared/Form'
import {
  prepareCurrency, parseCurrency, parsePercent, preparePercent, DollarValue
} from '../../Shared/MonetaryValue'
import { FundContext } from '../../Context/FundContext'

const SAFE = ({ expectedValue }) => {
  const { value: holding_type } = useFieldState('holding_type')
  return holding_type !== 'SAFE' && holding_type !== 'Note'
    ? null
    : (
    <>
      <Row>
        <DollarValue
          value={expectedValue || 25000 * 1000000}
          render={(formatted) => (
            <CurrencyInput
              field="dollar_value"
              label="Dollar Value"
              placeholder={formatted}
              optional
              keepState
            />
          )}
        />
        <DatePicker field="bestowed_on" label="Effective Date" keepState />
      </Row>
      <Row>
        <CurrencyInput
          field="conversion_cap"
          label="Conversion Cap"
          placeholder="$5,000,000.00"
          optional
          keepState
        />
        <PercentInput
          field="conversion_ratio"
          label="Conversion Ratio"
          placeholder="80%"
          keepState
        />
      </Row>
    </>
      )
}

const PrivateCertificated = () => {
  const { value: certificate_status } = useFieldState('certificate_status')
  return certificate_status !== 'Private - Certificated'
    ? null
    : (
    <Row>
      <Input field="certificate_name" label="Name / Number" placeholder="PS-1" keepState />
      <Input field="certificate_location" label="Location" placeholder="Carta" keepState />
    </Row>
      )
}

const PublicBrokerage = () => {
  const { value: certificate_status } = useFieldState('certificate_status')
  return certificate_status !== 'Public'
    ? null
    : (
    <Input field="certificate_location" label="Brokerage" placeholder="eTrade" keepState />
      )
}

const SharePriceCount = ({ expectedValue }) => {
  const { value: holdingType } = useFieldState('holding_type')
  const { value: shareCountValue, touched: shareCountTouched } = useFieldState('share_count')
  const { value: sharePriceValue } = useFieldState('share_price')
  const shareCountFieldApi = useFieldApi('share_count')

  useEffect(() => {
    if (expectedValue && expectedValue > 0 &&
      sharePriceValue && (!shareCountTouched || shareCountValue === '')) {
      const sharePrice = Number.parseFloat(sharePriceValue)

      if (sharePrice > 0) {
        const shareCount = expectedValue / 1000000 / sharePrice
        if (holdingType === 'Shares') {
          shareCountFieldApi.setValue(Math.floor(shareCount))
        } else {
          shareCountFieldApi.setValue(shareCount)
        }
        shareCountFieldApi.setTouched(false)
      }
    }
  }, [expectedValue, sharePriceValue, shareCountTouched, shareCountFieldApi, shareCountValue, holdingType])

  return holdingType === 'Shares'
    ? (
    <Row>
      <CurrencyInput
        field="share_price"
        label="Price Per Share"
        decimalLimit={5}
        placeholder="$0.00000"
        optional
        keepState
      />
      <IntegerInput
        field="share_count"
        label="# of Shares"
        placeholder="1000"
        optional
        keepState
      />
    </Row>
      )
    : (
    <Row>
      <CurrencyInput
        field="share_price"
        label="Price"
        decimalLimit={5}
        placeholder="$0.00000"
        optional
        keepState
      />
      <DecimalInput
        field="share_count"
        label="# of Tokens"
        decimalLimit={6}
        placeholder="1.234"
        optional
        keepState
      />
    </Row>
      )
}

const Shares = ({ certificateOptions, expectedValue }) => {
  const { value: holdingType } = useFieldState('holding_type')

  return holdingType !== 'Shares'
    ? null
    : (
    <>
      <SharePriceCount expectedValue={expectedValue} />
      <Row>
        <Input field="share_class" label="Share Class" placeholder="Series A Preferred" keepState />
        <DatePicker field="bestowed_on" label="Granted On" keepState />
      </Row>
      <Row>
        <StringSelect field="certificate_status" label="Stock Type" options={certificateOptions} keepState />
        <PublicBrokerage />
      </Row>
      <PrivateCertificated />
    </>
      )
}

const Tokens = ({ expectedValue }) => {
  const { value: holdingType } = useFieldState('holding_type')

  return holdingType !== 'Tokens'
    ? null
    : (
    <>
      <SharePriceCount expectedValue={expectedValue} />
      <Row>
        <DatePicker field="bestowed_on" label="Bought On" keepState />
      </Row>
    </>
      )
}

const OptionGrant = () => {
  const { value: holding_type } = useFieldState('holding_type')
  return holding_type !== 'Option Grant'
    ? null
    : (
    <>
      <Row>
        <IntegerInput
          field="share_count"
          label="# of Shares"
          placeholder="1000"
          optional
          keepState
        />
        <DatePicker field="bestowed_on" label="Granted On" keepState />
      </Row>
      <Row>
        <Input field="share_class" label="Share Class" placeholder="Common" keepState />
        <CurrencyInput
          field="exercise_price"
          label="Exercise Price"
          decimalLimit={5}
          placeholder="$0.00000"
          optional
          keepState
        />
      </Row>
      <Row>
        <DatePicker field="option_vesting_starts_on" label="Vesting Start Date" keepState />
        <DatePicker field="option_vesting_ends_on" label="Vesting End Date" keepState />
      </Row>
    </>
      )
}

const Warrant = () => {
  const { value: holding_type } = useFieldState('holding_type')
  return holding_type !== 'Warrant'
    ? null
    : (
    <>
      <Row>
        <IntegerInput
          field="share_count"
          label="# of Shares"
          placeholder="1000"
          optional
          keepState
        />
        <CurrencyInput
          field="exercise_price"
          label="Exercise Price"
          decimalLimit={5}
          placeholder="$0.00000"
          optional
          keepState
        />
      </Row>
      <Row>
        <Input field="share_class" label="Share Class" placeholder="Common" keepState />
        <CurrencyInput
          field="share_price"
          label="Current Share Price"
          decimalLimit={5}
          placeholder="$0.00000"
          optional
          keepState
        />
      </Row>
      <Row>
        <DatePicker field="bestowed_on" label="Granted On" keepState />
        <DatePicker field="expires_on" label="Expires On" keepState />
      </Row>
    </>
      )
}

const SPV = () => {
  const { value: holding_type } = useFieldState('holding_type')
  return holding_type !== 'SPV'
    ? null
    : (
    <>
      <Input field="spv_name" label="SPV Name" placeholder="Special Purpose Vehicle, LLC" keepState />
      <Row>
        <CurrencyInput
          field="dollar_value"
          label="Dollar Value"
          placeholder="$25,000.00"
          optional
          keepState
        />
        <DatePicker field="bestowed_on" label="Effective Date" keepState />
      </Row>
      <Row>
        <CurrencyInput
          field="share_price"
          label="Price Per Share"
          decimalLimit={5}
          placeholder="$0.00000"
          optional
          keepState
        />
        <PercentInput
          field="spv_carry_rate"
          label="SPV Carry"
          placeholder="20%"
          keepState
        />
      </Row>
    </>
      )
}

const validateFields = (values) => {
  if (values.holding_type === 'SAFE' ||
    values.holding_type === 'Note' ||
    values.holding_type === 'SPV') {
    return {
      dollar_value: validateRequiredCurrencyValue(values.dollar_value)
    }
  }

  if (values.holding_type === 'Shares') {
    return {
      share_count: validateRequiredIntegerValue(values.share_count),
      share_price: validateRequiredCurrencyValue(values.share_price)
    }
  }

  if (values.holding_type === 'Tokens') {
    return {
      share_count: validateRequiredDecimalValue(values.share_count),
      share_price: validateRequiredCurrencyValue(values.share_price)
    }
  }

  if (values.holding_type === 'Option Grant') {
    return {
      share_count: validateRequiredIntegerValue(values.share_count),
      exercise_price: validateRequiredCurrencyValue(values.exercise_price)
    }
  }

  return {}
}

export default function HoldingForm ({
  onSubmit, onCancel, holding, showNothingYet, expectedValue,
  holding_type_options, certificate_options, status_options,
  fund_options
}) {
  // Prepare currency values
  const {
    dollar_value, conversion_cap, share_price, share_count,
    spv_carry_rate, conversion_ratio, exercise_price, ...rest
  } = holding
  const initialValues = {
    dollar_value: prepareCurrency(dollar_value),
    conversion_cap: prepareCurrency(conversion_cap),
    share_price: prepareCurrency(share_price),
    share_count: rest.holding_type === 'Tokens' ? prepareCurrency(share_count) : share_count,
    spv_carry_rate: preparePercent(spv_carry_rate),
    conversion_ratio: preparePercent(conversion_ratio),
    exercise_price: prepareCurrency(exercise_price),
    ...rest
  }

  const onFormSubmit = ({
    dollar_value, conversion_cap, share_price, share_count,
    spv_carry_rate, conversion_ratio, exercise_price, ...newHolding
  }) => {
    onSubmit({
      ...holding,
      ...newHolding,
      dollar_value: parseCurrency(dollar_value),
      conversion_cap: parseCurrency(conversion_cap),
      share_price: parseCurrency(share_price),
      share_count: rest.holding_type === 'Tokens' ? parseCurrency(share_count) : share_count,
      spv_carry_rate: parsePercent(spv_carry_rate),
      conversion_ratio: parsePercent(conversion_ratio),
      exercise_price: parseCurrency(exercise_price)
    })
  }

  const isEditing = initialValues.id
  const selectOptions = fund_options.map((f) => ({ id: f.id, title: f.display_name }))
  const { fund } = useContext(FundContext)
  if (!isEditing && fund) {
    initialValues.fund_id = fund.id
  }

  return (
    <Form onSubmit={onFormSubmit} initialValues={initialValues} validateFields={validateFields}>
      {({ formApi }) => (
        <>
          <Row>
            <StringSelect
              label="Type"
              field="holding_type"
              options={holding_type_options}
              onChange={formApi.validate}
            />
            {isEditing && <StringSelect label="Current Status" field="status" options={status_options} />}
          </Row>

          <Row>
            {selectOptions.length > 0 && <Select field="fund_id" options={selectOptions} label="For which fund?" />}
            <Input field="entity_legal_name" label="From what legal entity?" />
          </Row>

          <SAFE expectedValue={expectedValue} />
          <Shares certificateOptions={certificate_options} expectedValue={expectedValue} />
          <Tokens expectedValue={expectedValue} />
          <OptionGrant />
          <Warrant />
          <SPV />

          <TextArea field="notes" label="Notes" />

          <Row>
            {showNothingYet && <Button title="Nothing Yet" onClick={onCancel} />}
            <Submit title="Save" />
          </Row>
        </>
      )}
    </Form>
  )
}
