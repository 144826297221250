import React from 'react'
import { Submit, Button } from '../../Shared/Modal'
import {
  Form, Input, Row, DisplayNameInput, CurrencyInput, StringSelect
} from '../../Shared/Form'
import {
  prepareCurrency, parseCurrency
} from '../../Shared/MonetaryValue'

export default function FundForm ({
  fund, onSubmit, onCancel, children, relationship_options, status_options
}) {
  // Prepare currency values
  const {
    target_size, ...rest
  } = fund
  const initialValues = {
    target_size: prepareCurrency(target_size),
    ...rest
  }

  const onFormSubmit = ({
    target_size, ...newFund
  }) => {
    onSubmit({
      ...fund,
      ...newFund,
      target_size: parseCurrency(target_size)
    })
  }

  return (
    <Form onSubmit={onFormSubmit} initialValues={initialValues}>
      {children}

      <Row>
        <DisplayNameInput field="display_name" label="Fund Name" destination="legal_name" placeholder="Fund I" />
        {status_options && <StringSelect field="status" options={status_options} label="Status" />}
      </Row>
      <Input field="legal_name" label="Legal Name (if backed by an entity)" placeholder="Fund I, LLC" />

      <Row>
        <CurrencyInput
          field="target_size"
          label="Fund Target Size"
          placeholder="$5,000,000.00"
          optional
          keepState
        />
        {relationship_options && <StringSelect field="relationship" options={relationship_options} label="Your Role" />}
      </Row>

      <Row>
        {onCancel && <Button title="Cancel" onClick={onCancel} />}
        <Submit title={fund ? 'Save' : 'Create Fund'} />
      </Row>
    </Form>
  )
}
