import React from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'

const EstimatedValue = styled(NumberFormat)`
  font-style: italic;
  color: #666;
`

export const EmptyValue = styled.span`
  font-style: italic;
  color: #666;
`

export const parsePercent = (value) => {
  if (!value) {
    return value
  }

  return Number.parseFloat(value) * 1000000 / 100
}

export const preparePercent = (value) => {
  if (!value) {
    return value
  }

  return value / 1000000 * 100
}

export const parseCurrency = (value) => {
  if (!value) {
    return value
  }

  return Number.parseFloat(value) * 1000000
}

export const prepareCurrency = (value) => {
  if (!value) {
    return value
  }

  return value / 1000000
}

export const DollarValue = ({
  value, estimate, decimals, humanize, blankOnZero, render, className
}) => {
  if (value === null) {
    if (render) {
      return render(null)
    }

    return null
  }

  if (value === 0) {
    if (blankOnZero) {
      if (render) {
        return render(null)
      }

      return null
    }

    if (!render) {
      return (<EmptyValue>--</EmptyValue>)
    }
  }

  let Value = NumberFormat
  if (estimate) {
    Value = EstimatedValue
  }

  let suffix
  let displayValue = (value || 0) / 1000000
  if (humanize) {
    if (displayValue >= 1000) {
      displayValue /= 1000
      suffix = 'K'

      if (displayValue >= 1000) {
        displayValue /= 1000
        suffix = 'M'

        if (displayValue >= 1000) {
          displayValue /= 1000
          suffix = 'B'
        }
      }
    }
  }

  return (
    <Value
      value={displayValue}
      displayType="text"
      thousandSeparator
      decimalScale={decimals !== undefined ? decimals : 2}
      fixedDecimalScale
      prefix="$"
      suffix={suffix}
      renderText={render}
      className={className}
    />
  )
}

const PositiveDelta = styled(NumberFormat)`
  color: green;
`

const NegativeDelta = styled(NumberFormat)`
  color: red;
`

export const DeltaValue = ({ value, decimals }) => {
  let Delta = NumberFormat
  if (value > 0) {
    Delta = PositiveDelta
  } else if (value < 0) {
    Delta = NegativeDelta
  } else {
    return (<EmptyValue>--</EmptyValue>)
  }

  // Override color for numbers less than 1 if we aren't showing decimals
  let displayValue = value
  if (decimals === 0 && Math.abs(value) < 1000000) {
    Delta = NumberFormat
    displayValue = 0
  }

  return (
    <Delta
      value={displayValue / 1000000}
      displayType="text"
      thousandSeparator
      decimalScale={decimals !== undefined ? decimals : 2}
      fixedDecimalScale
      prefix={displayValue > 0 ? '+$' : '$'}
    />
  )
}

export const ComparativeValue = ({ value, comparison, decimals }) => {
  let Delta = NumberFormat
  if (value > comparison) {
    Delta = PositiveDelta
  } else if (value < comparison) {
    Delta = NegativeDelta
  }

  return (
    <Delta
      value={value / 1000000}
      displayType="text"
      thousandSeparator
      decimalScale={decimals !== undefined ? decimals : 2}
      fixedDecimalScale
      prefix="$"
    />
  )
}

export const Multiple = styled(NumberFormat)``
