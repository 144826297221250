import React from 'react'
import { Submit } from '../../Shared/Modal'
import {
  IntegerInput, Form,
  Row, DatePicker
} from '../../Shared/Form'

export default function SplitForm ({ split, onSubmit }) {
  const isEditing = (split.id !== null)

  const onFormSubmit = (newSplit) => {
    onSubmit({ ...split, ...newSplit })
  }

  return (
    <Form onSubmit={onFormSubmit} initialValues={split}>
      <Row>
        <IntegerInput field="numerator" label="Numerator" placeholder="2" />
        <IntegerInput field="denominator" label="Denominator" placeholder="1" />
      </Row>
      <DatePicker field="split_on" label="When did the split take effect?" />

      <Submit title={isEditing ? 'Save Changes' : 'Record Split'} />
    </Form>
  )
}
