import React, { useContext } from 'react'
import { AwaitResponse, useAPIGet } from '../../Shared/API'
import { Loading, AbortOnError } from './Shared'
import { UserContext } from '../../Context/UserContext'
import useQuery from '../../Shared/useQuery'
import PortfolioTable from './PortfolioTable'

export default function ShowCompanies () {
  const { portfolioTouched, portfolioPath, portfolioAPI } = useContext(UserContext)
  const query = useQuery()
  const searchQuery = new URLSearchParams({
    ...Object.fromEntries(query.entries()),
    ts: portfolioTouched
  })

  const { api } = useAPIGet(`${portfolioAPI}?${searchQuery.toString()}`, [])

  return (
    <AwaitResponse api={api} loading={Loading} error={AbortOnError}>
      {({
        summary, entities, prospects, filters
      }) => (
        <PortfolioTable
          basePath={portfolioPath}
          summary={summary}
          entities={entities}
          prospects={prospects}
          filters={filters}
        />
      )}
    </AwaitResponse>
  )
}
