import React, { useEffect, useState } from 'react'
import { format as formatDate } from 'date-fns'
import { useFieldState, useFieldApi } from 'informed'
import styled from 'styled-components'
import { Submit, Button } from '../../Shared/Modal'
import {
  Form, Input, Row, DisplayNameInput, StringSelect
} from '../../Shared/Form'
import { Favicon } from '../Shared/UI'
import { useAPIGet } from '../../Shared/API'

const OutcomeReason = () => {
  const { value: status } = useFieldState('status')

  if (!status || status === 'Operating') {
    return null
  }

  // if (status === 'Public (Locked Up)') {
  //   const date = new Date();
  //   date.setMonth(date.getMonth() + 6);
  //   const future = formatDate(date, 'MMMM, yyy');

  //   return (
  //     <Input field="outcome_reason" label="When does lockup expire?" placeholder={`Locked up until ${future}…`} keepState />
  //   );
  // }

  const today = formatDate(new Date(), 'MMMM, yyy')

  return (
    <Input field="outcome_reason" label={`Reason it was ${status}`} placeholder={`${status} in ${today}…`} keepState />
  )
}

const Status = ({ status_options }) => {
  const { value: kind } = useFieldState('kind')

  if (kind === 'Cryptocurrency') {
    return null
  }

  return (<StringSelect field="status" options={status_options} label="Current Status" />)
}

const StockSymbol = () => {
  const { value: kind } = useFieldState('kind')

  if (kind === 'Public') {
    return (
      <Input field="stock_symbol" label="Ticker Symbol" placeholder="AAPL" keepState />
    )
  }

  if (kind === 'Cryptocurrency') {
    return (
      <Input field="stock_symbol" label="Token" placeholder="BTC" keepState />
    )
  }

  return null
}

const FaviconContainer = () => {
  const { value: url } = useFieldState('url')
  const { value: display_name } = useFieldState('display_name')
  const { value: description } = useFieldState('description')
  const descriptionFieldApi = useFieldApi('description')
  const { api } = useAPIGet(`/entities/favicon.json?url=${url}`, {})
  const [subject, setSubject] = useState()

  useEffect(() => {
    if (api.data && api.data.website === url) {
      setSubject(api.data)

      if (api.data.description && (!description || description.length === 0)) {
        descriptionFieldApi.setValue(api.data.description)
      }
    }
  }, [url, api.data, description, descriptionFieldApi])

  return (
    <Favicon
      subject={{ ...subject, display_name }}
      size={44}
      style={{ marginRight: 'none', marginLeft: '5px', marginTop: '5px' }}
    />
  )
}

const NetworkError = styled.p`
  color: red;
  margin: 0;
`

export default function EntityForm ({
  entity, onSubmit, onCancel,
  status_options, kind_options,
  children, onImport,
  isProspect, networkError
}) {
  const isEditing = (entity.id !== null && entity.id !== undefined)
  const createButton = isProspect ? 'Add Lead' : 'Create Entity'
  const validateURL = (v) => (v && !v.match(/^https?:\/\/.+/g) ? 'Invalid URL' : undefined)

  return (
    <Form onSubmit={onSubmit} initialValues={entity}>
      {children}

      {isProspect && !isEditing
        ? <Input field="display_name" label="Name" placeholder="Apple" />
        : (
          <Row>
            <DisplayNameInput
              field="display_name"
              label="Name"
              destination="legal_name"
              placeholder="Apple"
              suffix="Inc."
            />
            <StringSelect field="kind" options={kind_options} label="Type" />
          </Row>
          )}

      <Row style={{ alignItems: 'center' }}>
        <Input field="url" type="url" label="Website" placeholder="https://" validate={validateURL} validateOnBlur />
        <FaviconContainer />
      </Row>

      <Input field="description" label="Tagline" />

      {!isProspect && (
        <>
          <Row>
            <Status status_options={status_options} />
            <StockSymbol />
          </Row>
          <OutcomeReason />
        </>
      )}

      {networkError && <NetworkError>{networkError}</NetworkError>}

      <Row>
        {isProspect && <Button title="Cancel" onClick={onCancel} />}
        {onImport && <Button title="Import from CSV" onClick={onImport} />}
        <Submit title={isEditing ? 'Save Changes' : createButton} />
      </Row>
    </Form>
  )
}
