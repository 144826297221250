export default [
  {
    name: 'Company Name',
    description: 'The display name (not legal name) of the entity in which you invested, e.g.',
    examples: ['Acme']
  },
  {
    name: 'Bestowed On',
    description: 'When you received the holding, e.g.',
    examples: ['1/1/2020']
  },
  {
    name: 'TBD',
    description: 'tbd',
    examples: ['tbd']
  }
]
