import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Title, useModal } from '../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../Shared/API'
import InvestmentForm from './InvestmentForm'
import { EntityContext } from '../../Context/EntityContext'

export default function EditInvestment ({ returnPath }) {
  const { investmentID } = useParams()
  const { reloadEntity, entityAPI } = useContext(EntityContext)
  const { api } = useAPIGet(`${entityAPI}/investments/${investmentID}.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const editInvestment = async (investment) => {
    const { error } = (await API.put(`${entityAPI}/investments/${investmentID}.json`, { investment }))

    if (!error) {
      closeModal()
      reloadEntity()
    }
  }

  return (
    <AwaitResponse api={api}>
      {({ investment, round_series_options, fund_options }) => (
        <Modal>
          <Title>Edit Investment</Title>

          <InvestmentForm
            investment={investment}
            round_series_options={round_series_options}
            fund_options={fund_options}
            onSubmit={editInvestment}
          />
        </Modal>
      )}
    </AwaitResponse>
  )
}
