import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { format as formatDate, parseISO } from 'date-fns'
import { useHistory } from 'react-router-dom'
import Markdown from 'react-markdown'
import { Menu } from '../Shared/UI'

import { EntityContext } from '../../Context/EntityContext'
import { UserContext } from '../../Context/UserContext'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Byline = styled.span`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  color: #395e7c;
`

const Body = styled.div`
  font-size: 14px;
  border-left: 3px solid #ddd;
  padding-left: 7px;
  margin-top: 5px;

  *:first-child {
    margin-top: 0;
  }
`

export const Date = ({ date }) => (
  <span title={formatDate(date, 'MMMM d, yyy')}>{formatDate(date, 'MMM yyy')}</span>
)

const HamburgerIcon = styled.div`
  padding: 0 5px;
  cursor: pointer;

  &:after, &:before, > div {
    background-color: transparent;
    border-radius: 1.5px;
    content: '';
    display: block;
    height: 3px;
    width: 3px;
    margin: 2px 0;
  }
`

const NoteContainer = styled.div`
  margin-bottom: 20px;

  :hover {
    ${HamburgerIcon} {
      &:after, &:before, > div {
        background-color: #999;
      }

      :hover {
        &:after, &:before, > div {
          background-color: #395e7c;
        }
      }
    }
  }
`

const HamburgerContainer = styled.div``
const Hamburger = ({
  menu, displayMenu, onOpen, onClose, onSelect, hOffset
}) => (
  <HamburgerContainer onClick={onOpen}>
    {displayMenu && (
    <Menu
      menu={menu}
      onClose={onClose}
      onSelect={onSelect}
      style={{ marginLeft: hOffset ? `${hOffset}px` : 0 }}
    />
    )}
    <HamburgerIcon style={{ visibility: (displayMenu ? 'hidden' : 'visible') }}><div /></HamburgerIcon>
  </HamburgerContainer>
)

function Note ({
  note, entityPath, history, user,
  setHamburgerMenuIsVisibleForID, hamburgerMenuIsVisibleForID
}) {
  const hamburgerMenu = [
    {
      title: 'Edit…',
      select: () => (`${entityPath}/notes/${note.id}/edit`)
    },
    {
      title: 'Delete…',
      select: () => (`${entityPath}/notes/${note.id}/remove`)
    }
  ]

  return (
    <NoteContainer>
      <Header>
        <Byline>
          <Date date={parseISO(note.created_at)} />
          &nbsp;&mdash;&nbsp;
          {note.author.name}
          {parseISO(note.updated_at) > parseISO(note.created_at) &&
          (
            <i>
              &nbsp;
              (Edited)
            </i>
          )}
        </Byline>
        { note.author.id === user.id &&
        (
        <Hamburger
          menu={hamburgerMenu}
          displayMenu={hamburgerMenuIsVisibleForID === note.id}
          onOpen={() => { setHamburgerMenuIsVisibleForID(note.id) }}
          onClose={() => { setHamburgerMenuIsVisibleForID(undefined) }}
          onSelect={(item) => { history.push(item.select()) }}
        />
        )}
      </Header>

      <Body><Markdown source={note.text} /></Body>
    </NoteContainer>
  )
}

const Notes = styled.div`
  margin: 0 20px 20px 20px;
`

const Empty = styled.div`
  margin: 20px;
  color: #666;
  font-style: italic;
  text-align: center;
`

export default ({ notes }) => {
  const { user } = useContext(UserContext)
  const { entityPath } = useContext(EntityContext)
  const history = useHistory()
  const [hamburgerMenuIsVisibleForID, setHamburgerMenuIsVisibleForID] = useState()

  if (notes.length === 0) {
    return <Empty>Nothing has been recorded yet.</Empty>
  }

  return (
    <Notes>
      {notes.map((note) => (
        <Note
          key={note.id}
          note={note}
          user={user}
          entityPath={entityPath}
          history={history}
          hamburgerMenuIsVisibleForID={hamburgerMenuIsVisibleForID}
          setHamburgerMenuIsVisibleForID={setHamburgerMenuIsVisibleForID}
        />
      ))}
    </Notes>
  )
}
