import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { UserContext } from '../Context/UserContext'
import {
  create, get,
  parseCreationOptionsFromJSON,
  parseRequestOptionsFromJSON,
  supported
} from '@github/webauthn-json/browser-ponyfill'
import API from './API'

const LogInButton = styled.button`
    font-size: 16px;
    font-weight: 500;
    margin-left: 15px;
    padding: 8px 15px;
    border-radius: 3px;
    border: 2px solid white;

    &:hover {
      background-color: white;
    }
`

export function PasskeyLoginButton () {
  const { setToken } = useContext(UserContext)
  const history = useHistory()

  const login = async () => {
    try {
      const { data: prompt } = await API.get('/sessions.json')
      const passkey = await requestPasskey(prompt.challenge)
      const { data: { token } } = await API.post('/sessions.json', { id: prompt.id, passkey })
      setToken(token)
      history.replace('/')
    } catch (e) {
      console.log(e) // user cancelled
    }
  }

  if (supported()) {
    return (
      <LogInButton onClick={login}>Continue with Passkey</LogInButton>
    )
  }
}

export async function addPasskey () {
  const { data: prompt } = await API.get('/passkeys/new.json')
  const passkey = await registerPasskey(prompt)
  const { data: { token } } = await API.post('/passkeys.json', { id: prompt.id, passkey })
  return token
}

async function requestPasskey (challenge) {
  const passkey = await get(parseRequestOptionsFromJSON({
    publicKey: {
      challenge,
      allowCredentials: [],
      userVerification: 'discouraged'
    }
  }))

  return passkey.toJSON()
}

async function registerPasskey (prompt) {
  const passkey = await create(parseCreationOptionsFromJSON({
    publicKey: {
      challenge: prompt.challenge,
      rp: {
        id: window.location.hostname,
        name: 'Cashlytics'
      },
      user: {
        id: prompt.id,
        name: prompt.name,
        displayName: prompt.name
      },
      pubKeyCredParams: [],
      excludeCredentials: [],
      timeout: prompt.timeout,
      authenticatorSelection: {
        userVerification: 'required',
        authenticatorAttachment: await getAuthAttachment('auto'),
        residentKey: 'preferred',
        requireResidentKey: false
      },
      attestation: 'direct',
      extensions: {
        credProps: true
      }
    }
  }))

  return passkey.toJSON()
}

async function getAuthAttachment (authType) {
  if (authType === 'local') { return 'platform' }
  if (authType === 'roaming' || authType === 'extern') { return 'cross-platform' }
  if (authType === 'both') { return undefined } // The webauthn protocol considers `null` as invalid but `undefined` as "both"!

  // the default case: "auto", depending on device capabilities
  try {
    if (await isLocalAuthenticator()) { return 'platform' } else { return 'cross-platform' }
  } catch (e) {
    // might happen due to some security policies
    // see https://w3c.github.io/webauthn/#sctn-isUserVerifyingPlatformAuthenticatorAvailable
    return undefined // The webauthn protocol considers `null` as invalid but `undefined` as "both"!
  }
}

async function isLocalAuthenticator () {
  return await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
}
