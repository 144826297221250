import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { format as formatDate, parseISO } from 'date-fns'
import {
  Title, Submit, Subtitle, useModal
} from '../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../Shared/API'
import { EntityContext } from '../../Context/EntityContext'
import { Form } from '../../Shared/Form'

export default function RemoveNote () {
  const { noteID } = useParams()
  const { reloadEntity, entityPath, entityAPI } = useContext(EntityContext)
  const { api } = useAPIGet(`${entityAPI}/notes/${noteID}.json`, {})
  const { Modal, closeModal } = useModal(entityPath)

  const removeNote = async () => {
    const { error } = (await API.delete(`${entityAPI}/notes/${noteID}.json`))

    if (!error) {
      closeModal()
      reloadEntity()
    }
  }

  return (
    <AwaitResponse api={api}>
      {(note) => (
        <Modal>
          <Title>Remove Note?</Title>
          <Subtitle>
            Are you sure you want to remove your note from
            <b><nobr>{` ${formatDate(parseISO(note.created_at), 'MMMM d, yyy')}`}</nobr></b>
            ? This cannot be undone.
          </Subtitle>

          <Form onSubmit={removeNote}>
            <Submit title="Remove Note" />
          </Form>
        </Modal>
      )}
    </AwaitResponse>
  )
}
