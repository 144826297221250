import React, { useContext, useEffect } from 'react'
import {
  Switch, Redirect, Route, useHistory
} from 'react-router-dom'
import Dashboard from './Dashboard'
import { UserContext } from './Context/UserContext'
import NewPortfolio from './Dashboard/Portfolios/NewPortfolio'
import StaticRoutes from './Static'
import AcceptInvite from './Dashboard/Portfolios/AcceptInvite'
import { BackgroundLoader } from './Dashboard/Shared/UI'

const LogOut = () => {
  const { setToken } = useContext(UserContext)
  const history = useHistory()

  useEffect(() => {
    setToken(null)
    history.replace('/')
  })

  return <BackgroundLoader />
}

export default function App () {
  const { tokenValid, user, portfolios } = useContext(UserContext)

  if (!tokenValid) {
    return <LogOut />
  }

  return !user
    ? <StaticRoutes />
    : (
    <Switch>
      {/* Session Routes */}
      <Route path="/logout" exact>
        <LogOut />
      </Route>

      {/* Onboarding Routes */}
      <Route path="/portfolios/new">
        <NewPortfolio />
      </Route>
      <Route path="/join/:code">
        <AcceptInvite />
      </Route>
      <Route path="/accept/:code">
        <AcceptInvite />
      </Route>

      {/* Dashboard Routes */}
      <Route path="/:portfolioSlug">
        <Dashboard />
      </Route>

      {/* Fallback Redirects */}
      {portfolios.length > 0
        ? (<Redirect to={`/${portfolios[0].slug}`} />)
        : (<Redirect to="/portfolios/new" />)}
    </Switch>
      )
}
