import React, { useContext } from 'react'
import { Title, useModal, Subtitle } from '../../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../../Shared/API'
import DistributionForm from './DistributionForm'
import { EntityContext } from '../../../Context/EntityContext'

export default function AddDistribution ({ returnPath }) {
  const { reloadEntity, entityAPI } = useContext(EntityContext)
  const { api } = useAPIGet(`${entityAPI}/distributions/new.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const onSubmit = async (distribution) => {
    const { error } = (await API.post(`${entityAPI}/distributions.json`, { distribution }))

    if (!error) {
      closeModal()
      reloadEntity()
    }
  }

  return (
    <AwaitResponse api={api}>
      {({ distribution, fund_options, status_options }) => (
        <Modal>
          <Title>Record Distribution</Title>
          <Subtitle>
            What has been returned?
          </Subtitle>

          <DistributionForm
            distribution={distribution}
            fund_options={fund_options}
            status_options={status_options}
            onSubmit={onSubmit}
          />
        </Modal>
      )}
    </AwaitResponse>
  )
}
