import React from 'react'
import styled from 'styled-components'
import { format as formatDate, parseISO } from 'date-fns'

const Byline = styled.span`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  color: #777;
`

const Body = styled.div``

export const Date = ({ date }) => (
  <span title={formatDate(date, 'MMMM d, yyy')}>{formatDate(date, 'yyy')}</span>
)

const Profile = styled.div`
  margin: 0 20px 20px 20px;
`

const Empty = styled.div`
  margin: 20px;
  color: #666;
  font-style: italic;
  text-align: center;
`

export default ({ entity }) => (
  <Profile>
    {entity.kind !== 'Cryptocurrency' && (
      <>
        <Byline>Stage</Byline>
        <Body>{entity.profile.stage}</Body>
      </>
    )}

    {entity.kind !== 'Cryptocurrency' && entity.first_invested_on && (
      <>
        <Byline>Vintage</Byline>
        <Body><Date date={parseISO(entity.first_invested_on)} /></Body>
      </>
    )}

    <Byline>Original Investment Thesis</Byline>
    <Body>{entity.profile.thesis || <Empty>None recorded.</Empty>}</Body>
  </Profile>
)
