import React, { useEffect, useState } from 'react'
import { useFieldState } from 'informed'
import { Submit } from '../../Shared/Modal'
import {
  CurrencyInput, Form, Input,
  Row, DatePicker, IntegerInput, StringSelect
} from '../../Shared/Form'
import { prepareCurrency, parseCurrency, DollarValue } from '../../Shared/MonetaryValue'

const Reporting = () => {
  const { value: source } = useFieldState('source')
  const { value: sharePriceValue } = useFieldState('share_price')
  const { value: fullyDilutedShareCountValue } = useFieldState('fully_diluted_shares')
  const { value: roundShareCountValue } = useFieldState('round_shares')
  const [postMoneyValuation, setPostMoneyValuation] = useState()
  const [roundSize, setRoundSize] = useState()

  useEffect(() => {
    const sharePrice = Number.parseFloat(sharePriceValue)
    const fullyDilutedShareCount = Number.parseInt(fullyDilutedShareCountValue, 10)
    const roundShareCount = Number.parseInt(roundShareCountValue, 10)

    if (sharePrice > 0 && fullyDilutedShareCount > 0) {
      setPostMoneyValuation(sharePrice * fullyDilutedShareCount * 1000000)
    } else {
      setPostMoneyValuation(null)
    }

    if (sharePrice > 0 && roundShareCount > 0) {
      setRoundSize(sharePrice * roundShareCount * 1000000)
    } else {
      setRoundSize(null)
    }
  }, [sharePriceValue,
    fullyDilutedShareCountValue,
    setPostMoneyValuation,
    roundShareCountValue,
    setRoundSize
  ])

  return source !== 'Funding Docs' && source !== 'Company Reporting'
    ? null
    : (
    <>
      <Row>
        <Input field="name" label="Milestone Name" />
        <CurrencyInput
          field="share_price"
          label="Price Per Share"
          decimalLimit={5}
          placeholder="$0.00000"
          keepState
          optional
        />
      </Row>
      <Row>
        <IntegerInput
          field="fully_diluted_shares"
          label="# of Total Shares (Fully Diluted)"
          placeholder="1000"
          keepState
          optional
        />
        <IntegerInput
          field="round_shares"
          label="# of Round Shares"
          placeholder="1000"
          keepState
          optional
        />
      </Row>
      <Row>
        <DollarValue
          value={postMoneyValuation}
          render={(formatted) => (
            <CurrencyInput
              field="post_valuation"
              label="Post-Money Valuation"
              placeholder={formatted}
              keepState
              optional
              disabled
            />
          )}
        />

        <DollarValue
          value={roundSize}
          render={(formatted) => (
            <CurrencyInput
              field="round_size"
              label="Round Size"
              placeholder={formatted}
              keepState
              optional
              disabled
            />
          )}
        />
      </Row>
    </>
      )
}

const Estimate = () => {
  const { value: source } = useFieldState('source')
  return source !== 'Estimate'
    ? null
    : (
    <>
      <Row>
        <Input field="name" label="Milestone Name" />
      </Row>
      <Row>
        <CurrencyInput
          field="post_valuation"
          label="Post-Money Valuation"
          placeholder="$25,000,000.00"
          keepState
          optional
        />
        <CurrencyInput
          field="round_size"
          label="Round Size"
          placeholder="$5,000,000.00"
          keepState
          optional
        />
      </Row>
    </>
      )
}

const PublicRecords = () => {
  const { value: source } = useFieldState('source')
  const { value: sharePriceValue } = useFieldState('share_price')
  const { value: fullyDilutedShareCountValue } = useFieldState('fully_diluted_shares')
  const [postMoneyValuation, setPostMoneyValuation] = useState()

  useEffect(() => {
    const sharePrice = Number.parseFloat(sharePriceValue)
    const fullyDilutedShareCount = Number.parseInt(fullyDilutedShareCountValue, 10)

    if (sharePrice > 0 && fullyDilutedShareCount > 0) {
      setPostMoneyValuation(sharePrice * fullyDilutedShareCount * 1000000)
    } else {
      setPostMoneyValuation(null)
    }
  }, [sharePriceValue,
    fullyDilutedShareCountValue,
    setPostMoneyValuation
  ])

  return source !== 'Public Records'
    ? null
    : (
    <>
      <Row>
        <CurrencyInput
          field="share_price"
          label="Price Per Share"
          decimalLimit={5}
          placeholder="$0.00000"
          keepState
          optional
        />
        <IntegerInput
          field="fully_diluted_shares"
          label="# of Total Shares (Fully Diluted)"
          placeholder="1000"
          keepState
          optional
        />
      </Row>
      <Row>
        <DollarValue
          value={postMoneyValuation}
          render={(formatted) => (
            <CurrencyInput
              field="post_valuation"
              label="Market Cap"
              placeholder={formatted}
              keepState
              optional
              disabled
            />
          )}
        />
      </Row>
    </>
      )
}

export default function ValuationForm ({ valuation, onSubmit, source_options }) {
  const isEditing = (valuation.id !== null)

  // Prepare currency values
  const {
    share_price, post_valuation, round_size, ...rest
  } = valuation
  const initialValues = {
    share_price: prepareCurrency(share_price),
    post_valuation: prepareCurrency(post_valuation),
    round_size: prepareCurrency(round_size),
    ...rest
  }

  const onFormSubmit = ({
    share_price, post_valuation, round_size, ...newValuation
  }) => {
    onSubmit({
      ...valuation,
      ...newValuation,
      share_price: parseCurrency(share_price),
      post_valuation: parseCurrency(post_valuation),
      round_size: parseCurrency(round_size)
    })
  }

  return (
    <Form onSubmit={onFormSubmit} initialValues={initialValues}>
      <Row>
        <StringSelect field="source" options={source_options} label="Source of Information" />
        <DatePicker field="valuation_on" label="When?" />
      </Row>

      <Reporting />
      <Estimate />
      <PublicRecords />

      <Input field="description" label="Notes" />

      <Submit title={isEditing ? 'Save Changes' : 'Record Valuation'} />
    </Form>
  )
}
