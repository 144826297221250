import React, { createContext, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useAPIGet } from '../Shared/API'
import { FundContext } from './FundContext'
import { UserContext } from './UserContext'

export const EntityContext = createContext({})

export const EntityProvider = ({ children }) => {
  const { portfolioAPI, portfolioPath } = useContext(UserContext)
  const { fund, fundPath } = useContext(FundContext)
  const basePath = fundPath || portfolioPath
  const query = fund ? `funds=${fund.id}` : ''
  const { entityIDSlug } = useParams()
  const {
    api,
    reload: reloadEntity,
    setData: setEntity
  } = useAPIGet(`${portfolioAPI}/entities/${entityIDSlug}.json?${query}`, {})
  const { initialLoadComplete, data: { entity, status_options, kind_options } } = api

  const entityPaths = (e) => ({
    entityPath: e && `${basePath}/entities/${e.id}-${e.slug}`,
    entityAPI: e && `${portfolioAPI}/entities/${e.id}`,
    financesPath: e && `${basePath}/entities/${e.id}-${e.slug}/finances`,
    prospectPath: e && `${basePath}/prospects/${e.id}-${e.slug}`
  })

  // Make the context object:
  const context = {
    initialLoadComplete,
    entity,
    entityPaths,
    ...entityPaths(entity),
    status_options,
    kind_options,
    reloadEntity,
    setEntity
  }

  // pass the value in provider and return
  return <EntityContext.Provider value={context}>{children}</EntityContext.Provider>
}

export const { Consumer } = EntityContext
