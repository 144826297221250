import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { TableLoader } from '../Shared/Table'
import { Contents } from '../Shared/UI'

export function Loading () {
  return (
    <Contents>
      <TableLoader />
    </Contents>
  )
}

const ErrorMessage = styled.div`
  text-align: center;
  margin: 50px auto;
  font-size: 25px;
`

export function Error ({ children }) {
  return (
    <Contents>
      <ErrorMessage>{children}</ErrorMessage>
    </Contents>
  )
}

export function AbortOnError ({ children }) {
  const history = useHistory()

  useEffect(() => {
    history.push('/logout')
  })

  return (
    <Contents>
      <ErrorMessage>{children}</ErrorMessage>
    </Contents>
  )
}
