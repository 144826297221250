import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Title, useModal } from '../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../Shared/API'
import NoteForm from './NoteForm'
import { EntityContext } from '../../Context/EntityContext'

export default function EditNote () {
  const { noteID } = useParams()
  const { reloadEntity, entityPath, entityAPI } = useContext(EntityContext)
  const { api } = useAPIGet(`${entityAPI}/notes/${noteID}.json`, {})
  const { Modal, closeModal } = useModal(entityPath)

  const onSubmit = async (note) => {
    const { error } = (await API.put(`${entityAPI}/notes/${noteID}.json`, { note }))

    if (!error) {
      closeModal()
      reloadEntity()
    }
  }

  return (
    <AwaitResponse api={api}>
      {(note) => (
        <Modal disableClose>
          <Title>Edit Note</Title>

          <NoteForm note={note} onSubmit={onSubmit} onCancel={() => closeModal()} />
        </Modal>
      )}
    </AwaitResponse>
  )
}
