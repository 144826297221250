import React from 'react'
import { useFieldState } from 'informed'
import { Submit } from '../../../Shared/Modal'
import {
  Form,
  Row, DatePicker, CurrencyInput, Select, StringSelect, TextArea
} from '../../../Shared/Form'
import { prepareCurrency, parseCurrency } from '../../../Shared/MonetaryValue'

export default function DistributionForm ({
  distribution, fund_options, status_options, onSubmit
}) {
  const isEditing = (distribution.id !== null)

  // Prepare currency values
  const { dollar_value, ...rest } = distribution
  const initialValues = {
    dollar_value: prepareCurrency(dollar_value),
    ...rest
  }

  const onFormSubmit = ({ dollar_value, ...newDistribution }) => {
    onSubmit({
      ...distribution,
      ...newDistribution,
      dollar_value: parseCurrency(dollar_value)
    })
  }

  const fundSelectOptions = fund_options.map((f) => ({ id: f.id, title: f.display_name }))

  return (
    <Form onSubmit={onFormSubmit} initialValues={initialValues}>
      <Row>
        <CurrencyInput field="dollar_value" label="Dollar Value" placeholder="$25,000.00" />
        {fundSelectOptions.length > 0 && <Select field="fund_id" options={fundSelectOptions} label="Allocated to which fund?" />}
      </Row>

      <Row>
        <StringSelect field="status" options={status_options} label="Status" />
        <ReceivedDate />
      </Row>

      <TextArea field="notes" label="Notes" />

      <Submit title={isEditing ? 'Save Changes' : 'Record Distribution'} />
    </Form>
  )
}

const ReceivedDate = () => {
  const { value: status } = useFieldState('status')

  if (status === 'Received') {
    return (
      <DatePicker field="received_on" label="When?" />
    )
  }

  return null
}
