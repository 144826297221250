import React, { useContext } from 'react'
import { format as formatDate } from 'date-fns'
import {
  Title, Submit, Subtitle, useModal, Button
} from '../../Shared/Modal'
import API from '../../Shared/API'
import { EntityContext } from '../../Context/EntityContext'
import { Form, Input, Row } from '../../Shared/Form'

export default function PassProspect () {
  const {
    entity, entityAPI, prospectPath, setEntity
  } = useContext(EntityContext)
  const { Modal, closeModal } = useModal(prospectPath)
  const date = formatDate(new Date(), 'MMMM, yyy')

  const editEntity = async (newEntity) => {
    const { error, data } = (await API.put(entityAPI, {
      entity: { ...newEntity, status: 'Passed' }
    }))

    if (!error) {
      closeModal()
      setEntity(data)
    }
  }

  return (
    <Modal>
      <Title>
        Pass on
        {' '}
        {entity.display_name}
        ?
      </Title>
      <Subtitle>
        Are you sure you want to decline to invest in
        {' '}
        <b>{entity.display_name}</b>
        ?
      </Subtitle>

      <Form onSubmit={editEntity}>
        <Input
          field="outcome_reason"
          title="Why are you passing?"
          placeholder={`Passed in ${date} because…`}
          keepState
        />

        <Row>
          <Button title="Cancel" onClick={() => closeModal()} />
          <Submit title="Confirm Pass" />
        </Row>
      </Form>
    </Modal>
  )
}
