import React, { useContext } from 'react'
import { parseISO } from 'date-fns'
import Table, { CellTitle, CellSubtitle } from '../../Shared/Table'
import { FundContext } from '../../../Context/FundContext'

export default ({ distributions }) => {
  const columns = [
    {
      type: 'date',
      value: (row) => (parseISO(row.received_on))
    },
    {
      title: 'Notes',
      value: (row) => (row.notes)
    },
    {
      title: 'Amount',
      type: 'monetary',
      value: (row) => (row.dollar_value)
    }
  ]

  const fundColumn = {
    title: 'Company',
    type: 'stacked',
    render: ({ fund }) => (
      <>
        <CellTitle>{fund.display_name}</CellTitle>
        <CellSubtitle>{fund.legal_name}</CellSubtitle>
      </>
    )
  }

  if ((distributions[0] || {}).fund) {
    columns.splice(1, 0, fundColumn)
  }

  const { fundPath } = useContext(FundContext)

  return (
    <Table
      columns={columns}
      rows={distributions}
      defaultSort={{ column: 0, direction: 'asc' }}
      emptyMessage="No distributions have been received."
      rowMenu={[
        {
          title: 'Edit…',
          select: (row) => (`${fundPath}/distributions/${row.id}/edit`)
        },
        {
          title: 'Remove…',
          select: (row) => (`${fundPath}/distributions/${row.id}/remove`)
        }
      ]}
    />
  )
}
