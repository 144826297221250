import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Title, useModal } from '../../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../../Shared/API'
import CallForm from './CallForm'
import { FundContext } from '../../../Context/FundContext'

export default function EditCall ({ returnPath }) {
  const { callID } = useParams()
  const { reloadFund, fundAPI } = useContext(FundContext)
  const { api } = useAPIGet(`${fundAPI}/capital_calls/${callID}.json`, {})
  const { Modal, closeModal } = useModal(returnPath)

  const editCall = async (call) => {
    const { error } = (await API.put(`${fundAPI}/capital_calls/${callID}.json`, { capital_call: call }))

    if (!error) {
      closeModal()
      reloadFund()
    }
  }

  return (
    <AwaitResponse api={api}>
      {(call) => (
        <Modal>
          <Title>Edit Capital Call</Title>

          <CallForm
            call={call}
            onSubmit={editCall}
          />
        </Modal>
      )}
    </AwaitResponse>
  )
}
