import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import Stats from '../Shared/Stats'
import {
  Contents, Section, SectionHeader, AddLink, Left, Right, VStack
} from '../Shared/UI'
import EntitiesTable from '../Entities/EntitiesTable'
import ProspectsTable from '../Prospects/ProspectsTable'
import { Filters, Filter } from '../Shared/Filters'
import { DollarValue, ComparativeValue } from '../../Shared/MonetaryValue'

const FundMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  opacity: ${({ status }) => (status === 'Active' ? '1.0' : '0.5')};
`

const FundDetails = styled(VStack)`
  align-items: flex-start;
`

const FundTitle = styled.div`
  margin-right: 30px;
`

const FundRelationship = styled.span`
  font-size: 8px;
  padding: 2px 7px;
  border-radius: 7px;
  background-color: ${({ relationship }) => (relationship === 'Manager' ? 'lightslategray' : '#bbb')};
  color: white;
  text-transform: uppercase;
`

const FundPerformance = styled(VStack)`
  align-items: flex-end;
`

const FundInvested = styled(DollarValue)`
  color: gray;
  font-size: smaller;
`

const Search = styled.input`
  border-radius: 50vh;
  border: 1px solid #5c738b;
  background-color: transparent;
  color: rgba(255,255,255,0.7);
  letter-spacing: 0.02em;
  font-size: 13px;
  font-weight: 500;
  height: 25px;
  padding: 0 11px;
  margin-right: 20px;
  margin-bottom: 10px;
  width: 220px;

  &::placeholder {
    color: rgba(255,255,255,0.5);
  }

  :focus {
    outline: none;
    background-color: rgba(255,255,255,0.1);
  }
`

const FiltersNav = ({
  basePath, filters, search, setSearch
}) => {
  const [filterMenuOpen, setFilterMenuOpen] = useState()
  const history = useHistory()

  return (
    <Filters>
      <Left>
        {filters.funds && (
        <Filter
          defaultTitle="All Funds"
          filter="funds"
          filters={filters}
          filterOpen={filterMenuOpen}
          setFilterOpen={setFilterMenuOpen}
          render={(fund) => (
            <FundMenuItem status={fund.status}>
              <FundDetails>
                <FundTitle>
                  {fund.display_name}
                </FundTitle>
                <FundRelationship relationship={fund.relationship}>
                  {fund.relationship}
                </FundRelationship>
              </FundDetails>
              <FundPerformance>
                <ComparativeValue
                  value={fund.analysis.current_value}
                  decimals={0}
                  comparison={fund.analysis.total_invested}
                />
                <FundInvested value={fund.analysis.total_invested} decimals={0} />
              </FundPerformance>
            </FundMenuItem>
          )}
          onSelect={(item) => {
            history.push(`${basePath}/fund/${item.value}`)
          }}
        />
        )}
        <Filter
          defaultTitle="All Years"
          filter="years"
          filters={filters}
          filterOpen={filterMenuOpen}
          setFilterOpen={setFilterMenuOpen}
        />
        <Filter
          defaultTitle="All Stages"
          filter="stages"
          filters={filters}
          filterOpen={filterMenuOpen}
          setFilterOpen={setFilterMenuOpen}
        />
        <Filter
          defaultTitle="Active &amp; Inactive"
          filter="statuses"
          filters={filters}
          filterOpen={filterMenuOpen}
          setFilterOpen={setFilterMenuOpen}
        />
      </Left>
      <Right>
        <Search
          type="search"
          placeholder="Search…"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Right>
    </Filters>
  )
}

const Prospects = ({ basePath, prospects }) => prospects.length > 0 && (
<Section>
  <SectionHeader title="Active Leads">
    <AddLink to={`${basePath}/new/prospect`}>Add</AddLink>
  </SectionHeader>
  <ProspectsTable prospects={prospects} />
</Section>
)

const EmptyMessage = ({ basePath }) => (
  <span>
    There are no
    {' '}
    companies in this portfolio.
    {' '}
    <Link to={`${basePath}/new/prospect`}>Add a lead</Link>
    {' '}
    or
    {' '}
    <Link to={`${basePath}/new/investment`}>record a prior investment</Link>
    &hellip;
  </span>
)

export default function PortfolioTable ({
  basePath, summary, entities, prospects, filters, hideFilters
}) {
  const [search, setSearch] = useState('')

  const filteredEntities = entities.filter(
    (e) => search === '' || e.display_name.match(new RegExp(search, 'i'))
  )

  const filteredProspects = prospects.filter(
    (e) => search === '' || e.display_name.match(new RegExp(search, 'i'))
  )

  return (
    <>
      {hideFilters !== true && (
      <FiltersNav
        basePath={basePath}
        filters={filters}
        search={search}
        setSearch={setSearch}
      />
      )}
      <Stats entities={entities} stats={summary} />
      <Contents>
        {hideFilters !== true && <Prospects basePath={basePath} prospects={filteredProspects} />}

        <Section>
          <EntitiesTable
            basePath={basePath}
            entities={filteredEntities}
            emptyMessage={<EmptyMessage basePath={basePath} />}
          />
        </Section>
      </Contents>
    </>
  )
}
