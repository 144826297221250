import React from 'react'
import './index.css'
import { BrowserRouter as Router } from 'react-router-dom'
import HttpsRedirect from 'react-https-redirect'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { UserProvider } from './Context/UserContext'

import { createRoot } from 'react-dom/client'
const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(<HttpsRedirect>
  <Router>
    <UserProvider>
      <App />
    </UserProvider>
  </Router>
</HttpsRedirect>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
