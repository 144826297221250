export default [
  {
    name: 'Company Name',
    description: 'The display name (not legal name) of the entity in which you invested, e.g.',
    examples: ['Acme']
  },
  {
    name: 'Date',
    description: 'When you made the investment, e.g.',
    examples: ['1/1/2020']
  },
  {
    name: 'Amount',
    description: 'The dollar value (USD) of your investment, e.g.',
    examples: ['$25,000.00']
  },
  {
    name: 'Round Name',
    description: 'The name of the round you invested in, e.g.',
    examples: ['Series Seed-2']
  },
  {
    name: 'Round Series',
    description: 'The series of the round you invested in. Supported values are:',
    examples: ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Series C', 'Series D']
  },
  {
    name: 'Status',
    description: 'The current status of your investment. Support values are:',
    examples: ['Holding', 'Written Off']
  }
]
