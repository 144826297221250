import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Title, useModal } from '../../Shared/Modal'
import API, { AwaitResponse, useAPIGet } from '../../Shared/API'
import EntityForm from '../Entities/EntityForm'
import { UserContext } from '../../Context/UserContext'

export default function NewProspect ({ closePath }) {
  const { portfolioPath, portfolioAPI, setPortfolioTouched } = useContext(UserContext)
  const basePath = `${portfolioAPI}/entities`
  const { api } = useAPIGet(`${basePath}/new.json?status=Prospective`, [])
  const history = useHistory()
  const { Modal, closeModal } = useModal({ pathname: closePath || portfolioPath, search: history.location.search })
  const [networkError, setNetworkError] = useState()
  const [submittedData, setSubmittedData] = useState()

  return (
    <AwaitResponse api={api}>
      {({ entity: initialFormData }) => {
        const onSubmit = async (data) => {
          try {
            await API.post(`${basePath}.json`, { entity: { ...initialFormData, ...data } })
            setPortfolioTouched()
            closeModal()
          } catch (error) {
            setSubmittedData(data)
            setNetworkError(error.response.data.error)
          }
        }

        return (
          <Modal shake={networkError}>
            <Title>Add Lead</Title>

            <EntityForm
              onSubmit={onSubmit}
              onCancel={() => closeModal()}
              entity={{ ...initialFormData, ...submittedData }}
              isProspect
              networkError={networkError}
            />
          </Modal>
        )
      }}
    </AwaitResponse>
  )
}
