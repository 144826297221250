import React, { useEffect, useReducer, useCallback } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { ModalLoader } from './Modal'

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  responseType: 'json'
})

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        hasFired: true,
        isLoading: true,
        didCancel: false,
        error: undefined
      }
    case 'FETCH_SUCCESS':
      return state.didCancel
        ? state
        : {
            ...state,
            hasFired: true,
            isLoading: false,
            initialLoadComplete: true,
            error: undefined,
            data: action.payload
          }
    case 'FETCH_FAILURE':
      return state.didCancel
        ? state
        : {
            ...state,
            hasFired: true,
            initialLoadComplete: true,
            isLoading: false,
            error: action.error
          }
    case 'CANCEL':
      return {
        ...state,
        didCancel: true
      }
    default:
      throw new Error()
  }
}

export const useAPIGet = (initialUrl, initialData) => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    hasFired: false,
    initialLoadComplete: false,
    isLoading: false,
    didCancel: false,
    error: false,
    data: initialData
  })

  const performFetch = useCallback(
    async () => {
      dispatch({ type: 'FETCH_INIT' })

      try {
        const result = await API.get(initialUrl)
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data })
      } catch (error) {
        dispatch({ type: 'FETCH_FAILURE', error })
      }
    },
    [initialUrl]
  )

  useEffect(() => {
    performFetch()

    return () => {
      dispatch({ type: 'CANCEL' })
    }
  }, [performFetch])

  const setData = (data) => {
    dispatch({ type: 'FETCH_SUCCESS', payload: data })
  }

  return { api: state, setData, reload: performFetch }
}

const ErrorMessage = styled.div`
  color: white;
  text-align: center;
  margin: 50px auto;
`

export function AwaitResponse ({
  api, loading, error, children,
  errorComponent, loadingComponent
}) {
  const Loader = loading || ModalLoader
  const Error = error || ErrorMessage

  if (api.error) {
    return errorComponent || (<Error>{`${api.error}`}</Error>)
  } if (!api.hasFired || api.isLoading) {
    return loadingComponent || (<Loader />)
  }

  return children(api.data)
}

export default API
